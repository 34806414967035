import React, { useEffect, useState } from "react"
import { db } from "../../../../../utils/firebase"
import { NavLink } from "react-router-dom"
import "./booking.scss"

// Functional component to return the booking table row the masters 2025 page
export default function Booking({ id, details, groupStatus }) {
	const [agent, setAgent] = useState({})
	const [client, setClient] = useState({})
	// On component load
	useEffect(() => {
		db.doc(`users/${details.agent}`)
			.get()
			.then((agentDoc) => {
				setAgent(agentDoc.data())
			})

		db.doc(`clients/${details.client}`)
			.get()
			.then((clientDoc) => {
				setClient(clientDoc.data())
			})
	}, [])

	return (
		<tr>
			<td>
				{agent?.first_name && `${agent.first_name} ${agent.last_name}`}
				{!agent?.first_name && <small className="no-value">No agent assigned</small>}
			</td>
			<td>
				<NavLink to={`/client/${details.client}`}>
					{client?.first_name && `${client.first_name} ${client.last_name}`}
					{!client?.first_name && <small className="no-value">No name set</small>}
				</NavLink>
			</td>
			<td>{groupStatus}</td>
			<td className="is-button">
				<div className="condensed-buttons">
					<NavLink to={`/booking/${id}`}>View booking</NavLink>
					<NavLink
						to={`/booking/${id}`}
						target="_blank">
						View in new tab
					</NavLink>
				</div>
			</td>
		</tr>
	)
}
