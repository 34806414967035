export const ROOM_TYPES = {
	deluxe_single: "Deluxe Single",
	deluxe: "Deluxe",
	double_room: "Double Room",
	family_room: "Family Room",
	four_bed_apartment: "Four Bed Apartment",
	four_bedroom_villa: "Four Bedroom Villa",
	garden_view_single: "Garden View Single",
	garden_view: "Garden View",
	golf_view_single: "Golf View Single",
	golf_view: "Golf View",
	one_bedroom_apartment: "One Bedroom Apartment",
	one_bedroom_suite: "One Bedroom Suite",
	one_bedroom_villa: "One Bedroom Villa",
	pool_view_single: "Pool View Single",
	pool_view: "Pool View",
	premium_room_single: "Premium Room Single",
	premium_room: "Premium Room",
	quadruple_room: "Quadruple Room",
	sea_view_single: "Sea View Single",
	sea_view: "Sea View",
	side_sea_view_single: "Side Sea View Single",
	side_sea_view: "Side Sea View",
	single_room: "Single Room",
	studio_single: "Studio Single",
	studio: "Studio",
	suite: "Suite",
	suite_single: "Suite Single",
	sea_view_suite: "Sea View Suite",
	superior_single: "Superior Single",
	superior: "Superior",
	three_bed_apartment: "Three Bed Apartment",
	three_bedroom_villa: "Three Bedroom Villa",
	triple_room: "Triple Room",
	twin_room: "Twin Room",
	two_bed_apartment_mez: "Two Bed Apartment Mez",
	two_bedroom_apartment: "Two Bedroom Apartment",
	two_bedroom_suite: "Two Bedroom Suite",
	two_bedroom_villa: "Two Bedroom Villa",
	three_bedroom_apartment: "Three Bedroom Apartment",
	three_bedroom_suite: "Three Bedroom Suite",
	junior_suite_penthouse: "Junior Suite Penthouse",
}
