import React, { useEffect, useState, useContext } from "react"
import { BookingContext, BookingProvider } from "../../utils/providers/booking"
import { AuthContext } from "../../utils/providers/auth"
import { db, auth } from "../../utils/firebase"
import "./booking.scss"

/**
 * UI components
 */
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Button from "../../components/ui/button/button"
import Navigation from "../../components/ui/navigation/navigation"

/**
 * Tabs
 */
import Summary from "./tabs/summary"
import Hotels from "./tabs/hotels"
import Transfers from "./tabs/transfers"
import Rounds from "./tabs/rounds"
import Margins from "./tabs/margins"
import PriceLines from "./tabs/price-lines"
import Emails from "./tabs/emails"
import Notes from "./tabs/notes"
import Logs from "./tabs/logs"
import Atol from "./tabs/atol"
import EventInfo from "./tabs/event-info"
import Flights from "./tabs/flights"
import Finances from "./tabs/finances"
import moment from "moment"
import { LockIcon, UnlockIcon } from "lucide-react"

/**
 * Functional component to return the HTML markup for the booking page
 */
function Booking(props) {
	const [activeTab, setActiveTab] = useState("SUMMARY")
	const [loading, setLoading] = useState(true)
	const [clientID, setClientID] = useState("")
	const [client, setClient] = useState({})
	const [flight, setFlight] = useState(null)
	const [passengers, setPassengers] = useState([])

	/**
	 * Deconstruct the bookingID from the props
	 */
	const { bookingID } = props

	/**
	 * Pull the "setBooking" state from the booking context
	 */
	const { created, marginsLocked, outsideMarginWindow, setBooking, saving, saveBookingDetails, golfers, nonGolfers, setCommissionLogs } = useContext(BookingContext)
	const { user } = useContext(AuthContext)

	/**
	 * On component load
	 */
	useEffect(() => {
		/**
		 * Get the booking document from the document
		 */
		const unsubscribe = db.doc(`bookings/${bookingID}`).onSnapshot((bookingDoc) => {
			/**
			 * Deconstruct the clientID from the booking data
			 */
			const { client } = bookingDoc.data()
			/**
			 * Set the rest of the booking data into the context
			 */
			setBooking({ id: bookingID, ...bookingDoc.data() })
			setFlight(bookingDoc.data()?.flight || null)
			/**
			 * Set the clientID into the state
			 */
			setClientID(client)
			/**
			 * Reset the loading state
			 */
			setLoading(false)
		})

		const passengerUnsubscribe = db.collection(`bookings/${bookingID}/passengers`).onSnapshot((passengerDocs) => {
			passengerDocs.docChanges().forEach((change) => {
				if (change.type === "added") {
					setPassengers((passengers) => [
						...passengers,
						{
							id: change.doc.id,
							...change.doc.data(),
						},
					])
				}

				if (change.type === "modified") {
					setPassengers((passengers) => {
						let updatedPassengers = [...passengers]
						for (let i in passengers) {
							if (passengers[i].id === change.doc.id) {
								updatedPassengers[i] = {
									id: change.doc.id,
									...change.doc.data(),
								}
								break
							}
						}
						return updatedPassengers
					})
				}

				if (change.type === "removed") {
					setPassengers((passengers) => passengers.filter((enquiry) => enquiry.id !== change.doc.id))
				}
			})
		})

		const commissionLogsUnsubscribe = db
			.collection(`bookings/${bookingID}/commission_logs`)
			.orderBy("created", "desc")
			.onSnapshot((passengerDocs) => {
				passengerDocs.docChanges().forEach((change) => {
					if (change.type === "added") {
						setCommissionLogs((commissionLogs) => [
							...commissionLogs,
							{
								id: change.doc.id,
								...change.doc.data(),
							},
						])
					}

					if (change.type === "modified") {
						setCommissionLogs((commissionLogs) => {
							let updatedCommissionLogs = [...commissionLogs]
							for (let i in commissionLogs) {
								if (commissionLogs[i].id === change.doc.id) {
									updatedCommissionLogs[i] = {
										id: change.doc.id,
										...change.doc.data(),
									}
									break
								}
							}
							return updatedCommissionLogs
						})
					}

					if (change.type === "removed") {
						setCommissionLogs((commissionLogs) => commissionLogs.filter((enquiry) => enquiry.id !== change.doc.id))
					}
				})
			})

		/**
		 * Remove the listener when the component unloads
		 */
		return () => {
			unsubscribe()
			passengerUnsubscribe()
			commissionLogsUnsubscribe()
		}
	}, [bookingID])

	/**
	 * When the clientID is updated
	 */
	useEffect(() => {
		/**
		 * Get the client document from the database
		 */
		clientID &&
			db
				.doc(`clients/${clientID}`)
				.get()
				.then((clientDoc) => {
					/**
					 * Set the client details into the state
					 */
					setClient(clientDoc.data())
				})
	}, [clientID])

	return (
		<Tile fullPage={true}>
			{/* If we are in a loading state */}
			{loading && !client.first_name && "loading"}

			{/* If the booking data has loaded in */}
			{!loading && client.first_name && (
				<>
					<Title className="flex">
						<h1>Itinerary {client.first_name && `for ${client.first_name} ${client.last_name}`}</h1>

						<Button
							label="Save booking"
							small={true}
							loading={saving}
							loadingText="Saving..."
							onClick={() => saveBookingDetails()}
						/>
					</Title>

					{outsideMarginWindow && (
						<div className={["locked-margins-message", marginsLocked ? "margins-locked" : ""].join(" ")}>
							{marginsLocked && (
								<>
									<p>
										<LockIcon /> Margins on this booking are locked.
									</p>

									{user?.is_super_admin && (
										<Button
											label="Unlock"
											small={true}
											onClick={async () => {
												await db.doc(`bookings/${bookingID}`).set(
													{
														margins_unlocked: true,
														margins_unlocked_by: auth.currentUser.uid,
													},
													{ merge: true }
												)
											}}
										/>
									)}
								</>
							)}

							{!marginsLocked && (
								<>
									<p>
										<UnlockIcon /> Margins on this booking are unlocked.
									</p>

									{user?.is_super_admin && (
										<Button
											label="Lock"
											small={true}
											onClick={async () => {
												await db.doc(`bookings/${bookingID}`).set(
													{
														margins_unlocked: false,
														margins_unlocked_by: null,
													},
													{ merge: true }
												)
											}}
										/>
									)}
								</>
							)}
						</div>
					)}

					<Navigation
						links={[
							{
								id: "SUMMARY",
								label: "Summary",
							},
							{
								id: "HOTELS",
								label: "Hotels",
							},
							{
								id: "GOLF",
								label: "Rounds",
							},
							{
								id: "TRANSFERS",
								label: "Transfers",
							},
							{
								id: "FLIGHTS",
								label: "Flights",
							},
							{
								id: "MARGINS",
								label: "Sale margins",
							},
							{
								id: "FINANCES",
								label: "Finances",
							},
							{
								id: "PRICE_LINES",
								label: "Price lines",
							},
							{
								id: "EMAILS",
								label: "Emails",
							},
							{
								id: "NOTES",
								label: "Notes",
							},
							{
								id: "LOGS",
								label: "Logs",
							},
							{
								id: "Atol",
								label: "ATOL certificate",
							},
							{
								id: "EVENT_INFO",
								label: "Event Information",
							},
						]}
						active={activeTab}
						onClick={(tabID) => setActiveTab(tabID)}
					/>

					{activeTab === "SUMMARY" && (
						<Summary
							client={client}
							clientID={clientID}
						/>
					)}

					{activeTab === "HOTELS" && <Hotels client={client} />}

					{activeTab === "GOLF" && <Rounds client={client} />}

					{activeTab === "TRANSFERS" && <Transfers client={client} />}

					{activeTab === "FLIGHTS" && (
						<Flights
							client={client}
							bookingFlight={flight}
							expectedFlyers={parseInt(golfers + nonGolfers)}
							passengers={passengers}
						/>
					)}

					{activeTab === "MARGINS" && <Margins clientID={clientID} />}

					{activeTab === "FINANCES" && <Finances />}

					{activeTab === "PRICE_LINES" && <PriceLines client={client} />}

					{activeTab === "EMAILS" && <Emails client={client} />}

					{activeTab === "NOTES" && <Notes />}

					{activeTab === "LOGS" && <Logs />}

					{activeTab === "Atol" && <Atol client={client} />}

					{activeTab === "EVENT_INFO" && <EventInfo />}
				</>
			)}
		</Tile>
	)
}

/**
 * Wrapper for the booking functional component
 */
function BookingWrap(props) {
	/**
	 * Deconstruct the bookingID from the url params
	 */
	const { id } = props.match.params

	return (
		<BookingProvider>
			<Booking bookingID={id} />
		</BookingProvider>
	)
}

export default BookingWrap
