import React, { useEffect, useState } from "react"
import { db } from "../../utils/firebase"
import moment from "moment"

import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Table from "../../components/structure/table/table"
import HistoricalTargetRow from "../../components/historical-targets/historical-target-row"

function HistoricalTargets() {
	const [users, setUsers] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		// Query users who should be shown on the leaderboard
		db.collection("users")
			.orderBy("first_name")
			.where("show_for.targets", "==", true)
			.get()
			.then((userDocs) => {
				let agents = []
				userDocs.forEach((userDoc) => {
					agents.push({
						id: userDoc.id,
						...userDoc.data(),
					})
				})
				setUsers(agents)
				setLoading(false)
			})
	}, [])

	return (
		<Tile fullPage={true}>
			<Title>
				<h1>Historical Targets</h1>
			</Title>

			<Table
				className="travellers-table"
				headings={["Agent", ...Array.from({ length: 12 }, (_, i) => moment().subtract(i, "months").format("MMMM YYYY")).reverse()]}>
				{users.map((user) => (
					<HistoricalTargetRow
						key={user.id}
						id={user.id}
						details={user}
					/>
				))}
			</Table>
		</Tile>
	)
}

export default HistoricalTargets
