import React, { useState, useEffect, useContext } from "react"
import { AlertsContext } from "../../../../../utils/providers/alerts"
import { AuthContext } from "../../../../../utils/providers/auth"
import { db } from "../../../../../utils/firebase"
import { NavLink } from "react-router-dom"
import moment from "moment"
import "./masters-tickets.scss"

// UI components
import Table from "../../../../../components/structure/table/table"
import Input from "../../../../../components/ui/inputs/input"
import Button from "../../../../../components/ui/button/button"
import Badge from "../../../../../components/ui/badge/badge"
import Select from "../../../../../components/ui/select/select"

export default function MastersTickets() {
	const [searchTerm, setSearchTerm] = useState("")
	const [searchResults, setSearchResults] = useState([])
	const [selectedPerson, setSelectedPerson] = useState(null)
	const [ticketCode, setTicketCode] = useState("")
	const [tickets, setTickets] = useState([])
	const [loading, setLoading] = useState(true)
	const [ticketHistory, setTicketHistory] = useState([])
	const [selectedDay, setSelectedDay] = useState("all")
	const [selectedStatus, setSelectedStatus] = useState("all")
	const [allGroupNames, setAllGroupNames] = useState([])

	const { user } = useContext(AuthContext)
	const { pushAlert } = useContext(AlertsContext)

	// Define the days of Masters week
	const mastersDays = {
		all: "All Days",
		monday: "Monday",
		tuesday: "Tuesday",
		wednesday: "Wednesday",
		thursday: "Thursday",
		friday: "Friday",
		saturday: "Saturday",
		sunday: "Sunday",
	}

	const statusOptions = {
		all: "All Status",
		needs_return: "Needs Return",
		returned: "Returned",
	}

	// Fetch all tickets and ticket history on component load
	const fetchTickets = async () => {
		try {
			// Fetch active tickets and all bookings in parallel
			const [ticketsSnapshot, bookingsSnapshot] = await Promise.all([
				db.collection("events/masters_2025/tickets").get(),
				db.collection("bookings").where("event_tag", "==", "the_masters_2025").where("removed", "==", false).get(),
			])

			// Process tickets
			const ticketsData = ticketsSnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}))
			setTickets(ticketsData)

			// Process bookings and group names in parallel
			const bookingPromises = bookingsSnapshot.docs.map(async (booking) => {
				const groupNamesSnapshot = await db.collection(`bookings/${booking.id}/group_names`).get()
				const guests = groupNamesSnapshot.docs.map((doc) => ({
					id: doc.id,
					bookingId: booking.id,
					reference: booking.data().reference || "-",
					...doc.data(),
				}))
				return guests
			})

			const allGuests = (await Promise.all(bookingPromises)).flat()
			setAllGroupNames(allGuests)

			// Process ticket history from the tickets collection
			const ticketHistory = ticketsData.map((ticket) => ({
				...ticket,
				action: ticket.returned ? "returned" : "assigned",
				timestamp: ticket.returned ? ticket.returnedAt : ticket.assignedAt,
			}))
			setTicketHistory(ticketHistory)
		} catch (error) {
			console.error("Error fetching tickets:", error)
			pushAlert({
				type: "ERROR",
				title: "Error",
				body: "Failed to load ticket data",
			})
		} finally {
			setLoading(false)
		}
	}
	useEffect(() => {
		fetchTickets()
	}, [])

	// Search for guests using the already loaded group names
	const searchGuests = (term) => {
		if (!term) {
			setSearchResults([])
			return
		}

		const searchLower = term.toLowerCase()
		const filteredGuests = allGroupNames
			.filter((guest) => {
				const fullName = `${guest.first_name || ""} ${guest.last_name || ""}`.toLowerCase()
				const email = (guest.email || "").toLowerCase()
				const reference = String(guest.reference || "").toLowerCase()
				return fullName.includes(searchLower) || email.includes(searchLower) || reference.includes(searchLower)
			})
			.slice(0, 5) // Limit to 5 results

		setSearchResults(filteredGuests)
	}

	// Function to sanitize ticket code
	const sanitizeTicketCode = (code) => {
		// Remove all spaces and special characters, convert to uppercase
		return code.replace(/[^a-zA-Z0-9]/g, "").toUpperCase()
	}

	// Handle search input change
	const handleSearchChange = (value) => {
		setSearchTerm(value)
		searchGuests(value)
	}

	// Handle ticket code change
	const handleTicketCodeChange = (value) => {
		setTicketCode(sanitizeTicketCode(value))
	}

	// Assign ticket to guest
	const assignTicket = async () => {
		if (!selectedPerson || !ticketCode) {
			pushAlert({
				type: "ERROR",
				title: "Error",
				body: "Please select a guest and enter a ticket code",
			})
			return
		}

		try {
			// Check if ticket code is already assigned
			const existingTicket = tickets.find((t) => t.code === ticketCode)
			if (existingTicket) {
				pushAlert({
					type: "ERROR",
					title: "Error",
					body: "This ticket code has already been assigned",
				})
				return
			}

			// Create new ticket record
			const newTicket = {
				code: ticketCode,
				personId: selectedPerson.id,
				bookingId: selectedPerson.bookingId,
				assignedBy: user.uid,
				assignedAt: new Date(),
				returned: false,
				returnedAt: null,
				day: moment().format("dddd").toLowerCase(),
			}

			// Add to tickets collection
			const ticketRef = await db.collection("events/masters_2025/tickets").add(newTicket)

			// Create log entry for ticket assignment
			await db.collection("events/masters_2025/logs").add({
				action: "ticket_assigned",
				ticketId: ticketRef.id,
				ticketCode: ticketCode,
				personId: selectedPerson.id,
				bookingId: selectedPerson.bookingId,
				assignedBy: user.uid,
				timestamp: new Date(),
				details: {
					guestName: `${selectedPerson.first_name || ""} ${selectedPerson.last_name || ""}`.trim(),
					reference: selectedPerson.reference,
					day: newTicket.day,
				},
			})

			// Update local state
			const ticketWithId = { id: ticketRef.id, ...newTicket }
			setTickets((prev) => [...prev, ticketWithId])
			setTicketHistory((prev) => [...prev, { ...ticketWithId, action: "assigned", timestamp: newTicket.assignedAt }])

			// Clear form
			setSelectedPerson(null)
			setTicketCode("")
			setSearchTerm("")
			setSearchResults([])

			// Refresh the tickets
			fetchTickets()

			pushAlert({
				type: "SUCCESS",
				title: "Success",
				body: "Ticket assigned successfully",
			})
		} catch (error) {
			console.error("Error assigning ticket:", error)
			pushAlert({
				type: "ERROR",
				title: "Error",
				body: "Failed to assign ticket",
			})
		}
	}

	// Return ticket
	const returnTicket = async (ticketId) => {
		try {
			const ticket = tickets.find((t) => t.id === ticketId)
			if (!ticket) return

			const now = new Date()
			// Update ticket status
			await db.collection("events/masters_2025/tickets").doc(ticketId).update({
				returned: true,
				returnedAt: now,
			})

			// Create log entry for ticket return
			await db.collection("events/masters_2025/logs").add({
				action: "ticket_returned",
				ticketId: ticketId,
				ticketCode: ticket.code,
				personId: ticket.personId,
				bookingId: ticket.bookingId,
				returnedBy: user.uid,
				timestamp: now,
				details: {
					guestName: `${allGroupNames.find((g) => g.id === ticket.personId)?.first_name || ""} ${allGroupNames.find((g) => g.id === ticket.personId)?.last_name || ""}`.trim(),
					reference: allGroupNames.find((g) => g.id === ticket.personId)?.reference,
					day: ticket.day,
				},
			})

			// Update local state
			setTickets((prev) => prev.map((t) => (t.id === ticketId ? { ...t, returned: true, returnedAt: now } : t)))
			setTicketHistory((prev) => [...prev, { ...ticket, returned: true, returnedAt: now, action: "returned", timestamp: now }])

			pushAlert({
				type: "SUCCESS",
				title: "Success",
				body: "Ticket returned successfully",
			})
		} catch (error) {
			console.error("Error returning ticket:", error)
			pushAlert({
				type: "ERROR",
				title: "Error",
				body: "Failed to return ticket",
			})
		}
	}

	// Check if ticket needs to be returned (past midday)
	const needsReturn = (ticket) => {
		if (!ticket.assignedAt) return false
		const assignedDate = moment(ticket.assignedAt.seconds * 1000)
		const midday = moment(assignedDate).set({ hour: 12, minute: 0, second: 0 })
		return moment().isAfter(midday) && !ticket.returned
	}

	// Filter tickets based on selected day and status
	const filteredTickets = tickets.filter((ticket) => {
		const dayMatch = selectedDay === "all" || ticket.day === selectedDay
		const statusMatch = selectedStatus === "all" || (selectedStatus === "needs_return" && needsReturn(ticket)) || (selectedStatus === "returned" && ticket.returned)
		return dayMatch && statusMatch
	})

	return (
		<div className="masters-tickets-container">
			<div className="masters-tickets-assign">
				<div className="masters-tickets-search">
					<Input
						value={searchTerm}
						onChange={handleSearchChange}
						placeholder="Search for a guest..."
						type="text"
					/>
				</div>

				{searchResults.length > 0 && (
					<div className="masters-tickets-search-results">
						{searchResults.map((person) => (
							<div
								key={person.id}
								className={`masters-tickets-search-result ${selectedPerson?.id === person.id ? "selected" : ""}`}
								onClick={() => setSelectedPerson(person)}>
								{`${person.first_name || ""} ${person.last_name || ""}`.trim()} - {person.reference}
							</div>
						))}
					</div>
				)}

				{selectedPerson && (
					<div className="masters-tickets-assign-form">
						<Input
							value={ticketCode}
							onChange={handleTicketCodeChange}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									assignTicket()
								}
							}}
							placeholder="Enter ticket code..."
							type="text"
						/>
						<Button
							label="Assign Ticket"
							onClick={assignTicket}
						/>
					</div>
				)}
			</div>

			<div className="masters-tickets-filters">
				<div className="masters-tickets-filter">
					<Select
						value={mastersDays[selectedDay]}
						onSelect={(option) => setSelectedDay(option.option)}
						options={mastersDays}
						label="Day"
					/>
				</div>
				<div className="masters-tickets-filter">
					<Select
						value={statusOptions[selectedStatus]}
						onSelect={(option) => setSelectedStatus(option.option)}
						options={statusOptions}
						label="Status"
					/>
				</div>
			</div>

			<div className="masters-tickets-table">
				<Table
					className="bookings-table"
					headings={["Ticket Code", "Guest", "Email", "Phone", "Booking", "Assigned At", "Status", "Actions"]}>
					{filteredTickets.length > 0 ? (
						filteredTickets.map((ticket) => {
							// Find the guest details from all group names
							const guest = allGroupNames.find((g) => g.id === ticket.personId) || {
								first_name: "",
								last_name: "",
								email: "-",
								phone: "-",
								reference: "-",
							}
							return (
								<tr key={ticket.id}>
									<td>{ticket.code}</td>
									<td>{`${guest.first_name || ""} ${guest.last_name || ""}`.trim() || "-"}</td>
									<td>{guest.email || "-"}</td>
									<td>{guest.phone || "-"}</td>
									<td>
										<NavLink to={`/booking/${ticket.bookingId}`}>{guest.reference}</NavLink>
									</td>
									<td>{moment(ticket.assignedAt.seconds * 1000).format("MMM D, YYYY HH:mm")}</td>
									<td>
										<Badge
											type={ticket.returned ? "POSITIVE" : "AMBER"}
											label={ticket.returned ? "Returned" : "Needs Return"}
										/>
									</td>
									<td>
										{!ticket.returned && (
											<Button
												label="Return Ticket"
												onClick={() => returnTicket(ticket.id)}
												small
											/>
										)}
									</td>
								</tr>
							)
						})
					) : (
						<tr>
							<td
								colSpan="8"
								className="masters-no-data">
								No tickets found matching the selected filters
							</td>
						</tr>
					)}
				</Table>
			</div>
		</div>
	)
}
