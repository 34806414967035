import React, { useState, useEffect, useContext, useRef } from "react"
import { AuthContext } from "../../utils/providers/auth"
import { CALENDAR_MONTHS } from "../../utils/exports/months"
import { auth, db } from "../../utils/firebase"
import firebase from "firebase"
import moment from "moment"
import "./stats.scss"

/**
 * UI components
 */
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Select from "../../components/ui/select/select"
import Button from "../../components/ui/button/button"
import Table from "../../components/structure/table/table"
import Booking from "../../components/stats/booking"
import Checkbox from "../../components/ui/checkbox/checkbox"
import exportToExcel from "./_utils/export"

/**
 * Functional component to return the dashboard for viewing travellers by various dates
 */
export default function Stats() {
	const [loading, setLoading] = useState(false)
	const [bookings, setBookings] = useState([])

	const [exportArr, setExportArr] = useState([])
	const [exportTitle, setExportTitle] = useState("")

	const [agents, setAgents] = useState({})
	const [years, setYears] = useState({})

	const [agent, setAgent] = useState("")
	const [month, setMonth] = useState("")
	const [year, setYear] = useState("")
	const [type, setType] = useState("")
	const [flightsOnly, setFlightsOnly] = useState(false)

	const [flightsCost, setFlightsCost] = useState(0)
	const [customerPrice, setCustomerPrice] = useState(0)
	const [paidByClient, setPaidByClient] = useState(0)
	const [supplierInvoices, setSupplierInvoices] = useState(0)
	const [margins, setMargins] = useState(0)
	const [commission, setCommission] = useState(0)

	const [newMargins, setNewMargins] = useState(0)
	const [newCommission, setNewCommission] = useState(0)
	const [newCommissionOwed, setNewCommissionOwed] = useState(0)

	const [paxFlying, setPaxFlying] = useState(0)
	const [paxNotFlying, setPaxNotFlying] = useState(0)

	/**
	 *
	 */
	const totalMarginStatsFigure = useRef({})
	const totalCommisionStatsFigure = useRef({})
	const totalCommissionOwedStatsFigure = useRef({})

	const paxWithFlights = useRef({})
	const paxWithoutFlights = useRef({})

	/**
	 * Get the current user auth from the context
	 */
	const { user } = useContext(AuthContext)

	/**
	 *
	 */
	const clearLocalFigures = () => {
		/**
		 *
		 */
		totalMarginStatsFigure.current = {}
		totalCommisionStatsFigure.current = {}
		paxWithFlights.current = {}
		paxWithoutFlights.current = {}

		/**
		 *
		 */
		setBookings([])
		setNewMargins(0)
		setNewCommission(0)
		setPaxFlying(0)
		setPaxNotFlying(0)
	}

	// When the bookings are updated
	useEffect(() => {
		// Return early if there are no bookings
		if (bookings.length === 0 || !user?.can_export) return

		// Build a file name from the parameters
		setExportTitle(`${agent ? agents[agent.option] : "AllAgents"}_${month ? month : "All"}${year}_${type === "booked_date" ? "ByBookedDate" : "ByTravelDate"}${flightsOnly ? "_FlightsOnly" : ""}`)

		// Otherwise, loop through the bookings
		const exportData = bookings.map((booking) => {
			// Deconstruct the booking details
			const { booked, reference, check_in, golfers, non_golfers, booked_location, margins, confirmed, resorts_paid, paid_by_client, flights_booked, enquiry } = booking

			// Deconstruct the margins
			const { customer_price, flights_cost, supplier_invoices, agents_margin } = margins || {}

			// Return the booking details
			return {
				booked: moment(booked?.seconds, "X").format("MMM D[,] YYYY"),
				reference,
				site: enquiry?.site,
				check_in: moment(check_in?.seconds, "X").format("MMM D[,] YYYY"),
				non_flying_pax: flights_cost === 0 ? golfers + non_golfers : 0,
				flying_pax: flights_cost > 0 ? golfers + non_golfers : 0,
				location: booked_location,
				flights_cost: flights_cost || 0,
				flights_booked: flights_booked ? "TRUE" : "FALSE",
				customer_price: customer_price || 0,
				paid_by_client: paid_by_client || 0,
				supplier_invoices: supplier_invoices || 0,
				sales_margin: customer_price - supplier_invoices,
				commission: agents_margin || 0,
				confirmed: confirmed ? "TRUE" : "FALSE",
				resorts_paid: resorts_paid ? "TRUE" : "FALSE",
			}
		})

		// Compute the totals for numeric fields
		const totals = {
			booked: "Totals",
			reference: "",
			site: "",
			check_in: "",
			non_flying_pax: exportData.reduce((sum, item) => sum + (item.non_flying_pax || 0), 0),
			flying_pax: exportData.reduce((sum, item) => sum + (item.flying_pax || 0), 0),
			location: "",
			flights_cost: `£${exportData.reduce((sum, item) => sum + (item.flights_cost || 0), 0).toLocaleString()}`,
			flights_booked: "",
			customer_price: `£${exportData.reduce((sum, item) => sum + (item.customer_price || 0), 0).toLocaleString()}`,
			paid_by_client: `£${exportData.reduce((sum, item) => sum + (item.paid_by_client || 0), 0).toLocaleString()}`,
			supplier_invoices: `£${exportData.reduce((sum, item) => sum + (item.supplier_invoices || 0), 0).toLocaleString()}`,
			sales_margin: `£${exportData.reduce((sum, item) => sum + (item.sales_margin || 0), 0).toLocaleString()}`,
			commission: `£${exportData.reduce((sum, item) => sum + (item.commission || 0), 0).toLocaleString()}`,
			confirmed: "",
			resorts_paid: "",
		}

		// Add an empty row to the end of the export data
		exportData.push({})

		// Add the totals row
		exportData.push(totals)

		// Set the export data into the state
		setExportArr(exportData)
	}, [bookings])

	/**
	 * On load for the context
	 */
	useEffect(() => {
		/**
		 * Is this user an admin? If they are, they should have access to all agents stats,
		 * otherswise only allow access to their own
		 */
		if (user?.is_admin) {
			/**
			 * Pull a list of agents from the use list
			 */
			db.collection("users")
				.orderBy("first_name")
				.get()
				.then((agentDocs) => {
					let agentsArr = []
					/**
					 * Loop through the agent documents found
					 */
					agentDocs.forEach((agentDoc) => {
						/**
						 * Deconstruct the agent details
						 */
						const { first_name, last_name } = agentDoc.data()
						/**
						 * Add them into the array
						 */
						agentsArr[agentDoc.id] = `${first_name} ${last_name}`
					})
					/**
					 * Push the user into the state
					 */
					setAgents(agentsArr)
				})
		} else if (user?.is_manager) {
			/**
			 * Pull a list of agents from the use list
			 */
			db.collection("users")
				.where("manager", "==", auth.currentUser.uid)
				.orderBy("first_name")
				.get()
				.then((agentDocs) => {
					let agentsArr = []

					// Add the current user into the agentArr
					agentsArr[auth.currentUser.uid] = `${user?.first_name} ${user?.last_name}`

					/**
					 * Loop through the agent documents found
					 */
					agentDocs.forEach((agentDoc) => {
						/**
						 * Deconstruct the agent details
						 */
						const { first_name, last_name } = agentDoc.data()
						/**
						 * Add them into the array
						 */
						agentsArr[agentDoc.id] = `${first_name} ${last_name}`
					})
					/**
					 * Push the user into the state
					 */
					setAgents(agentsArr)
				})
		} else {
			let agentsArr = []
			/**
			 * Assign only this agents user ID into the array
			 */
			agentsArr[auth.currentUser.uid] = `${user?.first_name} ${user?.last_name}`
			/**
			 * Set this single-item array into the state
			 */
			setAgents(agentsArr)
		}
	}, [])

	/**
	 * On component load
	 */
	useEffect(() => {
		/**
		 * Create an empty object for storing the years
		 */
		let yearsObj = {}
		/**
		 * Get next year as a number
		 */
		let nextYear = Number(moment().startOf(year).add(2, "years").format("YYYY"))
		/**
		 * Run through a for loop to print out the years
		 */
		for (let i = nextYear; i >= 2018; i--) {
			yearsObj = {
				[i]: i,
				...yearsObj,
			}
		}
		/**
		 * Set the years into the state
		 */
		setYears(yearsObj)
	}, [])

	/**
	 * Fetch the travellers from the database
	 */
	const fetchTravellers = async () => {
		/**
		 * Clear the local figures
		 */
		clearLocalFigures()

		/**
		 * Show a loading spinner
		 */
		setLoading(true)

		if (year && !month && !agent && user.is_admin) {
			byYear()
		} else if (year && month && !agent && user.is_admin) {
			byYearAndMonth()
		} else if (year && month && agent) {
			byYearMonthAndAgent()
		} else {
			setLoading(false)
		}
	}

	const byYear = async () => {
		/**
		 * Get the milliseconds timestamps from the chosen filters
		 */
		const fetchStart = moment(`${year}`, "YYYY").startOf("year").valueOf()
		const fetchEnd = moment(`${year}`, "YYYY").endOf("year").valueOf()
		/**
		 * Build some firestore timestamps from the milliseconds
		 */
		const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart)
		const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd)
		/**
		 * Setup a variable to store the field we are going to be searching the database with
		 */
		let searchBy = "check_in"
		/**
		 * Are we fetching the bookings by travel or booked date?
		 */
		if (type === "travel_date") {
			searchBy = "check_in"
		} else if (type === "booked_date") {
			searchBy = "booked"
		}
		/**
		 * Pull all the bookings by their travel date
		 */
		await db
			.collection("bookings")
			.where(`${searchBy}`, ">=", startTimestamp)
			.where(`${searchBy}`, "<=", endTimestamp)
			.where("removed", "==", false)
			.orderBy(`${searchBy}`)
			.get()
			.then((bookingDocs) => {
				/**
				 * Create an empty array for storing the bookings
				 */
				let bookingsArr = []
				/**
				 * Setup some variables for storing the totals
				 */
				let totalFlightsCost = 0
				let totalCustomerPrices = 0
				let totalPaidByClients = 0
				let totalSupplierInvoices = 0
				let totalSalesMargins = 0
				let totalCommissions = 0
				/**
				 * Loop through the bookings that have been found that match
				 */
				bookingDocs.forEach((bookingDoc) => {
					/**
					 * Deconstruct some balances from the booking document
					 */
					const { paid_by_client, margins } = bookingDoc.data()
					/**
					 * Pull the margins from the
					 */
					const { customer_price, flights_cost, supplier_invoices, agents_margin } = margins || {}

					// Are we looking for only those bookings with a flights value attached?
					if ((flightsOnly && flights_cost > 0) || (!flightsOnly && flights_cost >= 0)) {
						/**
						 * Check to make sure there is a customer price and supplier invoice figures
						 */
						if (margins && customer_price > 0 && supplier_invoices > 0) {
							/**
							 * work out the sales margin for the booking
							 */
							const salesMargin = Number.parseFloat(customer_price - supplier_invoices)
							/**
							 * Add the values from this booking
							 */
							totalFlightsCost = totalFlightsCost + Number.parseFloat(flights_cost || 0)
							totalCustomerPrices = totalCustomerPrices + Number.parseFloat(customer_price || 0)
							totalPaidByClients = totalPaidByClients + Number.parseFloat(paid_by_client || 0)
							totalSupplierInvoices = totalSupplierInvoices + Number.parseFloat(supplier_invoices || 0)
							totalSalesMargins = totalSalesMargins + salesMargin
							totalCommissions = totalCommissions + Number.parseFloat(agents_margin || 0)
							/**
							 * Push the booking data into the array
							 */
							bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data() })
						} else {
							/**
							 * Push the booking data into the array
							 */
							bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data(), missing_values: true })
						}
					}
				})
				/**
				 * Set the bookings into the state
				 */
				setBookings(bookingsArr)
				/**
				 * Set the totals into the state
				 */
				setFlightsCost(totalFlightsCost || 0)
				setCustomerPrice(totalCustomerPrices || 0)
				setPaidByClient(totalPaidByClients || 0)
				setSupplierInvoices(totalSupplierInvoices || 0)
				setMargins(totalSalesMargins || 0)
				setCommission(totalCommissions || 0)
			})
		/**
		 * Reset the state
		 */
		setLoading(false)
	}

	const byYearAndMonth = async () => {
		/**
		 * Get the milliseconds timestamps from the chosen filters
		 */
		const fetchStart = moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf()
		const fetchEnd = moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf()
		/**
		 * Build some firestore timestamps from the milliseconds
		 */
		const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart)
		const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd)
		/**
		 * Setup a variable to store the field we are going to be searching the database with
		 */
		let searchBy = "check_in"
		/**
		 * Are we fetching the bookings by travel or booked date?
		 */
		if (type === "travel_date") {
			searchBy = "check_in"
		} else if (type === "booked_date") {
			searchBy = "booked"
		}

		// Query the database for all the bookings that match the requirements
		await db
			.collection("bookings")
			.where(`${searchBy}`, ">=", startTimestamp)
			.where(`${searchBy}`, "<=", endTimestamp)
			.where("removed", "==", false)
			.orderBy(`${searchBy}`)
			.get()
			.then((bookingDocs) => {
				/**
				 * Create an empty array for storing the bookings
				 */
				let bookingsArr = []
				/**
				 * Setup some variables for storing the totals
				 */
				let totalFlightsCost = 0
				let totalCustomerPrices = 0
				let totalPaidByClients = 0
				let totalSupplierInvoices = 0
				let totalSalesMargins = 0
				let totalCommissions = 0
				/**
				 * Loop through the bookings that have been found that match
				 */
				bookingDocs.forEach((bookingDoc) => {
					/**
					 * Deconstruct some balances from the booking document
					 */
					const { paid_by_client, margins } = bookingDoc.data()

					// Pull the margins from the booking
					const { customer_price, flights_cost, supplier_invoices, agents_margin } = margins || {}

					// Are we looking for only those bookings with a flights value attached?
					if ((flightsOnly && flights_cost > 0) || (!flightsOnly && flights_cost >= 0)) {
						// Check to make sure there is a customer price and supplier invoice figures
						if (margins && customer_price > 0 && supplier_invoices > 0) {
							// Work out the sales margin for the booking
							const salesMargin = Number.parseFloat(customer_price - supplier_invoices)

							// Add the values from this booking
							totalFlightsCost = totalFlightsCost + Number.parseFloat(flights_cost || 0)
							totalCustomerPrices = totalCustomerPrices + Number.parseFloat(customer_price || 0)
							totalPaidByClients = totalPaidByClients + Number.parseFloat(paid_by_client || 0)
							totalSupplierInvoices = totalSupplierInvoices + Number.parseFloat(supplier_invoices || 0)
							totalSalesMargins = totalSalesMargins + salesMargin
							totalCommissions = totalCommissions + Number.parseFloat(agents_margin || 0)

							// Push the booking data into the array
							bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data() })
						} else {
							// Push the booking into the array without the additional values
							bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data(), missing_values: true })
						}
					}
				})
				/**
				 * Set the bookings into the state
				 */
				setBookings(bookingsArr)
				/**
				 * Set the totals into the state
				 */
				setFlightsCost(totalFlightsCost || 0)
				setCustomerPrice(totalCustomerPrices || 0)
				setPaidByClient(totalPaidByClients || 0)
				setSupplierInvoices(totalSupplierInvoices || 0)
				setMargins(totalSalesMargins || 0)
				setCommission(totalCommissions || 0)
			})
		/**
		 * Reset the state
		 */
		setLoading(false)
	}

	const byYearMonthAndAgent = async () => {
		/**
		 * Get the milliseconds timestamps from the chosen filters
		 */
		const fetchStart = moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf()
		const fetchEnd = moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf()
		/**
		 * Build some firestore timestamps from the milliseconds
		 */
		const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart)
		const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd)
		/**
		 * Setup a variable to store the field we are going to be searching the database with
		 */
		let searchBy = "check_in"
		/**
		 * Are we fetching the bookings by travel or booked date?
		 */
		if (type === "travel_date") {
			searchBy = "check_in"
		} else if (type === "booked_date") {
			searchBy = "booked"
		}
		/**
		 * Pull all the bookings by their travel date
		 */
		await db
			.collection("bookings")
			.where(`${searchBy}`, ">=", startTimestamp)
			.where(`${searchBy}`, "<=", endTimestamp)
			.where("agent", "==", agent.option)
			.where("removed", "==", false)
			.orderBy(`${searchBy}`)
			.get()
			.then((bookingDocs) => {
				/**
				 * Create an empty array for storing the bookings
				 */
				let bookingsArr = []
				/**
				 * Setup some variables for storing the totals
				 */
				let totalFlightsCost = 0
				let totalCustomerPrices = 0
				let totalPaidByClients = 0
				let totalSupplierInvoices = 0
				let totalSalesMargins = 0
				let totalCommissions = 0
				/**
				 * Loop through the bookings that have been found that match
				 */
				bookingDocs.forEach((bookingDoc) => {
					/**
					 * Deconstruct some balances from the booking document
					 */
					const { paid_by_client, margins } = bookingDoc.data()
					/**
					 * Pull the margins from the
					 */
					const { customer_price, flights_cost, supplier_invoices, agents_margin } = margins || {}

					// Are we looking for only those bookings with a flights value attached?
					if ((flightsOnly && flights_cost > 0) || (!flightsOnly && flights_cost >= 0)) {
						/**
						 * Check to make sure there is a customer price and supplier invoice figures
						 */
						if (margins && customer_price > 0 && supplier_invoices > 0) {
							/**
							 * work out the sales margin for the booking
							 */
							const salesMargin = Number.parseFloat(customer_price - supplier_invoices)
							/**
							 * Add the values from this booking
							 */
							totalFlightsCost = totalFlightsCost + Number.parseFloat(flights_cost || 0)
							totalCustomerPrices = totalCustomerPrices + Number.parseFloat(customer_price || 0)
							totalPaidByClients = totalPaidByClients + Number.parseFloat(paid_by_client || 0)
							totalSupplierInvoices = totalSupplierInvoices + Number.parseFloat(supplier_invoices || 0)
							totalSalesMargins = totalSalesMargins + salesMargin
							totalCommissions = totalCommissions + Number.parseFloat(agents_margin || 0)
							/**
							 * Push the booking data into the array
							 */
							bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data() })
						} else {
							/**
							 * Push the booking data into the array
							 */
							bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data(), missing_values: true })
						}
					}
				})
				/**
				 * Set the bookings into the state
				 */
				setBookings(bookingsArr)
				/**
				 * Set the totals into the state
				 */
				setFlightsCost(totalFlightsCost || 0)
				setCustomerPrice(totalCustomerPrices || 0)
				setPaidByClient(totalPaidByClients || 0)
				setSupplierInvoices(totalSupplierInvoices || 0)
				setMargins(totalSalesMargins || 0)
				setCommission(totalCommissions || 0)
			})
		/**
		 * Reset the state
		 */
		setLoading(false)
	}

	const allHeadings = [
		"Booked",
		"Reference",
		"Site",
		"Agent",
		"Lead pax",
		"Email",
		"Travelling",
		"Returning",
		"No. of pax",
		"Location",
		"Flights",
		"Holiday cost",
		"Paid by client",
		"Supplier invoice",
		"Sales margin",
		"Commission",
		"Commission owed",
		"Confirmed",
		"Resorts paid",
		"",
	]

	const restrictedHeadings = [
		"Booked",
		"Reference",
		"Site",
		"Agent",
		"Lead pax",
		"Travelling",
		"Returning",
		"No. of pax",
		"Location",
		"Flights",
		"Holiday cost",
		"Paid by client",
		"Supplier invoice",
		"Sales margin",
		"Commission",
		"Commission owed",
		"Confirmed",
		"Resorts paid",
		"",
	]

	const updateLocalFigures = (id, type, figure) => {
		if (isNaN(figure)) return

		if (type === "MARGIN") {
			totalMarginStatsFigure.current[id] = figure
		} else if (type === "COMMISION") {
			totalCommisionStatsFigure.current[id] = figure
		} else if (type === "COMMISSION_OWED") {
			console.log(figure)
			totalCommissionOwedStatsFigure.current[id] = figure
		}

		setNewMargins(
			Object.values(totalMarginStatsFigure.current)
				.reduce((a, b) => a + b, 0)
				?.toLocaleString()
		)
		setNewCommission(
			Object.values(totalCommisionStatsFigure.current)
				.reduce((a, b) => a + b, 0)
				?.toLocaleString()
		)
		setNewCommissionOwed(
			Object.values(totalCommissionOwedStatsFigure.current)
				.reduce((a, b) => a + b, 0)
				?.toLocaleString()
		)
	}

	const updateLocalPAX = (id, pax = 0, flying = false) => {
		if (flying) {
			paxWithFlights.current[id] = pax
		} else {
			paxWithoutFlights.current[id] = pax
		}

		setPaxFlying(
			Object.values(paxWithFlights.current)
				.reduce((a, b) => a + b, 0)
				?.toLocaleString()
		)
		setPaxNotFlying(
			Object.values(paxWithoutFlights.current)
				.reduce((a, b) => a + b, 0)
				?.toLocaleString()
		)
	}

	return (
		<Tile fullPage={true}>
			<Title className="flex has-select-field">
				<h1>Stats</h1>
			</Title>

			<div className="page-filters extra-stats">
				<Select
					label="Agent:"
					placeholder="Agent:"
					value={agent}
					selected={agent.option}
					activeOnHover={true}
					onSelect={(option) => setAgent(option)}
					options={agents}
				/>

				<Select
					label="Month:"
					placeholder="Month:"
					value={month}
					activeOnHover={true}
					onSelect={(option) => setMonth(option.option)}
					options={CALENDAR_MONTHS}
				/>

				<Select
					label="Year:"
					placeholder="Year:"
					value={year}
					activeOnHover={true}
					onSelect={(option) => setYear(option.option)}
					options={years}
				/>

				<Select
					label="Search by:"
					placeholder="Search by:"
					value={type}
					selected={type}
					activeOnHover={true}
					onSelect={(option) => setType(option.option)}
					options={{
						travel_date: "Travel date",
						booked_date: "Booked date",
					}}
				/>

				<Checkbox
					checked={flightsOnly}
					onClick={() => setFlightsOnly(!flightsOnly)}
					label="Only inc. flights"
				/>

				<div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
					<Button
						filters={true}
						disabled={((!agent || !month || !year) && (!month || !year) && !year) || !type}
						label="Fetch stats"
						loadingText="Fetching stats..."
						loading={loading}
						onClick={() => fetchTravellers()}
					/>

					{user?.can_export && (
						<div>
							<Button
								filters={true}
								disabled={exportArr.length === 0}
								label="Export to XLSX"
								onClick={() => exportToExcel(exportArr, exportTitle)}
							/>
						</div>
					)}
				</div>
			</div>

			<Table
				className="travellers-table"
				headings={user?.is_super_admin ? allHeadings : restrictedHeadings}
				noResults={Object.entries(bookings).length === 0}
				noResultsMessage={"No results matching those filters"}>
				{bookings.map((booking) => (
					<Booking
						key={booking?.id}
						id={booking?.id}
						details={booking}
						show_email={user?.is_super_admin}
						update_local_totals={(id, type, figure) => updateLocalFigures(id, type, figure)}
						update_local_pax={(id, pax, flying) => updateLocalPAX(id, pax, flying)}
					/>
				))}

				<tr className="totals-row">
					<td colSpan={user?.is_super_admin ? "8" : "7"}>Totals:</td>
					<td>
						{paxFlying} (F) - {paxNotFlying} (NF)
					</td>
					<td></td>
					<td>£{flightsCost?.toLocaleString() || 0}</td>
					<td>£{customerPrice?.toLocaleString() || 0}</td>
					<td>£{paidByClient?.toLocaleString() || 0}</td>
					<td>£{supplierInvoices?.toLocaleString() || 0}</td>
					<td>£{newMargins || 0}</td>
					<td>£{newCommission || 0}</td>
					<td>£{newCommissionOwed || 0}</td>
				</tr>
			</Table>
		</Tile>
	)
}
