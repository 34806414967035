import React, { useState, useEffect } from "react"
import { db } from "../../../utils/firebase"
import moment from "moment"
import "./log.scss"

/**
 * UI components
 */
import Badge from "../../../components/ui/badge/badge"

function Log(props) {
	const [userName, setUserName] = useState("")
	const [badgeType, setBadgeType] = useState("")
	const [badgeLabel, setBadgeLabel] = useState("")

	/**
	 * Deconstruct the log data
	 */
	const { type, badge, message, user, created } = props.details

	/**
	 * Create a readable date from the seconds timestamp for the log document
	 */
	const log_date = moment(created?.seconds, "X").format("DD/MM/YYYY [at] HH:mm:ss")

	// Setup a variable to hold the log message
	let logMessage = message

	// Do we need to mask any client emails?
	if (props?.maskDetails) {
		// Mask any emails if the log message isn't an array
		if (!Array.isArray(logMessage)) {
			// logMessage = logMessage?.replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g, "**********");
		}
	}

	// When the badge type is updated
	useEffect(() => {
		if (badge === "BLUE" || badge === "INFO") {
			setBadgeType("INFO")
		}

		if (badge === "GREEN" || badge === "SUCCESS") {
			setBadgeType("POSITIVE")
		}

		if (badge === "AMBER" || badge === "WARNING") {
			setBadgeType("AMBER")
		}

		if (badge === "RED" || badge === "ERROR") {
			setBadgeType("NEGATIVE")
		}

		if (badge === "SPECIAL") {
			setBadgeType("SPECIAL")
		}
	}, [badge])

	/**
	 * When the log type is updated
	 */
	useEffect(() => {
		/**
		 * First remove the underscores
		 */
		const underscores = type.replace(/_/g, " ").toLowerCase()
		/**
		 * Then convert the words to capitals on the first
		 */
		const words = underscores.split(" ")
		/**
		 * Loop through the words
		 */
		for (let i = 0; i < words.length; i++) {
			/**
			 * Covnert the first letter to a capital
			 */
			words[i] = words[i][0].toUpperCase() + words[i].substr(1)
		}
		/**
		 * Then join the array back into a string
		 */
		const joined = words.join(" ")
		/**
		 * And set it into the state
		 */
		setBadgeLabel(joined)
	}, [type])

	/**
	 * When the user ID is updated
	 */
	useEffect(() => {
		/**
		 * Attempt to fetch the user from the staff collection
		 */
		user && fetchLogTriggerUser()
	}, [user])

	/**
	 * Fetch the name of the user who triggered the log
	 */
	const fetchLogTriggerUser = async () => {
		const staffName = await db
			.doc(`users/${user}`)
			.get()
			.then((staffDoc) => {
				/**
				 * Was a document found for this user
				 */
				if (staffDoc.exists) {
					/**
					 * Return their full name if a document was found
					 */
					return `${staffDoc.data().first_name} ${staffDoc.data().last_name}`
				} else {
					/**
					 * If a staff account wasn't found, return a false statement
					 */
					return false
				}
			})
		/**
		 * If there was a staff name returned
		 */
		if (staffName) {
			/**
			 * Set their name into the state
			 */
			setUserName(staffName)
		} else {
			/**
			 * If there wasn't, attempt to get a client account instead
			 */
			const clientName = await db
				.doc(`clients/${user}`)
				.get()
				.then((clientDoc) => {
					/**
					 * If a client document was found
					 */
					if (clientDoc.exists) {
						/**
						 * Return their full name if a document was found
						 */
						return `${clientDoc.data().first_name} ${clientDoc.data().last_name}`
					} else {
						/**
						 * If there wasn't one found, return a false statement
						 */
						return false
					}
				})
			/**
			 * If there was a client document found
			 */
			if (clientName) {
				/**
				 * Set their name into the state
				 */
				setUserName(clientName)
			}
		}
	}

	return (
		<tr>
			{/* Log type, shown as a badge */}
			<td>
				<Badge
					label={badgeLabel}
					type={badgeType}
				/>
			</td>

			{/* Date the log was created */}
			<td>{log_date}</td>

			{/* User who triggered the log */}
			<td>
				{userName && <>{userName}</>}

				{!userName && <small>No user attached</small>}
			</td>

			{/* Log message */}
			<td>
				{type !== "MATH_TRAIL" && logMessage}
				{type === "MATH_TRAIL" && (
					<ol className="log-math-price-column">
						{message.map((line) => (
							<li>{line}</li>
						))}
					</ol>
				)}
			</td>
		</tr>
	)
}

export default Log
