import React, { useEffect, useState } from "react"
import { db } from "../../utils/firebase"
import firebase from "firebase/app"
import moment from "moment"

import { CheckIcon, XIcon } from "lucide-react"
import Badge from "../ui/badge/badge"

export default function HistoricalTargetRow({ id, details }) {
	const [monthlyData, setMonthlyData] = useState({})
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const fetchHistoricalData = async () => {
			const months = Array.from({ length: 12 }, (_, i) => moment().subtract(i, "months").format("YYYY_MM")).reverse()

			const data = {}

			for (const month of months) {
				// Get the target for this month
				const targetDoc = await db.doc(`targets/${id}/archives/${month}`).get()

				// Get all bookings for this month to calculate actual sales
				const startOfMonth = moment(month, "YYYY_MM").startOf("month").valueOf()
				const endOfMonth = moment(month, "YYYY_MM").endOf("month").valueOf()

				const startTimestamp = firebase.firestore.Timestamp.fromMillis(startOfMonth)
				const endTimestamp = firebase.firestore.Timestamp.fromMillis(endOfMonth)

				const bookings = await db.collection("bookings").where("agent", "==", id).where("created", ">=", startTimestamp).where("created", "<=", endTimestamp).get()

				let totalSales = 0
				bookings.forEach((doc) => {
					totalSales += doc.data().margins?.profit || 0
				})

				if (targetDoc.exists) {
					const target = targetDoc.data().monthly
					const percentage = (totalSales / target) * 100

					data[month] = {
						target,
						actual: totalSales,
						achieved: totalSales >= target,
						percentage: percentage.toFixed(1),
					}
				}
			}

			setMonthlyData(data)
			setLoading(false)
		}

		fetchHistoricalData()
	}, [id])

	if (loading) {
		return (
			<tr>
				<td colSpan="13">Loading...</td>
			</tr>
		)
	}

	return (
		<tr>
			<td>
				{details.first_name} {details.last_name}
			</td>
			{Array.from({ length: 12 }, (_, i) => moment().subtract(i, "months").format("YYYY_MM"))
				.reverse()
				.map((month) => (
					<td
						key={month}
						className="text-center">
						{monthlyData[month] ? (
							<>
								<div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
									{monthlyData[month].achieved ? (
										<CheckIcon
											size={19}
											color="#22b229"
										/>
									) : (
										<XIcon
											size={19}
											color="#d3423d"
										/>
									)}

									<div>
										{monthlyData[month].percentage >= 100 && (
											<Badge
												type="POSITIVE"
												label={`${monthlyData[month].percentage}%`}
											/>
										)}

										{monthlyData[month].percentage < 100 && (
											<Badge
												type="NEGATIVE"
												label={`${monthlyData[month].percentage}%`}
											/>
										)}
									</div>
								</div>
							</>
						) : (
							<small>-</small>
						)}
					</td>
				))}
		</tr>
	)
}
