import * as XLSX from "xlsx"
import moment from "moment"
import { auth, db } from "../../../utils/firebase"

const exportToExcel = async (data, fileName) => {
	// Sort data by staff name
	const sortedData = [...data].sort((a, b) => a["Staff Name"].localeCompare(b["Staff Name"]))

	// Calculate total commission
	const totalCommission = sortedData.reduce((sum, row) => {
		const amount = parseFloat(row["Commission Amount"].replace("£", ""))
		return sum + amount
	}, 0)

	// Get current user's name from their document
	const currentUserDoc = await db.doc(`users/${auth.currentUser.uid}`).get()
	const currentUserData = currentUserDoc.data()
	const generatedBy = currentUserData?.first_name && currentUserData?.last_name ? `${currentUserData.first_name} ${currentUserData.last_name}` : "Unknown"

	// Create metadata section
	const metadata = [
		["Commission Settlement Report", "", "", "", ""],
		["", "", "", "", ""],
		["Generated by:", generatedBy, "", "", ""],
		["Generated on:", moment().format("DD/MM/YYYY HH:mm:ss"), "", "", ""],
		["Total Commission Amount:", `£${totalCommission.toFixed(2)}`, "", "", ""],
		["Number of Agents:", sortedData.length, "", "", ""],
		["", "", "", "", ""],
		["Note: Commission is split into two payments:", "", "", "", ""],
		["1. 50% paid in the month of booking", "", "", "", ""],
		["2. 50% paid in the month of travel", "", "", "", ""],
		["", "", "", "", ""],
		["", "", "", "", ""],
		// Headers for the agent summary
		["Agent Summary", "", "", "", ""],
		["Agent Name", "Total Commission", "Commission Paid", "Commission Owed", "Number of Bookings", "Status", "Payment Type", "Payment Status"],
	]

	// Add agent summary data
	sortedData.forEach((row) => {
		const commissionAmount = parseFloat(row["Commission Amount"].replace("£", ""))
		const commissionPaid = parseFloat(row["Commission Paid"].replace("£", ""))
		const commissionOwed = commissionAmount - commissionPaid

		// Determine status based on commission amounts
		let status = ""
		if (commissionAmount > 0 && commissionPaid === 0) {
			status = "Missing Payment"
		} else if (commissionOwed !== 0) {
			status = "Discrepancy"
		}

		// Determine payment type and status
		let paymentType = "Full Payment"
		let paymentStatus = "Complete"
		if (commissionPaid === 0) {
			paymentType = "No Payment"
			paymentStatus = "Pending"
		} else if (commissionPaid === commissionAmount) {
			paymentType = "Full Payment"
			paymentStatus = "Complete"
		} else if (commissionPaid > 0) {
			paymentType = "Partial Payment"
			paymentStatus = "In Progress"
		}

		metadata.push([row["Staff Name"], row["Commission Amount"], row["Commission Paid"], `£${commissionOwed.toFixed(2)}`, row["Number of Bookings"], status, paymentType, paymentStatus])
	})

	// Add spacing and detailed breakdown header
	metadata.push(
		["", "", "", "", ""],
		["", "", "", "", ""],
		["Detailed Commission Breakdown:", "", "", "", "", ""],
		["Agent Name", "Booking Reference", "Check-in Date", "Total Commission", "Commission Paid", "Commission Owed", "Status", "Payment Type", "Payment Status"]
	)

	// Add booking-by-booking breakdown
	sortedData.forEach((agent) => {
		if (agent.bookings && agent.bookings.length > 0) {
			agent.bookings.forEach((booking) => {
				const commissionAmount = parseFloat(booking.commission_amount || "0")
				const commissionPaid = parseFloat(booking.commission_paid || "0")
				const commissionOwed = commissionAmount - commissionPaid

				// Determine status based on commission amounts
				let status = ""
				if (commissionAmount > 0 && commissionPaid === 0) {
					status = "Missing Payment"
				} else if (commissionOwed !== 0) {
					status = "Discrepancy"
				}

				// Determine payment type and status
				let paymentType = "Full Payment"
				let paymentStatus = "Complete"
				if (commissionPaid === 0) {
					paymentType = "No Payment"
					paymentStatus = "Pending"
				} else if (commissionPaid === commissionAmount) {
					paymentType = "Full Payment"
					paymentStatus = "Complete"
				} else if (commissionPaid > 0) {
					paymentType = "Partial Payment"
					paymentStatus = "In Progress"
				}

				metadata.push([
					agent["Staff Name"],
					booking.reference || "",
					booking.check_in ? moment(booking.check_in.seconds, "X").format("DD/MM/YYYY") : "",
					`£${commissionAmount.toFixed(2)}`,
					`£${commissionPaid.toFixed(2)}`,
					`£${commissionOwed.toFixed(2)}`,
					status,
					paymentType,
					paymentStatus,
				])
			})
		}
	})

	// Create the worksheet
	const worksheet = XLSX.utils.aoa_to_sheet(metadata)

	// Set column widths
	const columnWidths = [
		{ wch: 30 }, // Agent Name
		{ wch: 20 }, // Commission Amount
		{ wch: 20 }, // Commission Paid
		{ wch: 20 }, // Commission Owed
		{ wch: 20 }, // Number of Bookings/Booking Reference
		{ wch: 20 }, // Check-in Date (only used in detailed breakdown)
		{ wch: 15 }, // Status
		{ wch: 15 }, // Payment Type
	]
	worksheet["!cols"] = columnWidths

	// Create a new workbook
	const workbook = XLSX.utils.book_new()

	// Append the worksheet to the workbook
	XLSX.utils.book_append_sheet(workbook, worksheet, "Commission")

	// Generate a binary string
	const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" })

	// Convert the binary string to a Blob
	const blob = new Blob([s2ab(wbout)], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })

	// Create a download link and trigger download
	const url = window.URL.createObjectURL(blob)
	const link = document.createElement("a")
	link.href = url
	link.download = `${fileName}.xlsx`
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
	window.URL.revokeObjectURL(url)

	return blob
}

// Helper function to convert string to ArrayBuffer
function s2ab(s) {
	const buf = new ArrayBuffer(s.length)
	const view = new Uint8Array(buf)
	for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
	return buf
}

export default exportToExcel
