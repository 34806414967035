import React, { useContext, useState, useEffect } from "react"
import { AlertsContext } from "../../../utils/providers/alerts"
import { AuthContext } from "../../../utils/providers/auth"
import { db } from "../../../utils/firebase"
import "./masters-2025.scss"
import moment from "moment"
import { NavLink } from "react-router-dom"

// UI components
import { CheckIcon, XIcon } from "lucide-react"
import Navigation from "../../../components/ui/navigation/navigation"
import Checkbox from "../../../components/ui/checkbox/checkbox"
import ViewBooking from "../../../components/booking/view/view"
import Title from "../../../components/structure/title/title"
import Table from "../../../components/structure/table/table"
import Tile from "../../../components/structure/tile/tile"
import Button from "../../../components/ui/button/button"
import Input from "../../../components/ui/inputs/input"
import Badge from "../../../components/ui/badge/badge"
import Booking from "./_components/booking/booking"
import MastersTickets from "./_components/masters-tickets/masters-tickets"

// Functional component to return the dashboard for The Masters 2025
export default function Masters2025() {
	const [bookings, setBookings] = useState([])
	const [bookingStats, setBookingStats] = useState({})
	const [loading, setLoading] = useState(true)
	const [viewBooking, setViewBooking] = useState("")
	const [activeTab, setActiveTab] = useState("BOOKINGS")
	const [searchTerm, setSearchTerm] = useState("")

	// State for golf days participants
	const [allGroupNames, setAllGroupNames] = useState([])
	const [loadingGroupNames, setLoadingGroupNames] = useState(true)

	// State for transfers
	const [transfers, setTransfers] = useState({})
	const [loadingTransfers, setLoadingTransfers] = useState(true)
	const [transferStatus, setTransferStatus] = useState({})
	const [updatingTransferId, setUpdatingTransferId] = useState(null)
	const [transferSaveTimeout, setTransferSaveTimeout] = useState(null)

	// State for club hire tracking
	const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"))
	const [bookingRounds, setBookingRounds] = useState({})
	const [loadingRounds, setLoadingRounds] = useState(true)

	// State for golf course settings
	const [golfCourseSettings, setGolfCourseSettings] = useState({
		friday_columbia: {
			slope_rating: 113,
			handicap_percentage: 95,
			saving: false,
		},
		friday_lexington: {
			slope_rating: 113,
			handicap_percentage: 95,
			saving: false,
		},
		saturday_windermere: {
			slope_rating: 113,
			handicap_percentage: 95,
			saving: false,
		},
	})

	// State for player scores
	const [playerScores, setPlayerScores] = useState({})
	const [savingScores, setSavingScores] = useState(false)

	// State for club hire status
	const [clubHireStatus, setClubHireStatus] = useState({})
	const [updatingPersonId, setUpdatingPersonId] = useState(null)
	const [saveTimeout, setSaveTimeout] = useState(null)

	// Get some context params
	const { user } = useContext(AuthContext)
	const { pushAlert } = useContext(AlertsContext)

	// Clean up timeouts when component unmounts
	useEffect(() => {
		return () => {
			if (saveTimeout) {
				clearTimeout(saveTimeout)
			}
			if (transferSaveTimeout) {
				clearTimeout(transferSaveTimeout)
			}
		}
	}, [saveTimeout, transferSaveTimeout])

	// On component load, fetch bookings with event_tag = "the_masters_2025"
	useEffect(() => {
		const fetchBookings = async () => {
			try {
				// Query bookings with event_tag = "the_masters_2025"
				const bookingsSnapshot = await db.collection("bookings").where("event_tag", "==", "the_masters_2025").where("removed", "==", false).get()

				// Map the bookings to an array
				const bookingsData = bookingsSnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}))

				// Set the bookings in state
				setBookings(bookingsData)

				// Fetch group names stats for each booking
				const statsPromises = bookingsData.map(async (booking) => {
					// Ensure we're parsing numbers correctly
					const golfers = parseInt(booking.golfers || 0)
					const nonGolfers = parseInt(booking.non_golfers || 0)
					const totalGroupSize = golfers + nonGolfers

					// Get group names for this booking
					const groupNamesSnapshot = await db.collection(`bookings/${booking.id}/group_names`).get()
					const groupNames = groupNamesSnapshot.docs.map((doc) => doc.data())
					// Count only entries with at least a first name or last name
					const groupNamesCount = groupNames.filter((person) => person.first_name || person.last_name).length

					return {
						bookingId: booking.id,
						golfers,
						nonGolfers,
						totalGroupSize,
						groupNamesCount,
						complete: groupNamesCount >= totalGroupSize && totalGroupSize > 0,
						incomplete: totalGroupSize > 0 && groupNamesCount < totalGroupSize,
						noGroupSize: totalGroupSize === 0,
					}
				})

				// Wait for all stats to be fetched
				const statsResults = await Promise.all(statsPromises)

				// Convert array to object with bookingId as key
				const statsObject = statsResults.reduce((acc, stat) => {
					acc[stat.bookingId] = stat
					return acc
				}, {})

				setBookingStats(statsObject)
			} catch (error) {
				console.error("Error fetching bookings:", error)
			} finally {
				setLoading(false)
			}
		}

		fetchBookings()
	}, [])

	// Fetch all group names across all bookings
	useEffect(() => {
		const fetchAllGroupNames = async () => {
			if (bookings.length === 0) return

			setLoadingGroupNames(true)

			try {
				// Create an array to hold all group names
				let allNames = []
				let statusData = {}

				// Fetch group names for each booking
				for (const booking of bookings) {
					const groupNamesSnapshot = await db.collection(`bookings/${booking.id}/group_names`).get()

					// Map the group names and add booking reference
					const names = groupNamesSnapshot.docs.map((doc) => {
						const personData = doc.data()

						// Extract club hire status if it exists
						if (personData.club_hire_status) {
							Object.keys(personData.club_hire_status).forEach((dateKey) => {
								const statusObj = personData.club_hire_status[dateKey]

								// Add to the status data
								statusData[`${doc.id}_${dateKey}`] = {
									sourced: statusObj.sourced || false,
									picked_up: statusObj.picked_up || false,
									returned: statusObj.returned || false,
								}
							})
						}

						return {
							id: doc.id,
							bookingId: booking.id,
							reference: booking.reference || "-",
							...personData,
						}
					})

					// Add to the array
					allNames = [...allNames, ...names]
				}

				// Filter out entries without names
				const validNames = allNames.filter((person) => person.first_name || person.last_name)

				setAllGroupNames(validNames)
				// Set the club hire status from the group_names documents
				setClubHireStatus(statusData)
			} catch (error) {
				console.error("Error fetching all group names:", error)
			} finally {
				setLoadingGroupNames(false)
			}
		}

		fetchAllGroupNames()
	}, [bookings])

	// Fetch golf course settings and player scores
	useEffect(() => {
		const fetchGolfSettings = async () => {
			try {
				// Fetch golf course settings
				const settingsDoc = await db.doc("events/masters_2025/settings/golf_courses").get()

				if (settingsDoc.exists) {
					setGolfCourseSettings((prevSettings) => ({
						...prevSettings,
						...settingsDoc.data(),
					}))
				} else {
					// Create the document if it doesn't exist
					await db.doc("events/masters_2025/settings/golf_courses").set({
						friday_columbia: {
							slope_rating: 113,
							handicap_percentage: 95,
						},
						friday_lexington: {
							slope_rating: 113,
							handicap_percentage: 95,
						},
						saturday_windermere: {
							slope_rating: 113,
							handicap_percentage: 95,
						},
					})
				}

				// Fetch player scores
				const scoresDoc = await db.doc("events/masters_2025/settings/player_scores").get()

				if (scoresDoc.exists) {
					setPlayerScores(scoresDoc.data())
				}

				// Fetch club hire status
				const clubHireStatusDoc = await db.doc("events/masters_2025/settings/club_hire_status").get()

				if (clubHireStatusDoc.exists) {
					setClubHireStatus(clubHireStatusDoc.data())
				}
			} catch (error) {
				console.error("Error fetching golf settings:", error)
			}
		}

		fetchGolfSettings()
	}, [])

	// Fetch all rounds for all bookings
	useEffect(() => {
		const fetchAllRounds = async () => {
			if (bookings.length === 0) return

			setLoadingRounds(true)

			try {
				// Create an object to hold all rounds by booking
				let allRounds = {}

				// Fetch rounds for each booking
				for (const booking of bookings) {
					if (booking.rounds_order && booking.rounds_order.length > 0) {
						const roundsData = {}

						// Fetch each round
						for (const roundId of booking.rounds_order) {
							const roundDoc = await db.doc(`bookings/${booking.id}/rounds/${roundId}`).get()

							if (roundDoc.exists) {
								roundsData[roundId] = roundDoc.data()
							}
						}

						// Add to the object
						allRounds[booking.id] = roundsData
					}
				}

				setBookingRounds(allRounds)
			} catch (error) {
				console.error("Error fetching rounds:", error)
			} finally {
				setLoadingRounds(false)
			}
		}

		fetchAllRounds()
	}, [bookings])

	// Fetch transfers for all bookings
	useEffect(() => {
		const fetchTransfers = async () => {
			if (bookings.length === 0) return

			setLoadingTransfers(true)

			try {
				// Create an object to hold all transfers by booking
				let allTransfers = {}
				let allTransferStatus = {}

				// Fetch transfers for each booking
				for (const booking of bookings) {
					const transfersSnapshot = await db.collection(`bookings/${booking.id}/transfers`).get()

					if (!transfersSnapshot.empty) {
						const transfersData = {}
						const statusData = {}

						transfersSnapshot.docs.forEach((doc) => {
							const transferData = doc.data()
							transfersData[doc.id] = transferData

							// Extract transfer status if it exists
							if (transferData.status) {
								Object.keys(transferData.status).forEach((dateKey) => {
									const statusObj = transferData.status[dateKey]
									statusData[`${doc.id}_${dateKey}`] = {
										confirmed: statusObj.confirmed || false,
										completed: statusObj.completed || false,
									}
								})
							}
						})

						allTransfers[booking.id] = transfersData
						allTransferStatus = { ...allTransferStatus, ...statusData }
					}
				}

				setTransfers(allTransfers)
				setTransferStatus(allTransferStatus)
			} catch (error) {
				console.error("Error fetching transfers:", error)
			} finally {
				setLoadingTransfers(false)
			}
		}

		fetchTransfers()
	}, [bookings])

	// Get club hire requirements for a specific date
	const getClubHireRequirements = (date) => {
		if (!date || loadingGroupNames || loadingRounds) {
			return []
		}

		const dateObj = moment(date)
		const requirements = []

		// Check each person with club hire enabled
		allGroupNames.forEach((person) => {
			if (person.club_hire) {
				let needsClubsOnDate = false
				let reason = ""

				// Check if they're playing in a golf day on this date
				if (dateObj.format("YYYY-MM-DD") === "2025-04-11") {
					// Friday
					if (person.golf_days && (person.golf_days.friday_columbia || person.golf_days.friday_lexington)) {
						needsClubsOnDate = true
						reason = person.golf_days.friday_columbia ? "Friday - Columbia Golf Day" : "Friday - Lexington Golf Day"
					}
				} else if (dateObj.format("YYYY-MM-DD") === "2025-04-12") {
					// Saturday
					if (person.golf_days && person.golf_days.saturday_windermere) {
						needsClubsOnDate = true
						reason = "Saturday - Windermere Golf Day"
					}
				}

				// If not already found, check if they have a round on this date
				if (!needsClubsOnDate && person.bookingId) {
					const rounds = bookingRounds[person.bookingId] || {}

					for (const roundId in rounds) {
						const round = rounds[roundId]
						if (round.tee_time && moment.unix(round.tee_time.seconds).format("YYYY-MM-DD") === dateObj.format("YYYY-MM-DD")) {
							needsClubsOnDate = true
							reason = `Tee time at ${moment.unix(round.tee_time.seconds).format("HH:mm")}`

							// If we have course information, add it
							if (round.courseID) {
								reason += ` at ${round.course_name || "course"}`
							}

							break
						}
					}
				}

				if (needsClubsOnDate) {
					requirements.push({
						...person,
						reason,
					})
				}
			}
		})

		return requirements
	}

	// Get club hire summary for all days
	const getClubHireSummary = () => {
		if (loadingGroupNames || loadingRounds) {
			return []
		}

		// Define the days of Masters week
		const days = [
			{ date: "2025-04-07", label: "Monday" },
			{ date: "2025-04-08", label: "Tuesday" },
			{ date: "2025-04-09", label: "Wednesday" },
			{ date: "2025-04-10", label: "Thursday" },
			{ date: "2025-04-11", label: "Friday" },
			{ date: "2025-04-12", label: "Saturday" },
			{ date: "2025-04-13", label: "Sunday" },
		]

		// Calculate requirements for each day
		return days.map((day) => {
			const requirements = getClubHireRequirements(day.date)
			const rightHanded = requirements.filter((person) => person.club_hire_hand === "right").length
			const leftHanded = requirements.filter((person) => person.club_hire_hand === "left").length

			return {
				...day,
				count: requirements.length,
				rightHanded,
				leftHanded,
			}
		})
	}

	// Get club hire statistics for a specific date
	const getClubHireStats = (date) => {
		const requirements = getClubHireRequirements(date)
		return {
			total: requirements.length,
			rightHanded: requirements.filter((person) => person.club_hire_hand === "right").length,
			leftHanded: requirements.filter((person) => person.club_hire_hand === "left").length,
		}
	}

	// Get club hire status statistics for a specific date
	const getClubHireStatusStats = (date) => {
		const requirements = getClubHireRequirements(date)

		if (requirements.length === 0) {
			return {
				sourced: 0,
				sourcedPercentage: 0,
				pickedUp: 0,
				pickedUpPercentage: 0,
				returned: 0,
				returnedPercentage: 0,
			}
		}

		const sourced = requirements.filter((person) => getClubHireStatus(person.id, date, "sourced")).length

		const pickedUp = requirements.filter((person) => getClubHireStatus(person.id, date, "picked_up")).length

		const returned = requirements.filter((person) => getClubHireStatus(person.id, date, "returned")).length

		return {
			sourced,
			sourcedPercentage: Math.round((sourced / requirements.length) * 100),
			pickedUp,
			pickedUpPercentage: Math.round((pickedUp / requirements.length) * 100),
			returned,
			returnedPercentage: Math.round((returned / requirements.length) * 100),
		}
	}

	// Calculate overall stats
	const calculateOverallStats = () => {
		if (Object.keys(bookingStats).length === 0) {
			return {
				totalBookings: 0,
				totalPeople: 0,
				completeBookings: 0,
				incompleteBookings: 0,
				noGroupSizeBookings: 0,
			}
		}

		// Calculate total people by summing up the totalGroupSize from each booking's stats
		const totalPeople = Object.values(bookingStats).reduce((sum, stat) => sum + stat.totalGroupSize, 0)

		const stats = {
			totalBookings: bookings.length,
			totalPeople,
			completeBookings: Object.values(bookingStats).filter((stat) => stat.complete).length,
			incompleteBookings: Object.values(bookingStats).filter((stat) => stat.incomplete).length,
			noGroupSizeBookings: Object.values(bookingStats).filter((stat) => stat.noGroupSize).length,
		}

		return stats
	}

	const [overallStats, setOverallStats] = useState({
		totalBookings: 0,
		totalPeople: 0,
		completeBookings: 0,
		incompleteBookings: 0,
		noGroupSizeBookings: 0,
	})

	// Recalculate stats when bookingStats changes
	useEffect(() => {
		setOverallStats(calculateOverallStats())
	}, [bookingStats, bookings])

	// Render group status icon and text
	const renderGroupStatus = (bookingId) => {
		if (!bookingStats[bookingId]) {
			return <div className="masters-group-status-loading">Loading...</div>
		}

		const stats = bookingStats[bookingId]

		if (stats.complete) {
			return (
				<div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
					<CheckIcon
						size={19}
						color="#22b229"
					/>

					<div>
						<Badge
							type="POSITIVE"
							label={`${stats.groupNamesCount}/${stats.totalGroupSize}`}
						/>
					</div>
				</div>
			)
		}

		return (
			<div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
				<XIcon
					size={19}
					color="#d3423d"
				/>

				<div>
					<Badge
						type="NEGATIVE"
						label={`${stats.groupNamesCount}/${stats.totalGroupSize}`}
					/>
				</div>
			</div>
		)
	}

	// Update golf course settings
	const updateGolfCourseSetting = (course, field, value) => {
		setGolfCourseSettings((prevSettings) => ({
			...prevSettings,
			[course]: {
				...prevSettings[course],
				[field]: value,
			},
		}))
	}

	// Save golf course settings
	const saveGolfCourseSettings = async (course) => {
		// Set saving state
		setGolfCourseSettings((prevSettings) => ({
			...prevSettings,
			[course]: {
				...prevSettings[course],
				saving: true,
			},
		}))

		try {
			// Update the document
			await db.doc("events/masters_2025/settings/golf_courses").set(
				{
					[course]: {
						slope_rating: golfCourseSettings[course].slope_rating,
						handicap_percentage: golfCourseSettings[course].handicap_percentage,
					},
				},
				{ merge: true }
			)
		} catch (error) {
			console.error(`Error saving ${course} settings:`, error)
		} finally {
			// Reset saving state
			setGolfCourseSettings((prevSettings) => ({
				...prevSettings,
				[course]: {
					...prevSettings[course],
					saving: false,
				},
			}))
		}
	}

	// Calculate playing handicap
	const calculatePlayingHandicap = (handicap, course) => {
		if (!handicap || isNaN(parseFloat(handicap))) return "-"

		const exactHandicap = parseFloat(handicap)
		const slopeRating = parseFloat(golfCourseSettings[course].slope_rating)
		const handicapPercentage = parseFloat(golfCourseSettings[course].handicap_percentage)

		// Formula: (Exact Handicap × (Slope Rating / 113) × Handicap Allowance)
		const playingHandicap = Math.round(exactHandicap * (slopeRating / 113) * (handicapPercentage / 100))

		return playingHandicap
	}

	// Update player score
	const updatePlayerScore = (personId, course, score) => {
		setPlayerScores((prevScores) => ({
			...prevScores,
			[`${personId}_${course}`]: score,
		}))
	}

	// Save all player scores
	const saveAllPlayerScores = async () => {
		setSavingScores(true)

		try {
			await db.doc("events/masters_2025/settings/player_scores").set(playerScores)
		} catch (error) {
			console.error("Error saving player scores:", error)
		} finally {
			setSavingScores(false)
		}
	}

	// Calculate net score
	const calculateNetScore = (grossScore, playingHandicap) => {
		if (!grossScore || grossScore === "" || playingHandicap === "-") return "-"

		const gross = parseInt(grossScore)
		const handicap = parseInt(playingHandicap)

		return gross - handicap
	}

	// Render golf day participants
	const renderGolfDayParticipants = (course) => {
		// Filter participants for this course
		const participants = allGroupNames.filter((person) => person.golf_days && person.golf_days[course])

		if (participants.length === 0) {
			return (
				<tr>
					<td
						colSpan="9"
						className="masters-no-data">
						No participants found for this golf day.
					</td>
				</tr>
			)
		}

		return participants.map((person) => {
			const playingHandicap = calculatePlayingHandicap(person.handicap, course)
			const grossScore = playerScores[`${person.id}_${course}`] || ""
			const netScore = calculateNetScore(grossScore, playingHandicap)

			// Get the date for this course
			const courseDate =
				course === "friday_columbia" || course === "friday_lexington"
					? "2025-04-11" // Friday
					: "2025-04-12" // Saturday for Windermere

			// Get club hire status
			const isSourced = person.club_hire && getClubHireStatus(person.id, courseDate, "sourced")
			const isPickedUp = person.club_hire && getClubHireStatus(person.id, courseDate, "picked_up")
			const isReturned = person.club_hire && getClubHireStatus(person.id, courseDate, "returned")

			return (
				<tr key={`${person.id}_${course}`}>
					<td>{person.first_name || ""}</td>
					<td>{person.last_name || ""}</td>
					<td>
						<NavLink to={`/booking/${person.bookingId}`}>{person.reference !== "-" ? person.reference : "Unknown"}</NavLink>
					</td>
					<td>{person.handicap || "-"}</td>
					<td>{playingHandicap}</td>
					<td>
						<Input
							value={grossScore}
							onChange={(value) => updatePlayerScore(person.id, course, value)}
							placeholder="Score"
							type="number"
						/>
					</td>
					<td>{netScore}</td>
					<td>{person.club_hire ? <div>{person.club_hire_hand === "left" ? "Left" : "Right"}-handed</div> : "No"}</td>
				</tr>
			)
		})
	}

	// Update club hire status for a person on a specific date
	const updateClubHireStatus = async (personId, date, field, value) => {
		const statusKey = `${personId}_${date}`
		const person = allGroupNames.find((p) => p.id === personId)

		if (!person || !person.bookingId) {
			pushAlert({
				type: "ERROR",
				title: "Update Failed",
				body: "Could not find person information",
			})
			return
		}

		try {
			// Update local state
			setClubHireStatus((prevStatus) => {
				const newStatus = {
					...prevStatus,
					[statusKey]: {
						...(prevStatus[statusKey] || {}),
						[field]: value,
					},
				}

				// Debounce the auto-save to prevent too many Firestore writes
				if (saveTimeout) {
					clearTimeout(saveTimeout)
				}

				// Set the person ID being updated
				setUpdatingPersonId(personId)

				const newTimeout = setTimeout(() => {
					saveClubHireStatusToFirestore(personId, date, field, value, person.bookingId)
				}, 1000) // Wait 1 second after the last change before saving

				setSaveTimeout(newTimeout)

				return newStatus
			})
		} catch (error) {
			console.error("Error updating club hire status:", error)
			pushAlert({
				type: "ERROR",
				title: "Update Failed",
				body: "Could not update club hire status",
			})
		}
	}

	// Save club hire status to Firestore without UI feedback
	const saveClubHireStatusToFirestore = async (personId, date, field, value, bookingId) => {
		try {
			// Get a reference to the person's document
			const personRef = db.doc(`bookings/${bookingId}/group_names/${personId}`)

			// Update the specific field using dot notation for nested updates
			await personRef.update({
				[`club_hire_status.${date}.${field}`]: value,
			})

			// Show a subtle success notification
			pushAlert({
				type: "SUCCESS",
				title: "Status Updated",
				body: "Club hire status saved successfully",
			})

			// Clear the updating person ID
			setUpdatingPersonId(null)
		} catch (error) {
			console.error("Error auto-saving club hire status:", error)
			pushAlert({
				type: "ERROR",
				title: "Save Failed",
				body: "Could not save club hire status changes",
			})
		}
	}

	// Get club hire status for a person on a specific date
	const getClubHireStatus = (personId, date, field) => {
		const statusKey = `${personId}_${date}`
		return clubHireStatus[statusKey]?.[field] || false
	}

	// Get transfer requirements for a specific date
	const getTransferRequirements = (date) => {
		if (!date || loadingTransfers) {
			return []
		}

		const requirements = []

		// Check each booking's group names for transfer requirements
		allGroupNames.forEach((person) => {
			if (person.transfer_days) {
				// Convert the date to a day name (lowercase)
				const dayName = moment(date).format("dddd").toLowerCase()

				// Check if this person needs a transfer on this day
				if (person.transfer_days[dayName]) {
					// Find the booking to get transfer reference info
					const booking = bookings.find((b) => b.id === person.bookingId)

					requirements.push({
						id: person.id,
						bookingId: person.bookingId,
						reference: booking?.reference || "-",
						first_name: person.first_name,
						last_name: person.last_name,
						phone: person.phone,
						email: person.email,
						date: date,
						pickup_location: person.pickup_location || "-",
						dropoff_location: person.dropoff_location || "-",
						event_transfer_booked: booking?.event_transfer_booked || false,
						event_transfer_reference: booking?.event_transfer_reference || "-",
					})
				}
			}
		})

		return requirements
	}

	// Get transfer summary for all days
	const getTransferSummary = () => {
		if (loadingTransfers) {
			return []
		}

		// Define the days of Masters week
		const days = [
			{ date: "2025-04-07", label: "Monday" },
			{ date: "2025-04-08", label: "Tuesday" },
			{ date: "2025-04-09", label: "Wednesday" },
			{ date: "2025-04-10", label: "Thursday" },
			{ date: "2025-04-11", label: "Friday" },
			{ date: "2025-04-12", label: "Saturday" },
			{ date: "2025-04-13", label: "Sunday" },
		]

		// Calculate requirements for each day
		return days.map((day) => {
			const requirements = getTransferRequirements(day.date)
			return {
				...day,
				count: requirements.length,
			}
		})
	}

	// Get transfer status statistics for a specific date
	const getTransferStatusStats = (date) => {
		const requirements = getTransferRequirements(date)

		if (requirements.length === 0) {
			return {
				confirmed: 0,
				confirmedPercentage: 0,
				completed: 0,
				completedPercentage: 0,
			}
		}

		const confirmed = requirements.filter((transfer) => getTransferStatus(transfer.id, date, "confirmed")).length
		const completed = requirements.filter((transfer) => getTransferStatus(transfer.id, date, "completed")).length

		return {
			confirmed,
			confirmedPercentage: Math.round((confirmed / requirements.length) * 100),
			completed,
			completedPercentage: Math.round((completed / requirements.length) * 100),
		}
	}

	// Get transfer status for a specific transfer on a specific date
	const getTransferStatus = (transferId, date, field) => {
		const statusKey = `${transferId}_${date}`
		return transferStatus[statusKey]?.[field] || false
	}

	// Update transfer status for a specific transfer on a specific date
	const updateTransferStatus = async (transferId, date, field, value) => {
		const transfer = Object.values(transfers)
			.flatMap((bookingTransfers) => Object.entries(bookingTransfers))
			.find(([id]) => id === transferId)?.[1]

		if (!transfer) {
			pushAlert({
				type: "ERROR",
				title: "Update Failed",
				body: "Could not find transfer information",
			})
			return
		}

		try {
			// Update local state
			setTransferStatus((prevStatus) => {
				const newStatus = {
					...prevStatus,
					[`${transferId}_${date}`]: {
						...(prevStatus[`${transferId}_${date}`] || {}),
						[field]: value,
					},
				}

				// Debounce the auto-save to prevent too many Firestore writes
				if (transferSaveTimeout) {
					clearTimeout(transferSaveTimeout)
				}

				// Set the transfer ID being updated
				setUpdatingTransferId(transferId)

				const newTimeout = setTimeout(() => {
					saveTransferStatusToFirestore(transferId, date, field, value, transfer.bookingId)
				}, 1000) // Wait 1 second after the last change before saving

				setTransferSaveTimeout(newTimeout)

				return newStatus
			})
		} catch (error) {
			console.error("Error updating transfer status:", error)
			pushAlert({
				type: "ERROR",
				title: "Update Failed",
				body: "Could not update transfer status",
			})
		}
	}

	// Save transfer status to Firestore
	const saveTransferStatusToFirestore = async (transferId, date, field, value, bookingId) => {
		try {
			// Get a reference to the transfer's document
			const transferRef = db.doc(`bookings/${bookingId}/transfers/${transferId}`)

			// Update the specific field using dot notation for nested updates
			await transferRef.update({
				[`status.${date}.${field}`]: value,
			})

			// Show a subtle success notification
			pushAlert({
				type: "SUCCESS",
				title: "Status Updated",
				body: "Transfer status saved successfully",
			})

			// Clear the updating transfer ID
			setUpdatingTransferId(null)
		} catch (error) {
			console.error("Error auto-saving transfer status:", error)
			pushAlert({
				type: "ERROR",
				title: "Save Failed",
				body: "Could not save transfer status changes",
			})
		}
	}

	return (
		<>
			<Tile>
				<Title>
					<h1>The Masters 2025</h1>
				</Title>

				<Navigation
					links={[
						{
							id: "BOOKINGS",
							label: "Bookings",
						},
						{
							id: "GUESTS",
							label: "Guests",
						},
						{
							id: "CLUB_HIRE",
							label: "Club Hire",
						},
						{
							id: "TRANSFERS",
							label: "Transfers",
						},
						{
							id: "MASTERS_TICKETS",
							label: "Masters Tickets",
						},
						{
							id: "FRIDAY_COLUMBIA",
							label: "Friday - Columbia",
						},
						{
							id: "FRIDAY_LEXINGTON",
							label: "Friday - Lexington",
						},
						{
							id: "SATURDAY_WINDERMERE",
							label: "Saturday - Windermere",
						},
					]}
					active={activeTab}
					onClick={(tabID) => setActiveTab(tabID)}
				/>

				{loading && activeTab === "BOOKINGS" ? (
					<p className="masters-loading">Loading bookings...</p>
				) : loadingGroupNames && activeTab !== "BOOKINGS" && activeTab !== "CLUB_HIRE" && activeTab !== "TRANSFERS" ? (
					<p className="masters-loading">Loading participants...</p>
				) : loadingRounds && activeTab === "CLUB_HIRE" ? (
					<p className="masters-loading">Loading club hire data...</p>
				) : loadingTransfers && activeTab === "TRANSFERS" ? (
					<p className="masters-loading">Loading transfers data...</p>
				) : (
					<>
						{activeTab === "BOOKINGS" && (
							<>
								<div className="masters-stats-summary">
									<div className="masters-stat-card">
										<div className="masters-stat-value">{overallStats.totalBookings}</div>
										<div className="masters-stat-label">Total Bookings</div>
									</div>
									<div
										className="masters-stat-card clickable"
										onClick={() => setActiveTab("GUESTS")}
										style={{ cursor: "pointer" }}>
										<div className="masters-stat-value">{overallStats.totalPeople}</div>
										<div className="masters-stat-label">Total Guests</div>
									</div>
									<div className="masters-stat-card">
										<div className="masters-stat-value">{overallStats.completeBookings}</div>
										<div className="masters-stat-label">Complete Bookings</div>
									</div>
									<div className="masters-stat-card">
										<div className="masters-stat-value">{overallStats.incompleteBookings}</div>
										<div className="masters-stat-label">Incomplete Bookings</div>
									</div>
								</div>

								<Table
									className="bookings-table"
									headings={["Agent", "Lead Client", "Group Status", ""]}>
									{bookings.length > 0 ? (
										bookings.map((booking) => (
											<React.Fragment key={booking.id}>
												<Booking
													id={booking.id}
													details={booking}
													groupStatus={renderGroupStatus(booking.id)}
												/>
											</React.Fragment>
										))
									) : (
										<tr>
											<td colSpan={9}>
												<small>No bookings found for The Masters 2025 event.</small>
											</td>
										</tr>
									)}
								</Table>
							</>
						)}

						{activeTab === "GUESTS" && (
							<>
								<div className="masters-guests-container">
									<div
										className="masters-guests-search"
										style={{ width: "400px", marginBottom: "20px" }}>
										<Input
											value={searchTerm}
											onChange={(value) => setSearchTerm(value)}
											placeholder="Search by name, email, or booking reference..."
											type="text"
										/>
									</div>
									<div className="masters-guests-participants">
										<Table
											className="bookings-table"
											headings={["Booking", "Full Name", "Email", "Phone", "Club Hire", "Club Hire Hand", "Transfer Days", "Golf Days", "Masters Days"]}>
											{allGroupNames.length > 0 ? (
												[...allGroupNames]
													.filter((person) => {
														if (!searchTerm) return true
														const searchLower = searchTerm.toLowerCase()
														const fullName = `${person.first_name || ""} ${person.last_name || ""}`.toLowerCase()
														const email = (person.email || "").toLowerCase()
														const reference = String(person.reference || "").toLowerCase()
														return fullName.includes(searchLower) || email.includes(searchLower) || reference.includes(searchLower)
													})
													.sort((a, b) => {
														// First sort by booking reference
														const refCompare = String(a.reference || "").localeCompare(String(b.reference || ""))
														if (refCompare !== 0) return refCompare
														// If booking refs are the same, sort by last name
														return (a.last_name || "").localeCompare(b.last_name || "")
													})
													.map((person) => {
														// Format transfer days
														const transferDays = person.transfer_days
															? Object.entries(person.transfer_days)
																	.filter(([_, needed]) => needed)
																	.map(([day]) => day.charAt(0).toUpperCase() + day.slice(1))
																	.join(", ") || "None required"
															: "None required"

														// Format golf days
														const golfDays = person.golf_days
															? Object.entries(person.golf_days)
																	.filter(([_, playing]) => playing)
																	.map(([day]) => {
																		switch (day) {
																			case "friday_columbia":
																				return "Friday - Columbia"
																			case "friday_lexington":
																				return "Friday - Lexington"
																			case "saturday_windermere":
																				return "Saturday - Windermere"
																			default:
																				return day
																		}
																	})
																	.join(", ") || "Not playing"
															: "Not playing"

														// Format masters days
														const mastersDays = person.masters_days
															? Object.entries(person.masters_days)
																	.filter(([_, attending]) => attending)
																	.map(([day]) => day.charAt(0).toUpperCase() + day.slice(1))
																	.join(", ") || "Not attending"
															: "Not attending"

														return (
															<tr key={person.id}>
																<td>
																	<NavLink to={`/booking/${person.bookingId}`}>{person.reference !== "-" ? person.reference : "Unknown"}</NavLink>
																</td>
																<td>{`${person.first_name || ""} ${person.last_name || ""}`.trim() || "-"}</td>
																<td>{person.email || "-"}</td>
																<td>{person.phone || "-"}</td>
																<td>
																	<Badge
																		type={person.club_hire ? "POSITIVE" : "DEFAULT"}
																		label={person.club_hire ? "Yes" : "No"}
																	/>
																</td>
																<td>{person.club_hire ? `${person.club_hire_hand.charAt(0).toUpperCase() + person.club_hire_hand.slice(1)}-handed` : "-"}</td>
																<td>{transferDays}</td>
																<td>{golfDays}</td>
																<td>{mastersDays}</td>
															</tr>
														)
													})
											) : (
												<tr>
													<td
														colSpan="9"
														className="masters-no-data">
														No guests found
													</td>
												</tr>
											)}
										</Table>
									</div>
								</div>
							</>
						)}

						{activeTab === "CLUB_HIRE" && (
							<>
								<div className="masters-club-hire-container">
									<div className="masters-club-hire-header">
										<h2>Club Hire Requirements</h2>
									</div>

									<div className="masters-club-hire-date-selector">
										<label>Select Date:</label>
										<input
											type="date"
											value={selectedDate}
											onChange={(e) => setSelectedDate(e.target.value)}
											min="2025-04-07" // Monday of Masters week
											max="2025-04-13" // Sunday of Masters week
										/>
									</div>

									<div className="masters-club-hire-week-summary">
										<div className="masters-club-hire-week-grid">
											{getClubHireSummary().map((day) => (
												<div
													key={day.date}
													className={`masters-club-hire-day-card ${selectedDate === day.date ? "active" : ""} ${day.count > 0 ? "has-requirements" : ""}`}
													onClick={() => setSelectedDate(day.date)}>
													<div className="day-label">{day.label}</div>
													<div className="day-date">{moment(day.date).format("MMM D")}</div>
													<div className="day-count">
														{day.count > 0 ? (
															<>
																{day.count}
																<span className="day-count-details">
																	({day.rightHanded}R / {day.leftHanded}L)
																</span>
															</>
														) : (
															day.count
														)}
													</div>
												</div>
											))}
										</div>
									</div>

									{getClubHireRequirements(selectedDate).length > 0 && (
										<div className="masters-club-hire-status-summary">
											<div className="masters-status-card">
												<div className="masters-status-header">Sourced</div>
												<div className="masters-status-value">
													{getClubHireStatusStats(selectedDate).sourced}/{getClubHireStats(selectedDate).total}
												</div>
												<div className="masters-status-percentage">{getClubHireStatusStats(selectedDate).sourcedPercentage}%</div>
												<div className="masters-status-progress">
													<div
														className={`masters-status-progress-bar ${getClubHireStatusStats(selectedDate).sourcedPercentage === 100 ? "progress-complete" : ""}`}
														style={{ width: `${getClubHireStatusStats(selectedDate).sourcedPercentage}%` }}></div>
												</div>
											</div>
											<div className="masters-status-card">
												<div className="masters-status-header">Picked Up</div>
												<div className="masters-status-value">
													{getClubHireStatusStats(selectedDate).pickedUp}/{getClubHireStats(selectedDate).total}
												</div>
												<div className="masters-status-percentage">{getClubHireStatusStats(selectedDate).pickedUpPercentage}%</div>
												<div className="masters-status-progress">
													<div
														className={`masters-status-progress-bar ${getClubHireStatusStats(selectedDate).pickedUpPercentage === 100 ? "progress-complete" : ""}`}
														style={{ width: `${getClubHireStatusStats(selectedDate).pickedUpPercentage}%` }}></div>
												</div>
											</div>
											<div className="masters-status-card">
												<div className="masters-status-header">Returned</div>
												<div className="masters-status-value">
													{getClubHireStatusStats(selectedDate).returned}/{getClubHireStats(selectedDate).total}
												</div>
												<div className="masters-status-percentage">{getClubHireStatusStats(selectedDate).returnedPercentage}%</div>
												<div className="masters-status-progress">
													<div
														className={`masters-status-progress-bar ${getClubHireStatusStats(selectedDate).returnedPercentage === 100 ? "progress-complete" : ""}`}
														style={{ width: `${getClubHireStatusStats(selectedDate).returnedPercentage}%` }}></div>
												</div>
											</div>
										</div>
									)}

									<div className="masters-club-hire-participants">
										<Table
											className="masters-club-hire-table"
											headings={["First Name", "Last Name", "Booking", "Handicap", "Reason", "Hand", "Sourced", "Picked Up", "Returned", "Contact"]}>
											{getClubHireRequirements(selectedDate).length > 0 ? (
												getClubHireRequirements(selectedDate).map((person) => (
													<tr key={person.id}>
														<td>{person.first_name || ""}</td>
														<td>{person.last_name || ""}</td>
														<td>
															<NavLink to={`/booking/${person.bookingId}`}>{person.reference !== "-" ? person.reference : "Unknown"}</NavLink>
														</td>
														<td>{person.handicap || "-"}</td>
														<td>{person.reason || "Golf Day"}</td>
														<td>{person.club_hire_hand === "left" ? "Left" : "Right"}-handed</td>
														<td className="masters-club-hire-status-cell">
															<Checkbox
																checked={getClubHireStatus(person.id, selectedDate, "sourced")}
																onClick={() => updateClubHireStatus(person.id, selectedDate, "sourced", !getClubHireStatus(person.id, selectedDate, "sourced"))}
																label=""
																disabled={updatingPersonId === person.id}
															/>
														</td>
														<td className="masters-club-hire-status-cell">
															<Checkbox
																checked={getClubHireStatus(person.id, selectedDate, "picked_up")}
																onClick={() => updateClubHireStatus(person.id, selectedDate, "picked_up", !getClubHireStatus(person.id, selectedDate, "picked_up"))}
																label=""
																disabled={updatingPersonId === person.id}
															/>
														</td>
														<td className="masters-club-hire-status-cell">
															<Checkbox
																checked={getClubHireStatus(person.id, selectedDate, "returned")}
																onClick={() => updateClubHireStatus(person.id, selectedDate, "returned", !getClubHireStatus(person.id, selectedDate, "returned"))}
																label=""
																disabled={updatingPersonId === person.id}
															/>
														</td>
														<td>{person.phone || person.email || "-"}</td>
														{/* <td className="masters-club-hire-status-indicator">{updatingPersonId === person.id ? <span className="saving-indicator">Saving...</span> : null}</td> */}
													</tr>
												))
											) : (
												<tr>
													<td
														colSpan="11"
														className="masters-no-data">
														No club hire requirements found for {moment(selectedDate).format("ddd, MMM D, YYYY")}
													</td>
												</tr>
											)}
										</Table>
									</div>
								</div>
							</>
						)}

						{activeTab === "TRANSFERS" && (
							<>
								<div className="masters-club-hire-container">
									<div className="masters-club-hire-header">
										<h2>Transfer Requirements</h2>
									</div>

									<div className="masters-club-hire-date-selector">
										<label>Select Date:</label>
										<input
											type="date"
											value={selectedDate}
											onChange={(e) => setSelectedDate(e.target.value)}
											min="2025-04-07" // Monday of Masters week
											max="2025-04-13" // Sunday of Masters week
										/>
									</div>

									<div className="masters-club-hire-week-summary">
										<div className="masters-club-hire-week-grid">
											{getTransferSummary().map((day) => (
												<div
													key={day.date}
													className={`masters-club-hire-day-card ${selectedDate === day.date ? "active" : ""} ${day.count > 0 ? "has-requirements" : ""}`}
													onClick={() => setSelectedDate(day.date)}>
													<div className="day-label">{day.label}</div>
													<div className="day-date">{moment(day.date).format("MMM D")}</div>
													<div className="day-count">{day.count}</div>
												</div>
											))}
										</div>
									</div>

									{getTransferRequirements(selectedDate).length > 0 && (
										<div className="masters-club-hire-status-summary">
											<div className="masters-status-card">
												<div className="masters-status-header">Confirmed</div>
												<div className="masters-status-value">
													{getTransferStatusStats(selectedDate).confirmed}/{getTransferRequirements(selectedDate).length}
												</div>
												<div className="masters-status-percentage">{getTransferStatusStats(selectedDate).confirmedPercentage}%</div>
												<div className="masters-status-progress">
													<div
														className={`masters-status-progress-bar ${getTransferStatusStats(selectedDate).confirmedPercentage === 100 ? "progress-complete" : ""}`}
														style={{ width: `${getTransferStatusStats(selectedDate).confirmedPercentage}%` }}></div>
												</div>
											</div>
											<div className="masters-status-card">
												<div className="masters-status-header">Completed</div>
												<div className="masters-status-value">
													{getTransferStatusStats(selectedDate).completed}/{getTransferRequirements(selectedDate).length}
												</div>
												<div className="masters-status-percentage">{getTransferStatusStats(selectedDate).completedPercentage}%</div>
												<div className="masters-status-progress">
													<div
														className={`masters-status-progress-bar ${getTransferStatusStats(selectedDate).completedPercentage === 100 ? "progress-complete" : ""}`}
														style={{ width: `${getTransferStatusStats(selectedDate).completedPercentage}%` }}></div>
												</div>
											</div>
										</div>
									)}

									<div className="masters-club-hire-participants">
										<Table
											className="masters-club-hire-table"
											headings={["First Name", "Last Name", "Booking", "Pickup Location", "Dropoff Location", "Transfer Booked", "Transfer Reference", "Contact"]}>
											{getTransferRequirements(selectedDate).length > 0 ? (
												getTransferRequirements(selectedDate).map((transfer) => (
													<tr key={transfer.id}>
														<td>{transfer.first_name || ""}</td>
														<td>{transfer.last_name || ""}</td>
														<td>
															<NavLink to={`/booking/${transfer.bookingId}`}>{transfer.reference !== "-" ? transfer.reference : "Unknown"}</NavLink>
														</td>
														<td>{transfer.pickup_location || "-"}</td>
														<td>{transfer.dropoff_location || "-"}</td>
														<td>
															<Badge
																type={transfer.event_transfer_booked ? "POSITIVE" : "NEGATIVE"}
																label={transfer.event_transfer_booked ? "Yes" : "No"}
															/>
														</td>
														<td>{transfer.event_transfer_booked ? transfer.event_transfer_reference : "-"}</td>
														<td>{transfer.phone || transfer.email || "-"}</td>
													</tr>
												))
											) : (
												<tr>
													<td
														colSpan="8"
														className="masters-no-data">
														No transfers found for {moment(selectedDate).format("ddd, MMM D, YYYY")}
													</td>
												</tr>
											)}
										</Table>
									</div>
								</div>
							</>
						)}

						{activeTab === "MASTERS_TICKETS" && <MastersTickets />}

						{activeTab === "FRIDAY_COLUMBIA" && (
							<>
								<div className="masters-golf-day-settings">
									<div className="masters-golf-day-settings-header">
										<h2>Friday - Columbia Golf Settings</h2>
									</div>
									<div className="masters-golf-day-settings-form">
										<div className="masters-golf-day-setting">
											<label>Slope Rating:</label>
											<Input
												value={golfCourseSettings.friday_columbia.slope_rating}
												onChange={(value) => updateGolfCourseSetting("friday_columbia", "slope_rating", value)}
												placeholder="Slope Rating"
												type="number"
											/>
										</div>
										<div className="masters-golf-day-setting">
											<label>Handicap Percentage:</label>
											<Input
												value={golfCourseSettings.friday_columbia.handicap_percentage}
												onChange={(value) => updateGolfCourseSetting("friday_columbia", "handicap_percentage", value)}
												placeholder="Handicap %"
												type="number"
											/>
										</div>
										<Button
											label="Save Settings"
											loading={golfCourseSettings.friday_columbia.saving}
											loadingText="Saving..."
											onClick={() => saveGolfCourseSettings("friday_columbia")}
										/>
									</div>
								</div>

								<div className="masters-golf-day-participants">
									<Table
										className="masters-golf-day-table"
										headings={["First Name", "Last Name", "Booking", "Handicap", "Playing Handicap", "Gross Score", "Net Score", "Club Hire"]}>
										{renderGolfDayParticipants("friday_columbia")}
									</Table>

									<div className="masters-golf-day-actions">
										<Button
											label="Save All Scores"
											loading={savingScores}
											loadingText="Saving..."
											onClick={saveAllPlayerScores}
										/>
									</div>
								</div>
							</>
						)}

						{activeTab === "FRIDAY_LEXINGTON" && (
							<>
								<div className="masters-golf-day-settings">
									<div className="masters-golf-day-settings-header">
										<h2>Friday - Lexington Golf Settings</h2>
									</div>
									<div className="masters-golf-day-settings-form">
										<div className="masters-golf-day-setting">
											<label>Slope Rating:</label>
											<Input
												value={golfCourseSettings.friday_lexington.slope_rating}
												onChange={(value) => updateGolfCourseSetting("friday_lexington", "slope_rating", value)}
												placeholder="Slope Rating"
												type="number"
											/>
										</div>
										<div className="masters-golf-day-setting">
											<label>Handicap Percentage:</label>
											<Input
												value={golfCourseSettings.friday_lexington.handicap_percentage}
												onChange={(value) => updateGolfCourseSetting("friday_lexington", "handicap_percentage", value)}
												placeholder="Handicap %"
												type="number"
											/>
										</div>
										<Button
											label="Save Settings"
											loading={golfCourseSettings.friday_lexington.saving}
											loadingText="Saving..."
											onClick={() => saveGolfCourseSettings("friday_lexington")}
										/>
									</div>
								</div>

								<div className="masters-golf-day-participants">
									<Table
										className="masters-golf-day-table"
										headings={["First Name", "Last Name", "Booking", "Handicap", "Playing Handicap", "Gross Score", "Net Score", "Club Hire"]}>
										{renderGolfDayParticipants("friday_lexington")}
									</Table>

									<div className="masters-golf-day-actions">
										<Button
											label="Save All Scores"
											loading={savingScores}
											loadingText="Saving..."
											onClick={saveAllPlayerScores}
										/>
									</div>
								</div>
							</>
						)}

						{activeTab === "SATURDAY_WINDERMERE" && (
							<>
								<div className="masters-golf-day-settings">
									<div className="masters-golf-day-settings-header">
										<h2>Saturday - Windermere Golf Settings</h2>
									</div>
									<div className="masters-golf-day-settings-form">
										<div className="masters-golf-day-setting">
											<label>Slope Rating:</label>
											<Input
												value={golfCourseSettings.saturday_windermere.slope_rating}
												onChange={(value) => updateGolfCourseSetting("saturday_windermere", "slope_rating", value)}
												placeholder="Slope Rating"
												type="number"
											/>
										</div>
										<div className="masters-golf-day-setting">
											<label>Handicap Percentage:</label>
											<Input
												value={golfCourseSettings.saturday_windermere.handicap_percentage}
												onChange={(value) => updateGolfCourseSetting("saturday_windermere", "handicap_percentage", value)}
												placeholder="Handicap %"
												type="number"
											/>
										</div>
										<Button
											label="Save Settings"
											loading={golfCourseSettings.saturday_windermere.saving}
											loadingText="Saving..."
											onClick={() => saveGolfCourseSettings("saturday_windermere")}
										/>
									</div>
								</div>

								<div className="masters-golf-day-participants">
									<Table
										className="masters-golf-day-table"
										headings={["First Name", "Last Name", "Booking", "Handicap", "Playing Handicap", "Gross Score", "Net Score", "Club Hire"]}>
										{renderGolfDayParticipants("saturday_windermere")}
									</Table>

									<div className="masters-golf-day-actions">
										<Button
											label="Save All Scores"
											loading={savingScores}
											loadingText="Saving..."
											onClick={saveAllPlayerScores}
										/>
									</div>
								</div>
							</>
						)}
					</>
				)}
			</Tile>

			{/* Do we have a bookingID in the local state to load into a window? */}
			{viewBooking && (
				<ViewBooking
					enquiryID={viewBooking}
					close={() => setViewBooking("")}
				/>
			)}
		</>
	)
}
