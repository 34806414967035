import React, { useEffect, useState } from "react"
import { db } from "../../utils/firebase"
import GHDLogo from "../../assets/images/golf-holidays-direct-logo.png"
import moment from "moment"
import "./leaderboard.scss"

/**
 * UI components and structure
 */
import Agent from "../../components/leaderboard/agent"
import Table from "../../components/structure/table/table"

/**
 * Functional component to return the leaderboard page
 */
function Leaderboard() {
	const [agents, setAgents] = useState([])
	const [rangeDaily, setRangeDaily] = useState({ start: 0, end: 0 })
	const [rangeWeekly, setRangeWeekly] = useState({ start: 0, end: 0 })
	const [rangeMonthly, setRangeMonthly] = useState({ start: 0, end: 0 })

	const [dailySales, setDailySales] = useState({})
	const [weeklySales, setWeeklySales] = useState({})
	const [monthlySales, setMonthlySales] = useState({})

	const [dailyMargins, setDailyMargins] = useState({})
	const [weeklyMargins, setWeeklyMargins] = useState({})
	const [monthlyMargins, setMonthlyMargins] = useState({})

	const [dailySalesTotal, setDailySalesTotal] = useState(0)
	const [weeklySalesTotal, setWeeklySalesTotal] = useState(0)
	const [monthlySalesTotal, setMonthlySalesTotal] = useState(0)

	const [dailyMarginTotal, setDailyMarginTotal] = useState(0)
	const [weeklyMarginTotal, setWeeklyMarginTotal] = useState(0)
	const [monthlyMarginTotal, setMonthlyMarginTotal] = useState(0)

	/**
	 * On component load, construct some date ranges for querying the database
	 */
	useEffect(() => {
		/**
		 * Get the start and end of the day in milliseconds
		 */
		const rangeDailyStart = moment().startOf("day").valueOf()
		const rangeDailyEnd = moment().endOf("day").valueOf()
		/**
		 * Set them into the state
		 */
		setRangeDaily({ start: rangeDailyStart, end: rangeDailyEnd })
		/**
		 * Get the start and end of the week in milliseconds
		 */
		const rangeWeekStart = moment().startOf("isoWeek").valueOf()
		const rangeWeekEnd = moment().endOf("isoWeek").valueOf()
		/**
		 * Set them into the state
		 */
		setRangeWeekly({ start: rangeWeekStart, end: rangeWeekEnd })
		/**
		 * Get the start and end of the month in milliseconds
		 */
		const rangeMonthStart = moment().startOf("month").valueOf()
		const rangeMonthEnd = moment().endOf("month").valueOf()
		/**
		 * Set them into the state
		 */
		setRangeMonthly({ start: rangeMonthStart, end: rangeMonthEnd })
	}, [])

	/**
	 * On component load, pull a list of the agents from the database
	 */
	useEffect(() => {
		const unsubscribe = db
			.collection("users")
			.orderBy("first_name")
			.where("show_for.targets", "==", true)
			.onSnapshot((agentDocs) => {
				/**
				 * Loop over the agents found
				 */
				agentDocs.docChanges().forEach((change) => {
					/**
					 * Agent document added
					 */
					if (change.type === "added") {
						setAgents((agents) => [
							...agents,
							{
								id: change.doc.id,
								...change.doc.data(),
							},
						])
					}
					/**
					 * Agent document updated
					 */
					if (change.type === "modified") {
						setAgents((agents) => {
							let updatedEnquiries = [...agents]
							for (let i in agents) {
								if (agents[i].id === change.doc.id) {
									updatedEnquiries[i] = {
										id: change.doc.id,
										...change.doc.data(),
									}
									break
								}
							}
							return updatedEnquiries
						})
					}
					/**
					 * Agent document removed
					 */
					if (change.type === "removed") {
						setAgents((agents) => agents.filter((agent) => agent.id !== change.doc.id))
					}
				})
			})
		/**
		 * Remove the listener on component unload
		 */
		return () => unsubscribe()
	}, [])

	/**
	 * Called from the agent component with their daily figures
	 */
	const updateDaily = (id, sales, margin) => {
		/**
		 * Push the figures into the state
		 */
		setDailySales((dailySales) => ({ ...dailySales, [id]: sales }))
		setDailyMargins((dailyMargins) => ({ ...dailyMargins, [id]: margin }))
	}

	/**
	 * Called from the agent component with their weekly figures
	 */
	const updateWeekly = (id, sales, margin) => {
		/**
		 * Push the figures into the state
		 */
		setWeeklySales((weeklySales) => ({ ...weeklySales, [id]: sales }))
		setWeeklyMargins((weeklyMargins) => ({ ...weeklyMargins, [id]: margin }))
	}

	/**
	 * Called from the agent component with their monthly figures
	 */
	const updateMonthly = (id, sales, margin) => {
		/**
		 * Push the figures into the state
		 */
		setMonthlySales((monthlySales) => ({ ...monthlySales, [id]: sales }))
		setMonthlyMargins((monthlyMargins) => ({ ...monthlyMargins, [id]: margin }))
	}

	/**
	 * Loop over an object passed in and sum the values together
	 */
	const sumAgentFigure = (objectToSum) => {
		/**
		 * Setup a running total
		 */
		let total = 0
		/**
		 * Loop through the daily sales array
		 */
		for (const [agent, figure] of Object.entries(objectToSum)) {
			/**
			 * Add the figure for this agent to the running total
			 */
			total = total + figure
		}
		/**
		 * Return the total in integer form
		 */
		return Number.parseInt(total)
	}

	/**
	 * When the daily sales are updated
	 */
	useEffect(() => {
		/**
		 * Get a total for the agent figure
		 */
		const totalSales = sumAgentFigure(dailySales)
		const totalMargin = sumAgentFigure(dailyMargins)
		/**
		 * Set the totals into the state
		 */
		setDailySalesTotal(totalSales)
		setDailyMarginTotal(totalMargin)
	}, [dailySales, dailyMargins])

	/**
	 * When the weekly sales are updated
	 */
	useEffect(() => {
		/**
		 * Get a total for the agent figure
		 */
		const totalSales = sumAgentFigure(weeklySales)
		const totalMargin = sumAgentFigure(weeklyMargins)
		/**
		 * Set the totals into the state
		 */
		setWeeklySalesTotal(totalSales)
		setWeeklyMarginTotal(totalMargin)
	}, [weeklySales, weeklyMargins])

	/**
	 * When the monthly sales are updated
	 */
	useEffect(() => {
		/**
		 * Get a total for the agent figure
		 */
		const totalSales = sumAgentFigure(monthlySales)
		const totalMargin = sumAgentFigure(monthlyMargins)
		/**
		 * Set the totals into the state
		 */
		setMonthlySalesTotal(totalSales)
		setMonthlyMarginTotal(totalMargin)
	}, [monthlySales, monthlyMargins])

	return (
		<div className="leaderboard-background">
			<div className="leaderboard-tile">
				<img
					src={GHDLogo}
					alt="Golf Holidays Direct"
				/>

				<Table
					className="leaderboard-table"
					headings={["Agent", "Today sales", "Today margin", "Week sales", "Week margin", "Month sales", "Month margin"]}>
					{/* Loop through the agents that are to show on the leaderboard */}
					{agents.map((agent) => (
						<Agent
							key={agent.id}
							agentID={agent.id}
							details={agent}
							rangeDaily={rangeDaily}
							rangeWeekly={rangeWeekly}
							rangeMonthly={rangeMonthly}
							updateDaily={(id, sales, margin) => updateDaily(id, sales, margin)}
							updateWeekly={(id, sales, margin) => updateWeekly(id, sales, margin)}
							updateMonthly={(id, sales, margin) => updateMonthly(id, sales, margin)}
						/>
					))}

					<tr className="leaderboard-totals">
						<td></td>
						<td>
							<p>£{dailySalesTotal.toLocaleString()}</p>
						</td>
						<td>
							<p>£{dailyMarginTotal.toLocaleString()}</p>
						</td>
						<td>
							<p>£{weeklySalesTotal.toLocaleString()}</p>
						</td>
						<td>
							<p>£{weeklyMarginTotal.toLocaleString()}</p>
						</td>
						<td>
							<p>£{monthlySalesTotal.toLocaleString()}</p>
						</td>
						<td>
							<p>£{monthlyMarginTotal.toLocaleString()}</p>
						</td>
					</tr>
				</Table>
			</div>
		</div>
	)
}

export default Leaderboard
