import React from "react"
import ReactDOM from "react-dom"
import App from "./entry"
import "./index.scss"

/**
 * Render the application component to the DOM
 */
ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
)
