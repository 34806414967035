import React, { useEffect, useState } from "react"
import { db } from "../../utils/firebase"
import { NavLink } from "react-router-dom"
import moment from "moment"
import "./enquiry.scss"

/**
 * UI components
 */
import Button from "../ui/button/button"
import Checkbox from "../ui/checkbox/checkbox"

/**
 * Functional component to return the enquiry table row
 */
export default function Enquiry(props) {
	const [client, setClient] = useState({})
	const [inMultiSelect, setInMultiSelect] = useState(false)
	const [isRemoved, setIsRemoved] = useState(false)

	const [followUp, setFollowUp] = useState(null)

	/**
	 * Deconstruct the details from the props
	 */
	const { id, details, tab, addToBulk, removeFromBulk } = props

	// Store the client details in variables
	let clientEmail = details.email || null
	let clientPhone = details.phone || null

	// If we have the requirement to mask particular client details
	if (props.maskDetails) {
		// Mask the entire email address
		clientEmail = clientEmail?.replace(/./g, "*") || null

		// Do the same for the phone number
		clientPhone = clientPhone?.replace(/./g, "*") || null
	}

	/**
	 * Generate a string to represent the created date from the timestamps
	 */
	const dateFrom = moment(details.date_from?._seconds || details.date_from?.seconds, "X").format("MMM D[,] YYYY")

	/**
	 * On component load
	 */
	useEffect(() => {
		/**
		 * Get the client record from the database
		 */
		db.doc(`clients/${details.client}`)
			.get()
			.then((clientDoc) => {
				/**
				 * Set the client data into the local state
				 */
				setClient(clientDoc.data())
			})
	}, [])

	/**
	 * On component load
	 */
	useEffect(() => {
		/**
		 * Setup a listener on the enquiry document
		 */
		const unsubscribe = db.doc(`enquiries/${id}`).onSnapshot((enquirySnap) => {
			/**
			 * Was the change a removal of the enquiry?
			 */
			if (!enquirySnap.exists) {
				/**
				 * Push the exists state into the local state
				 */
				setIsRemoved(!enquirySnap.exists)
			} else {
				/**
				 * Pull the details from the enquriy document
				 */
				const { follow_up } = enquirySnap.data()
				/**
				 * Parse the timestamp as a date time string
				 */
				const followUpDate = moment(follow_up?.seconds, "X").format("MMM D[,] YYYY")
				/**
				 * Then set this follow up date into the state
				 */
				setFollowUp(followUpDate)
			}
		})
		/**
		 * Remove the listener when the component unloads
		 */
		return () => unsubscribe()
	}, [])

	/**
	 * When the bulk assign checkbox is changed
	 */
	useEffect(() => {
		if (tab === "REQUIRE_ATTENTION") {
			if (inMultiSelect) {
				addToBulk(props.id)
			} else {
				removeFromBulk(props.id)
			}
		}
	}, [inMultiSelect])

	/**
	 * If the enquiry document is marked as removed
	 */
	if (isRemoved) {
		/**
		 * Just return an empty fragment
		 */
		return <></>
	} else {
		/**
		 * Return the enquiry row
		 */
		return (
			<tr data-system-id={id}>
				<td>{details.site}</td>

				<td>
					{!props.maskDetails && (
						<NavLink to={`client/${details.client}`}>
							{client.first_name && `${client.first_name} ${client.last_name}`}
							{!client.first_name && <small className="no-value">No name set</small>}
						</NavLink>
					)}

					{props.maskDetails && (
						<>
							{client.first_name && `${client.first_name} ${client.last_name}`}
							{!client.first_name && <small className="no-value">No name set</small>}
						</>
					)}
				</td>

				{tab !== "REQUIRE_ATTENTION" && (
					<>
						<td>
							{client.email && <>{clientEmail}</>}
							{!client.email && <small className="no-value">No email set</small>}
						</td>
						<td>
							{client.phone && <>{clientPhone}</>}
							{!client.phone && <small className="no-value">No number set</small>}
						</td>
						<td>
							{followUp === "Invalid date" && <small>No date set</small>}

							{followUp !== "Invalid date" && <>{followUp}</>}
						</td>
					</>
				)}
				<td>{details.location}</td>
				<td>{dateFrom}</td>
				<td>{details.group_size}</td>
				{tab !== "REQUIRE_ATTENTION" && <td>£{details.quoted ? details.quoted : 0}</td>}
				{/* {(tab === "REQUIRE_ATTENTION") &&
                <td>
                    <Checkbox
                        checked={inMultiSelect}
                        onClick={() => setInMultiSelect(!inMultiSelect)} />
                </td>
            } */}
				<td
					className="is-button"
					style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
					<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "fit-content" }}>
						<Button
							small={true}
							label="View enquiry"
							onClick={() => props.showEnquiryWindow(id)}
						/>

						{tab === "REQUIRE_ATTENTION" && (
							<Button
								small={true}
								label="Assign"
								onClick={() => props.showAssignWindow(id)}
							/>
						)}
					</div>
				</td>
			</tr>
		)
	}
}
