import React, { useState, useEffect, useContext } from "react"
import { AlertsContext } from "../../utils/providers/alerts"
import { CALENDAR_MONTHS } from "../../utils/exports/months"
import { db } from "../../utils/firebase"
import firebase from "firebase"
import moment from "moment"
import "./conversions.scss"

// UI imports
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Select from "../../components/ui/select/select"
import Table from "../../components/structure/table/table"
import Button from "../../components/ui/button/button"
import Checkbox from "../../components/ui/checkbox/checkbox"

// Chart.js imports
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title as ChartTitle, Tooltip, Legend } from "chart.js"
import { Line } from "react-chartjs-2"

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ChartTitle, Tooltip, Legend)

// Build up a list of years for the select field
let yearsObj = {}
let nextYear = Number(moment().startOf("year").add(2, "years").format("YYYY"))
for (let i = nextYear; i >= 2018; i--) {
	yearsObj = {
		[i]: i,
		...yearsObj,
	}
}

// Store the channel names
const channels = [
	{
		site: "GHD",
		name: "Golf Holidays Direct",
	},
	{ site: "MGH", name: "My Golf Holidays" },
	{ site: "MGB", name: "My Golf Breaks" },
	{ site: "GBS", name: "Golf Breaks Spain" },
	{ site: "GBP", name: "Golf Breaks Portugal" },
	{ site: "SYSTEM", name: "System" },
]

// Returns the HTML markup for the conversions page
export default function Conversions() {
	const [loading, setLoading] = useState(false)
	const [newBusinessLoading, setNewBusinessLoading] = useState(false)
	const [users, setUsers] = useState([])
	const [showCharts, setShowCharts] = useState(false)

	const [month, setMonth] = useState("")
	const [year, setYear] = useState("")

	const [userRows, setUserRows] = useState([])
	const [channelRows, setChannelRows] = useState([])
	const [historicalData, setHistoricalData] = useState({})

	const { pushAlert } = useContext(AlertsContext)

	// On component load
	useEffect(() => {
		const fetchSalesAgents = async () => {
			const salesAgents = db.collection("users").orderBy("first_name").where("show_for.targets", "==", true)
			const snapshot = await salesAgents.get()
			const agents = snapshot.docs.map((doc) => {
				return {
					id: doc.id,
					...doc.data(),
				}
			})
			setUsers(agents)
		}
		fetchSalesAgents()
	}, [])

	// Add this new function to fetch historical data
	const fetchHistoricalData = async (agentID, startDate, endDate) => {
		const timePoints = []
		let currentDate = moment(startDate)
		const endMoment = moment(endDate)

		// Generate time points based on if we're looking at months or year
		while (currentDate.isSameOrBefore(endMoment)) {
			timePoints.push({
				start: currentDate
					.clone()
					.startOf(month ? "day" : "month")
					.valueOf(),
				end: currentDate
					.clone()
					.endOf(month ? "day" : "month")
					.valueOf(),
				label: month ? currentDate.format("D MMM") : currentDate.format("MMM"),
			})
			currentDate.add(1, month ? "day" : "month")
		}

		const results = await Promise.all(
			timePoints.map(async ({ start, end }) => {
				const startTimestamp = firebase.firestore.Timestamp.fromMillis(start)
				const endTimestamp = firebase.firestore.Timestamp.fromMillis(end)

				const [activeEnquiries, removedEnquiries, bookedEnquiries] = await Promise.all([
					db.collection("enquiries").where("agent", "==", agentID).where("created", ">=", startTimestamp).where("created", "<=", endTimestamp).get(),
					db.collection("enquiries_removed").where("agent", "==", agentID).where("enquired", ">=", startTimestamp).where("enquired", "<=", endTimestamp).get(),
					db.collection("bookings").where("agent", "==", agentID).where("enquiry.created", ">=", startTimestamp).where("enquiry.created", "<=", endTimestamp).get(),
				])

				// Calculate conversion rate using same logic as before
				const activeCount = new Set(activeEnquiries.docs.map((doc) => doc.data().client)).size
				const removedCount = new Set(removedEnquiries.docs.map((doc) => doc.data().enquiry?.client)).size
				const totalEnquiries = activeCount + removedCount
				const conversionRate = totalEnquiries > 0 ? (bookedEnquiries.size / totalEnquiries) * 100 : 0

				return conversionRate
			})
		)

		return {
			labels: timePoints.map((tp) => tp.label),
			data: results,
		}
	}

	// Fetch the conversion data from the database
	const fetchConversionsData = async (newBusiness = false) => {
		// Reset the state
		if (newBusiness) {
			setNewBusinessLoading(true)

			// Also show an alert to say about the load time
			pushAlert({
				title: "Slow Loading",
				body: "This may take a minute to load, please be patient.",
			})
		} else {
			setLoading(true)
		}

		// Get the milliseconds timestamps from the chosen filters
		let fetchStart = 0
		let fetchEnd = 0
		if (month && year) {
			fetchStart = moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf()
			fetchEnd = moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf()
		} else if (!month && year) {
			fetchStart = moment(`${year}`, "YYYY").startOf("year").valueOf()
			fetchEnd = moment(`${year}`, "YYYY").endOf("year").valueOf()
		}
		const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart)
		const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd)

		try {
			// Fetch data for all users concurrently
			const userResults = await Promise.all(
				users.map(async (user) => {
					const { id: agentID } = user

					// Fetch active, removed, and booked enquiries concurrently
					const [activeEnquiries, removedEnquiries, bookedEnquiries] = await Promise.all([
						db
							.collection("enquiries") //
							.where("agent", "==", agentID)
							.where("created", ">=", startTimestamp)
							.where("created", "<=", endTimestamp)
							.get(),

						db
							.collection("enquiries_removed") //
							.where("agent", "==", agentID)
							.where("enquired", ">=", startTimestamp)
							.where("enquired", "<=", endTimestamp)
							.get(),

						db
							.collection("bookings") //
							.where("agent", "==", agentID)
							.where("enquiry.created", ">=", startTimestamp)
							.where("enquiry.created", "<=", endTimestamp)
							.get(),
					])

					// Remove all duplicate enquiries using the client ID
					const activeEnquiriesMap = new Map()
					activeEnquiries.forEach((doc) => {
						const enquiry = doc.data()
						activeEnquiriesMap.set(enquiry.client, enquiry)
					})
					const removedEnquiriesMap = new Map()
					removedEnquiries.forEach((doc) => {
						const enquiry = doc.data()
						removedEnquiriesMap.set(enquiry.enquiry?.client, enquiry)
					})

					// Calculate counts
					const activeCount = activeEnquiriesMap.size
					const removedCount = removedEnquiriesMap.size
					const bookedCount = bookedEnquiries.size

					// Calculate total enquiries and conversion rate
					const totalEnquiries = activeCount + removedCount
					const conversionRate = totalEnquiries > 0 ? ((bookedCount / totalEnquiries) * 100).toFixed(2) : 0

					return {
						agentID,
						agent: user.first_name ? `${user.first_name} ${user.last_name}` : agentID,
						original_enquiries: activeEnquiries.size + removedEnquiries.size,
						enquiries: totalEnquiries,
						bookings: bookedCount,
						conversionRate,
					}
				})
			)
			const channelResults = await Promise.all(
				channels.map(async (channel) => {
					const { site } = channel

					// Fetch active, removed, and booked enquiries concurrently
					const [activeEnquiries, removedEnquiries, bookedEnquiries] = await Promise.all([
						db
							.collection("enquiries") //
							.where("site", "==", site)
							.where("created", ">=", startTimestamp)
							.where("created", "<=", endTimestamp)
							.get(),

						db
							.collection("enquiries_removed") //
							.where("enquiry.site", "==", site)
							.where("enquired", ">=", startTimestamp)
							.where("enquired", "<=", endTimestamp)
							.get(),

						db
							.collection("bookings") //
							.where("enquiry.site", "==", site)
							.where("enquiry.created", ">=", startTimestamp)
							.where("enquiry.created", "<=", endTimestamp)
							.get(),
					])

					// Remove all duplicate enquiries using the client ID
					const activeEnquiriesMap = new Map()
					activeEnquiries.forEach((doc) => {
						const enquiry = doc.data()
						activeEnquiriesMap.set(enquiry.client, enquiry)
					})
					const removedEnquiriesMap = new Map()
					removedEnquiries.forEach((doc) => {
						const enquiry = doc.data()
						removedEnquiriesMap.set(enquiry.enquiry?.client, enquiry)
					})

					let newBusinessPercentage = 0
					if (newBusiness) {
						// Get a list of all the client IDs from the current active and removed enquiries
						const clientIDs = Array.from(activeEnquiriesMap.keys()).concat(Array.from(removedEnquiriesMap.keys()))

						// Setup a function to split the clientIDs array into chunks of 10 (max size allowed with the where clause for the firestore query)
						const chunkArray = (array, size) => {
							const result = []
							for (let i = 0; i < array.length; i += size) {
								result.push(array.slice(i, i + size))
							}
							return result
						}

						// Split the clientIDs array into chunks of 10
						const chunks = chunkArray(clientIDs, 10)

						// Setup a variable to store all the unique client IDs
						const uniqueClientIDs = new Set()

						// Loop through the chunks
						for (const chunk of chunks) {
							// Check the enquiries collection
							const querySnapshot = await db.collection("enquiries").where("client", "in", chunk).where("created", "<", startTimestamp).get()
							querySnapshot.forEach((doc) => {
								uniqueClientIDs.add(doc.data().client)
							})

							// Then also check the enquiries_removed collection
							const removedQuerySnapshot = await db.collection("enquiries_removed").where("enquiry.client", "in", chunk).where("enquiry.created", "<", startTimestamp).get()
							removedQuerySnapshot.forEach((doc) => {
								uniqueClientIDs.add(doc.data().enquiry.client)
							})
						}

						// Work out the number of new business enquiries
						const newBusinessEnquiries = activeEnquiries.size + removedEnquiries.size - uniqueClientIDs.size

						// And then as a percentage of the total enquiries
						newBusinessPercentage = ((newBusinessEnquiries / (activeEnquiries.size + removedEnquiries.size)) * 100).toFixed(2)
					}

					// Calculate counts
					const activeCount = activeEnquiriesMap.size
					const removedCount = removedEnquiriesMap.size
					const bookedCount = bookedEnquiries.size

					// Calculate total enquiries and conversion rate
					const totalEnquiries = activeCount + removedCount
					const conversionRate = totalEnquiries > 0 ? ((bookedCount / totalEnquiries) * 100).toFixed(2) : 0

					return {
						site: channel.site,
						name: channel.name,
						original_enquiries: activeEnquiries.size + removedEnquiries.size,
						enquiries: totalEnquiries,
						bookings: bookedCount,
						conversionRate,
						new_business: isNaN(newBusinessPercentage) ? 0 : newBusinessPercentage,
					}
				})
			)

			// Update state with results
			setUserRows(userResults)
			setChannelRows(channelResults)

			// After fetching current data, get historical data for each user
			const fetchStart = month && year ? moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf() : moment(`${year}`, "YYYY").startOf("year").valueOf()
			const fetchEnd = month && year ? moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf() : moment(`${year}`, "YYYY").endOf("year").valueOf()

			const historicalResults = {}
			for (const user of users) {
				historicalResults[user.id] = await fetchHistoricalData(user.id, fetchStart, fetchEnd)
			}
			setHistoricalData(historicalResults)
		} catch (err) {
			console.error("Error fetching conversion data:", err)
		} finally {
			setLoading(false)
			setNewBusinessLoading(false)
		}
	}

	// Add the chart component
	const renderChart = (agentId) => {
		const data = historicalData[agentId]
		if (!data) return null

		// Determine if we're in monthly or yearly view based on data points
		const isMonthView = data.labels.length > 12
		const windowSize = isMonthView ? 7 : 3

		// Calculate rolling average with edge handling
		const rollingAverageData = data.data.map((value, index, array) => {
			let sum = 0
			let count = 0

			// Calculate window boundaries
			const windowStart = Math.max(0, index - Math.floor(windowSize / 2))
			const windowEnd = Math.min(array.length - 1, index + Math.floor(windowSize / 2))

			// Sum up available values within window
			for (let i = windowStart; i <= windowEnd; i++) {
				if (array[i] !== null && array[i] !== undefined) {
					sum += array[i]
					count++
				}
			}

			return count > 0 ? sum / count : null
		})

		const chartData = {
			labels: data.labels,
			datasets: [
				{
					label: "Conversion Rate %",
					data: data.data,
					borderColor: "#e41f13",
					backgroundColor: "#e41f13",
					tension: 0.1,
					pointRadius: 4,
					pointHoverRadius: 6,
					spanGaps: true,
					segment: {
						borderColor: (ctx) => (ctx.p0.skip || ctx.p1.skip ? "rgba(228, 31, 19, 0.2)" : undefined),
					},
				},
				{
					label: isMonthView ? "7-Day Rolling Average" : "3-Month Rolling Average",
					data: rollingAverageData,
					borderColor: "grey",
					backgroundColor: "grey",
					borderWidth: 2,
					tension: 0.4,
					pointRadius: 2,
					spanGaps: true,
					borderDash: [5, 5],
				},
			],
		}

		const options = {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: true,
					position: "top",
					align: "end",
				},
				tooltip: {
					callbacks: {
						label: (context) => {
							const value = context.parsed.y
							if (value === null || value === undefined) return null
							return `${value.toFixed(2)}%`
						},
					},
				},
			},
			scales: {
				y: {
					beginAtZero: true,
					title: {
						display: true,
						text: "Conversion Rate %",
					},
				},
			},
		}

		return (
			<div style={{ height: "300px", marginTop: "10px", marginBottom: "20px" }}>
				<Line
					data={chartData}
					options={options}
				/>
			</div>
		)
	}

	return (
		<Tile fullPage={true}>
			<Title className="flex has-select-field">
				<h1>Conversions</h1>
			</Title>

			<div className="page-filters extra-stats">
				<Select
					label="Month:"
					placeholder="Month:"
					value={month}
					activeOnHover={true}
					onSelect={(option) => setMonth(option.option)}
					options={CALENDAR_MONTHS}
				/>

				<Select
					label="Year:"
					placeholder="Year:"
					value={year}
					activeOnHover={true}
					onSelect={(option) => setYear(option.option)}
					options={yearsObj}
				/>

				<div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
					<Button
						filters={true}
						disabled={(!month || !year) && !year}
						label="Fetch"
						loadingText="Fetching..."
						loading={loading}
						onClick={() => fetchConversionsData()}
					/>
					<Button
						filters={true}
						disabled={(!month || !year) && !year}
						label="Fetch w/ New Business %"
						loadingText="Fetching..."
						loading={newBusinessLoading}
						onClick={() => fetchConversionsData(true)}
					/>
					<Checkbox
						label="Show charts"
						checked={showCharts}
						onClick={() => setShowCharts(!showCharts)}
					/>
				</div>
			</div>

			<Table
				className="conversions-table"
				headings={["Name", "Enquiries", "Enquiries (De-Duped)", "Bookings", "Conversion Rate", "New Business"]}
				noResults={!channelRows.length}
				noResultsMessage="Choose some parameters to fetch conversion data">
				{channelRows.map((channelRow) => (
					<tr key={channelRow.site}>
						<td>{channelRow.name}</td>
						<td>{channelRow.original_enquiries}</td>
						<td>{channelRow.enquiries}</td>
						<td>{channelRow.bookings}</td>
						<td>{channelRow.conversionRate}%</td>
						<td>{channelRow.new_business}%</td>
					</tr>
				))}
			</Table>

			<Table
				className="conversions-table has-extra-margin"
				headings={["Agent", "Enquiries", "Enquiries (De-Duped)", "Bookings", "Conversion Rate"]}
				noResults={!userRows.length}
				noResultsMessage="Choose some parameters to fetch conversion data">
				{userRows.map((userRow) => (
					<React.Fragment key={userRow.agentID}>
						<tr>
							<td>{userRow.agent}</td>
							<td>{userRow.original_enquiries}</td>
							<td>{userRow.enquiries}</td>
							<td>{userRow.bookings}</td>
							<td>{userRow.conversionRate || 0}%</td>
						</tr>
						{showCharts && (
							<tr>
								<td colSpan="5">{renderChart(userRow.agentID)}</td>
							</tr>
						)}
					</React.Fragment>
				))}
			</Table>
		</Tile>
	)
}
