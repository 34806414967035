import React, { useEffect, useState } from "react"
import { db } from "../../../utils/firebase"

/**
 * UI components
 */
import Table from "../../../components/structure/table/table"
import Enquiry from "../../../components/enquiry/enquiry"

/**
 * Loading icon for when the button is processing
 */
import { LoadingIcon } from "../../../utils/svgs"

/**
 * Functional component returning all the leads that are found with the status of "REQUIRE_ATTENTION"
 */
export default function RequireAttention(props) {
	const [loading, setLoading] = useState(false)
	const [enquiries, setEnquiries] = useState([])
	const [bulkAssign, setBulkAssign] = useState([])

	/**
	 * When the local bulk assign array is updated
	 */
	useEffect(() => {
		/**
		 * Push the updated array up the props
		 */
		props.setBulk(bulkAssign)
	}, [bulkAssign])

	/**
	 * On component load
	 */
	useEffect(() => {
		/**
		 * Show a loading spinner over the top
		 */
		setLoading(true)
		/**
		 * Setup a listener on the databse for the enquiries
		 */
		const unsubscribe = db
			.collection("enquiries")
			.orderBy("created")
			.where("status", "==", "REQUIRE_ATTENTION")
			.onSnapshot((enquiriesSnap) => {
				/**
				 * Push the snapshot size up the props to the navigation on the enquiries page
				 */
				props.updateRequiredCount(enquiriesSnap.size)
				/**
				 * Loop over the enquiries found
				 */
				enquiriesSnap.docChanges().forEach((change) => {
					/**
					 * Enquiry document added
					 */
					if (change.type === "added") {
						setEnquiries((enquiries) => [
							...enquiries,
							{
								id: change.doc.id,
								...change.doc.data(),
							},
						])
					}
					/**
					 * Enquiry document updated
					 */
					if (change.type === "modified") {
						setEnquiries((enquiries) => {
							let updatedEnquiries = [...enquiries]
							for (let i in enquiries) {
								if (enquiries[i].id === change.doc.id) {
									updatedEnquiries[i] = {
										id: change.doc.id,
										...change.doc.data(),
									}
									break
								}
							}
							return updatedEnquiries
						})
					}
					/**
					 * Enquiry document removed
					 */
					if (change.type === "removed") {
						setEnquiries((enquiries) => enquiries.filter((enquiry) => enquiry.id !== change.doc.id))
					}
				})
				/**
				 * And then set the loading state back to false to show the enquiries
				 */
				setLoading(false)
			})
		/**
		 * On component unload
		 */
		return () => unsubscribe()
	}, [])

	/**
	 * Take in an enquiry ID and save it into the local state for bulk-assigning
	 */
	const addToBulk = (enquiryID) => {
		/**
		 * Push the enquiry ID into the local state
		 */
		setBulkAssign([...bulkAssign, enquiryID])
	}

	/**
	 * Take in an enquiry ID and remove it from the local state
	 */
	const removeFromBulk = (enquiryID) => {
		/**
		 * Setup a new array with the enquiry ID filtered
		 */
		const newBulkArray = bulkAssign.filter((enquiry) => enquiry !== enquiryID)
		/**
		 * Then set this new array into the state
		 */
		setBulkAssign(newBulkArray)
	}

	return (
		<Table
			className="enquiries-table"
			headings={["Site", "Name", "Location/Event", "Date from", "Group size"]}>
			{loading && (
				<div className="table-loading-splash">
					<div className="loading-wrapper">
						<p>Loading...</p>
						<div className="svg-loading-wrap">
							<LoadingIcon />
						</div>
					</div>
				</div>
			)}

			{/* Loop through any enquiries to print them into the table */}
			{enquiries.map((enquiry) => (
				<Enquiry
					key={enquiry.id}
					id={enquiry.id}
					details={enquiry}
					tab="REQUIRE_ATTENTION"
					maskDetails={props.maskDetails}
					addToBulk={(enquiryID) => addToBulk(enquiryID)}
					removeFromBulk={(enquiryID) => removeFromBulk(enquiryID)}
					showAssignWindow={(enquiryID) => props.setAssignEnquiry(enquiryID)}
					showEnquiryWindow={(enquiryID) => props.showEnquiryWindow(enquiryID)}
				/>
			))}

			{/* If there are no enquiries to show */}
			{enquiries.length === 0 && (
				<tr>
					<td colSpan={8}>
						<small>There are no enquiries that require attention at the moment</small>
					</td>
				</tr>
			)}
		</Table>
	)
}
