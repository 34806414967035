import { useEffect, useState } from "react"
import { db } from "../../../utils/firebase"

// ChartJS imports
import { Line } from "react-chartjs-2"
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip } from "chart.js"
import annotationPlugin from "chartjs-plugin-annotation"

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, annotationPlugin)

export default function EnquiriesLineChart({ tmStart, tmEnd, lyStart, lyEnd }) {
	const [tmEnquiries, setTmEnquiries] = useState([])
	const [lyEnquiries, setLyEnquiries] = useState([])

	// On load
	useEffect(() => {
		fetchThisMonth()
		fetchLastYear()
	}, [])

	// Fetch this months stats
	const fetchThisMonth = async () => {
		const enquiries = await db
			.collection("enquiries")
			.where("created", ">", tmStart)
			.where("created", "<", tmEnd)
			.get()
			.then((enquiryDocs) => {
				let enquiries = []
				enquiryDocs.forEach((enquiryDoc) => {
					enquiries.push({ id: enquiryDoc.id, ...enquiryDoc.data() })
				})
				return enquiries
			})
		const deletedEnquiries = await db
			.collection("enquiries_removed")
			.where("enquiry.created", ">", tmStart)
			.where("enquiry.created", "<", tmEnd)
			.get()
			.then((enquiryDocs) => {
				let enquiries = []
				enquiryDocs.forEach((enquiryDoc) => {
					enquiries.push({ id: enquiryDoc.id, ...enquiryDoc.data().enquiry })
				})
				return enquiries
			})
		setTmEnquiries([...enquiries, ...deletedEnquiries])
	}

	// Fetch last years stats
	const fetchLastYear = async () => {
		const enquiries = await db
			.collection("enquiries")
			.where("created", ">", lyStart)
			.where("created", "<", lyEnd)
			.orderBy("created")
			.get()
			.then((enquiryDocs) => {
				let enquiries = []
				enquiryDocs.forEach((enquiryDoc) => {
					enquiries.push({ id: enquiryDoc.id, ...enquiryDoc.data() })
				})
				return enquiries
			})
		const deletedEnquiries = await db
			.collection("enquiries_removed")
			.where("enquiry.created", ">", lyStart)
			.where("enquiry.created", "<", lyEnd)
			.orderBy("enquiry.created")
			.get()
			.then((enquiryDocs) => {
				let enquiries = []
				enquiryDocs.forEach((enquiryDoc) => {
					enquiries.push({ id: enquiryDoc.id, ...enquiryDoc.data().enquiry })
				})
				return enquiries
			})
		setLyEnquiries([...enquiries, ...deletedEnquiries])
	}

	// Process data to get the number of enquiries for each day
	const processEnquiries = (data) => {
		const counts = {}
		const dailyCounts = {} // Add this to track daily totals

		data.forEach((enquiry) => {
			const date = new Date(enquiry.created.seconds * 1000)
			const normalizedDate = date.toLocaleDateString("en-GB", {
				day: "2-digit",
				month: "2-digit",
			})
			counts[normalizedDate] = (counts[normalizedDate] || 0) + 1
			dailyCounts[normalizedDate] = (dailyCounts[normalizedDate] || 0) + 1
		})

		// Sort dates and calculate cumulative total
		const sortedDates = Object.keys(counts).sort((a, b) => {
			const [dayA, monthA] = a.split("/").map(Number)
			const [dayB, monthB] = b.split("/").map(Number)
			return monthA === monthB ? dayA - dayB : monthA - monthB
		})

		const cumulativeCounts = {}
		let runningTotal = 0
		sortedDates.forEach((date) => {
			runningTotal += counts[date]
			cumulativeCounts[date] = runningTotal
		})

		return { cumulativeCounts, dailyCounts }
	}

	const { cumulativeCounts: currentYearCounts, dailyCounts: currentYearDailyCounts } = processEnquiries(tmEnquiries)
	const { cumulativeCounts: lastYearCounts, dailyCounts: lastYearDailyCounts } = processEnquiries(lyEnquiries)

	// Get all unique dates across both datasets
	const allDates = [...new Set([...Object.keys(currentYearCounts), ...Object.keys(lastYearCounts)])].sort((a, b) => new Date(a) - new Date(b))

	// Get today as a string
	const today = new Date().toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "2-digit",
	})

	// Generate datasets for Chart.js
	const currentYearDataset = allDates.map((date) => {
		if (date > today) {
			return null
		}
		return currentYearCounts[date] || 0
	})
	const lastYearDataset = allDates.map((date) => lastYearCounts[date] || 0)

	const chartData = {
		labels: allDates,
		datasets: [
			{
				label: "This Year",
				data: currentYearDataset,
				borderColor: "#e41f13",
				backgroundColor: "#e41f13",
				fill: false,
			},
			{
				label: "Last Year",
				data: lastYearDataset,
				borderColor: "grey",
				backgroundColor: "grey",
				pointRadius: 2,
				borderDash: [5, 5],
				borderWidth: 2,
				fill: false,
			},
		],
	}

	const chartOptions = {
		responsive: true,
		animations: {
			y: {
				easing: "easeOutQuart",
				duration: 2000,
				from: (ctx) => {
					if (ctx.type === "data") {
						if (ctx.mode === "default" && !ctx.dropped) {
							ctx.dropped = true
							return ctx.chart.scales.y.bottom
						}
					}
					return ctx.chart.scales.y.bottom
				},
			},
			x: {
				duration: 0,
			},
		},
		plugins: {
			legend: {
				position: "top",
			},
			tooltip: {
				enabled: true,
				mode: "index",
				intersect: false,
				callbacks: {
					label: function (context) {
						const datasetIndex = context.datasetIndex
						const currentValue = context.raw
						const date = context.label
						const comparisonValue = datasetIndex === 0 ? context.chart.data.datasets[1].data[context.dataIndex] || 0 : context.chart.data.datasets[0].data[context.dataIndex] || 0

						const diff = currentValue - comparisonValue
						const percentageDiff = comparisonValue ? ((diff / comparisonValue) * 100).toFixed(2) : "N/A"

						// Get daily counts and calculate day-over-day change
						const dailyCounts = datasetIndex === 0 ? currentYearDailyCounts : lastYearDailyCounts
						const dailyTotal = dailyCounts[date] || 0

						// Get previous date's count
						const [day, month] = date.split("/").map(Number)
						const prevDate = `${String(day - 1).padStart(2, "0")}/${String(month).padStart(2, "0")}`
						const prevDayCount = dailyCounts[prevDate] || 0
						const dayOverDayChange = dailyTotal - prevDayCount

						// Add custom message based on dataset
						if (datasetIndex === 0) {
							return [
								`This Year: ${currentValue} (${diff >= 0 ? "+" : ""}${diff}, ${percentageDiff !== "N/A" ? percentageDiff + "%" : "N/A"})`,
								`Daily Total: ${dailyTotal} (${dayOverDayChange >= 0 ? "+" : ""}${dayOverDayChange} previous day)`,
							]
						} else {
							return [`Last Year: ${currentValue}`]
						}
					},
					labelColor: () => {
						return {
							borderColor: "transparent",
							backgroundColor: "transparent",
						}
					},
				},
			},
		},
		interaction: {
			mode: "index",
			intersect: false,
		},
		scales: {
			x: {
				title: {
					display: true,
					text: "Dates (DD/MM)",
				},
			},
			y: {
				title: {
					display: true,
					text: "Cumulative Enquiries",
				},
			},
		},
	}

	const customLabelsPlugin = {
		id: "customLabels",
		beforeDatasetsDraw(chart) {
			const { ctx, chartArea, scales } = chart

			ctx.save()

			// Create a Set of unique dates to track which lines we've drawn
			const drawnLines = new Set()

			// Define all events with their dates and labels
			const events = [
				// { date: "03/01", labels: ["Ad Bidding Change"], yOffset: 15 },
				// { date: "04/01", labels: ["Jan Newsletter"], yOffset: 30 },
				// { date: "06/01", labels: ["Ad Keyword Change"], yOffset: 15 },
				// { date: "06/01", labels: ["UK Newsletter"], yOffset: 30 },
				// { date: "06/01", labels: ["Competitor Ad Targeting"], yOffset: 45 },
				// { date: "08/01", labels: ["Spain Newsletter"], yOffset: 30 },
				// { date: "10/01", labels: ["Portugal Newsletter"], yOffset: 15 },
				// { date: "10/01", labels: ["The Masters Ad"], yOffset: 45 },
				// { date: "12/01", labels: ["France Newsletter"], yOffset: 30 },
				// { date: "14/01", labels: ["Hot Deals Newsletter"], yOffset: 15 },
				// { date: "15/01", labels: ["Ad Keywords Change"], yOffset: 45 },
				// { date: "16/01", labels: ["Ryder Cup Newsletter"], yOffset: 30 },
				// { date: "16/01", labels: ["Nick D Post"], yOffset: 15 },
				// { date: "20/01", labels: ["Promo Newsletter"], yOffset: 15 },
				// { date: "19/01", labels: ["UAE Newsletter"], yOffset: 30 },
				// { date: "19/01", labels: ["Microsoft Ads Start"], yOffset: 45 },
				// { date: "19/01", labels: ["Ad Campaign Changes"], yOffset: 60 },
				// { date: "22/01", labels: ["AG Solus"], yOffset: 15 },
				// { date: "22/01", labels: ["USA Newsletter"], yOffset: 30 },
				// { date: "24/01", labels: ["Africa Newsletter"], yOffset: 15 },
				// { date: "26/01", labels: ["Jan Newsletter"], yOffset: 15 },
				// { date: "26/01", labels: ["Ad Campaign Changes"], yOffset: 30 },
				// { date: "28/01", labels: ["Spa Newsletter"], yOffset: 45 },
				// { date: "30/01", labels: ["HNW Newsletter"], yOffset: 15 },
				// { date: "03/02", labels: ["Pirin Solus"], yOffset: 15 },
				// { date: "05/02", labels: ["Sandos Griego Solus"], yOffset: 30 },
				// { date: "07/02", labels: ["American Golf Ecomm"], yOffset: 15 },
				// { date: "09/02", labels: ["Sunday Savers"], yOffset: 30 },
				// { date: "10/02", labels: ["Sunday Savers"], yOffset: 15 },
				// { date: "11/02", labels: ["Hot Deals"], yOffset: 30 },
				// { date: "14/02", labels: ["Golf & Spa Days"], yOffset: 30 },
				// { date: "16/02", labels: ["All Inclusive"], yOffset: 15 },
				// { date: "18/02", labels: ["Ryder Cup"], yOffset: 15 },
				// { date: "13/02", labels: ["Ad Budget Down"], yOffset: 15 },
				// { date: "20/02", labels: ["Ad Budget Up"], yOffset: 15 },
				// { date: "20/02", labels: ["Group Deals"], yOffset: 30 },
				// { date: "22/02", labels: ["Golf Tours"], yOffset: 15 },
				{ date: "02/03", labels: ["Hot Deals Newsletter"], yOffset: 15 },
				{ date: "04/03", labels: ["DHM Solus Newsletter"], yOffset: 30 },
				{ date: "06/03", labels: ["All Inclusive Newsletter"], yOffset: 15 },
				{ date: "10/03", labels: ["Bing Budget Inc."], yOffset: 15 },
				{ date: "10/03", labels: ["Google Budget Dec."], yOffset: 30 },
				{ date: "10/03", labels: ["Spain Pt1 Newsletter"], yOffset: 45 },
				{ date: "12/03", labels: ["Spain Pt2 Newsletter"], yOffset: 60 },
				{ date: "15/03", labels: ["Spain Pt1 Resend"], yOffset: 15 },
				{ date: "16/03", labels: ["The Players Newsletter"], yOffset: 30 },
				{ date: "18/03", labels: ["March - Hot Deals"], yOffset: 15 },
			]

			// Draw vertical lines and labels for each event
			events.forEach(({ date, labels, yOffset }) => {
				const xValue = scales.x.getPixelForValue(date)

				if (xValue) {
					// Only draw the vertical line if we haven't drawn it for this date yet
					if (!drawnLines.has(date)) {
						ctx.strokeStyle = "rgba(255, 99, 132, 0.5)"
						ctx.lineWidth = 1
						ctx.beginPath()
						ctx.moveTo(xValue, chartArea.top)
						ctx.lineTo(xValue, chartArea.bottom)
						ctx.stroke()
						drawnLines.add(date)
					}

					// Draw labels (these will still be drawn for each event)
					ctx.font = "10px Poppins"
					ctx.fillStyle = "rgba(255, 99, 132, 0.75)"
					ctx.textAlign = "center"

					labels.forEach((label, index) => {
						ctx.fillText(label, xValue, chartArea.top + yOffset + index * 15)
					})
				}
			})

			ctx.restore()
		},
		afterDatasetsDraw(chart) {
			const { ctx } = chart
			const currentYearMeta = chart.getDatasetMeta(0)
			const lastYearDataset = chart.data.datasets[1].data

			ctx.save()

			// Draw custom labels above dots for current year
			currentYearMeta.data.forEach((point, index) => {
				const currentValue = chart.data.datasets[0].data[index]
				const lastYearValue = lastYearDataset[index] || 0

				// Skip drawing if the value is 0 or null
				if (!currentValue || currentValue === 0) return

				const diff = currentValue - lastYearValue
				const percentageDiff = lastYearValue === 0 ? "N/A" : ((diff / lastYearValue) * 100).toFixed(2)

				// Draw the value and difference above the dot
				const text = `${currentValue} (${percentageDiff}%)`
				ctx.font = "10px Poppins"
				ctx.fillStyle = "black"
				ctx.textAlign = "center"
				ctx.fillText(text, point.x, point.y - 20)
			})

			ctx.restore()
		},
	}

	return (
		<div>
			<h2 style={{ fontSize: "18px", marginBottom: "4px" }}>Enquiry Trends</h2>
			<p style={{ fontSize: "15px", marginBottom: "3px" }}>This month: {tmEnquiries.length}</p>
			<p style={{ fontSize: "15px", marginBottom: "3px" }}>Last year: {lyEnquiries.length}</p>
			<p style={{ fontSize: "15px", marginBottom: "3px" }}>Difference: {tmEnquiries.length - lyEnquiries.length}</p>
			<br />
			<div style={{ height: "675px", width: "100%" }}>
				<Line
					data={chartData}
					options={chartOptions}
					plugins={[customLabelsPlugin]}
				/>
			</div>
		</div>
	)
}
