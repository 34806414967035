import React, { useState, useEffect } from "react"
import { db } from "../../utils/firebase"
import "./booking-margins.scss"
import moment from "moment"

// UI imports
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Table from "../../components/structure/table/table"
import { LoadingIcon } from "../../utils/svgs"

// Returns the HTML markup for the booking margins page
export default function BookingMargins() {
	const [loading, setLoading] = useState(true)
	const [bookings, setBookings] = useState([])

	// On component load
	useEffect(() => {
		const fetchBookings = async () => {
			const bookings = await db.collection("bookings").get()

			// Loop through the bookings and format them
			const formattedBookings = bookings.docs
				.map((doc) => {
					if (doc.data().margins) {
						const { profit, customer_price, flights_cost } = doc.data().margins

						// Check if both profit and customer_price are valid numbers and customer_price is not zero
						if (profit != null && customer_price != null && customer_price !== 0) {
							// Work out the margin as a percentage of the customer price
							const margin = ((profit / customer_price) * 100).toFixed(2)

							// Calculate margin without flights cost if flights_cost exists
							let marginWithoutFlights = null
							if (flights_cost != null && flights_cost > 0) {
								const customerPriceWithoutFlights = customer_price - flights_cost
								if (customerPriceWithoutFlights > 0) {
									marginWithoutFlights = ((profit / customerPriceWithoutFlights) * 100).toFixed(2)
								}
							} else {
								// If no flights cost, the margin without flights is the same as regular margin
								marginWithoutFlights = margin
							}

							return {
								id: doc.id,
								...doc.data(),
								margin_percentage: parseFloat(margin),
								margin_without_flights_percentage: marginWithoutFlights ? parseFloat(marginWithoutFlights) : null,
							}
						}
					}
					return null
				})
				.filter((booking) => booking !== null)

			// Group the bookings by year and month
			const groupedBookings = formattedBookings.reduce((acc, booking) => {
				if (!booking.created) return acc

				const year = moment(booking.created.seconds, "X").format("YYYY")
				const month = moment(booking.created.seconds, "X").format("MMMM")

				const key = `${year}-${month}`

				if (!acc[key]) {
					acc[key] = {
						year,
						month,
						margins: [],
						margins_without_flights: [],
						count: 0,
					}
				}

				acc[key].margins.push(booking.margin_percentage)
				if (booking.margin_without_flights_percentage !== null) {
					acc[key].margins_without_flights.push(booking.margin_without_flights_percentage)
				}
				acc[key].count++
				return acc
			}, {})

			// Calculate averages and convert to array
			const bookingsArray = Object.values(groupedBookings).map((group) => ({
				year: group.year,
				month: group.month,
				average_margin: group.margins.length > 0 ? (group.margins.reduce((a, b) => a + b, 0) / group.margins.length).toFixed(2) : "0.00",
				average_margin_without_flights:
					group.margins_without_flights.length > 0 ? (group.margins_without_flights.reduce((a, b) => a + b, 0) / group.margins_without_flights.length).toFixed(2) : "0.00",
				count: group.count,
			}))

			// Sort by year and month (most recent first)
			const sortedBookings = bookingsArray.sort((a, b) => {
				if (b.year !== a.year) return b.year - a.year
				return moment(b.month, "MMMM").diff(moment(a.month, "MMMM"))
			})

			setBookings(sortedBookings)
			setLoading(false)
		}

		fetchBookings()
	}, [])

	return (
		<Tile fullPage={true}>
			<Title className="flex has-select-field">
				<h1>Booking Margins</h1>
			</Title>

			{loading && (
				<div className="table-loading-splash">
					<div className="loading-wrapper">
						<p>Loading...</p>
						<div className="svg-loading-wrap">
							<LoadingIcon />
						</div>
					</div>
				</div>
			)}

			<Table
				className="bookings-table"
				headings={["Year", "Month", "Bookings", "Average Margin (%)", "Average Margin w/o Flights (%)"]}
				noResults={!bookings.length}
				noResultsMessage="Choose some parameters to fetch conversion data">
				{bookings.map((bookingRow) => (
					<tr key={`${bookingRow.year}-${bookingRow.month}`}>
						<td>{bookingRow.year}</td>
						<td>{bookingRow.month}</td>
						<td>{bookingRow.count}</td>
						<td>{bookingRow.average_margin}%</td>
						<td>{bookingRow.average_margin_without_flights}%</td>
					</tr>
				))}
			</Table>
		</Tile>
	)
}
