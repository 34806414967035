import React, { useState, useContext } from "react"
import { AuthContext } from "../../utils/providers/auth"
import { PlusIcon, SearchIcon } from "../../utils/svgs"
import { auth } from "../../utils/firebase"
import "./enquiries.scss"

/**
 * Algolia
 */
import algoliasearch from "algoliasearch"
import { InstantSearch, Configure, connectSearchBox, connectHits, connectStateResults } from "react-instantsearch-dom"

/**
 * UI components
 */
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Input from "../../components/ui/inputs/input"
import Table from "../../components/structure/table/table"
import Navigation from "../../components/ui/navigation/navigation"
import RequireAttention from "./types/require-attention"
import Enquiry from "../../components/enquiry/enquiry"
import ViewEnquiry from "../../components/enquiry/view/view"
import Button from "../../components/ui/button/button"
import Add from "./add/add"
import Assign from "./assign/assign"
import Checkbox from "../../components/ui/checkbox/checkbox"

/**
 * Functional component to return the enquiry tables
 */
function Enquiries() {
	const [activeTab, setActiveTab] = useState("BEING_PROCESSED")
	const [requireAttentionCount, setRequireAttentionCount] = useState(0)
	const [viewEnquiry, setViewEnquiry] = useState("")
	const [addingEnquiry, setAddingEnquiry] = useState(false)
	const [assignEnquiry, setAssignEnquiry] = useState("")
	const [bulkAssignments, setBulkAssignments] = useState([])
	const [searchAll, setSearchAll] = useState(false)

	/**
	 * Pull the current user's object in from the auth context
	 */
	const { user } = useContext(AuthContext)

	/**
	 * Algolia search API keys
	 */
	const searchClient = algoliasearch("HY764WYKNV", "059a9cd79a33ec2b2822acf2ffdfd4c9")

	/**
	 * Custom inputfield for the algolia search
	 */
	const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
		<form
			noValidate
			action=""
			role="search">
			<Input
				value={currentRefinement}
				icon={<SearchIcon />}
				fullEvent={true}
				placeholder="Quick search"
				onChange={(e) => refine(e.currentTarget.value)}
			/>
		</form>
	)

	/**
	 * Connect the custom search box
	 */
	const CustomSearchBox = connectSearchBox(SearchBox)

	/**
	 * Setup a custom function for returning the hits streamed down from algolia
	 */
	const Hits = ({ hits }) => {
		/**
		 * Are there any hits found from algolia?
		 */
		if (hits.length > 0) {
			/**
			 * Print the objects found
			 */
			return hits.map((hit) => (
				<Enquiry
					key={hit.objectID}
					id={hit.objectID}
					details={hit}
					maskDetails={user?.maks_details}
					showEnquiryWindow={(enquiryID) => setViewEnquiry(enquiryID)}
				/>
			))
		} else {
			/**
			 * Return nothing for the hits
			 */
			return null
		}
	}

	/**
	 * Connect the custom hits to the DOM
	 */
	const CustomHits = connectHits(Hits)

	/**
	 * Connect a custom results component to display either the results no a no results message
	 */
	const Results = connectStateResults(({ searchState, searchResults, children }) =>
		searchResults && searchResults.nbHits !== 0 ? (
			<CustomHits />
		) : (
			<tr>
				<td colSpan={8}>
					<small>No results have been found {searchState.query ? `for ${searchState.query}.` : ""}</small>
				</td>
			</tr>
		)
	)

	const fullNavigation = [
		{
			id: "REQUIRE_ATTENTION",
			label: "Require attention",
			count: requireAttentionCount,
		},
		{
			id: "FREEPHONE",
			label: "Freephone leads",
		},
		{
			id: "HOT",
			label: "Hot leads",
		},
		{
			id: "BEING_PROCESSED",
			label: "Being processed",
		},
	]

	const restrictedNavigation = [
		{
			id: "FREEPHONE",
			label: "Freephone leads",
		},
		{
			id: "HOT",
			label: "Hot leads",
		},
		{
			id: "BEING_PROCESSED",
			label: "Being processed",
		},
	]

	return (
		<Tile fullPage={true}>
			<InstantSearch
				searchClient={searchClient}
				indexName="enquiries">
				{/* If the checkbox for searching them all isn't toggled */}
				{searchAll && <Configure filters={`status:${activeTab}`} />}

				{/* If the checkbox toggled for searching all is toggled */}
				{!searchAll && <Configure filters={`status:${activeTab} AND agent:${auth.currentUser.uid}`} />}

				<Title className="flex">
					<h1>All enquiries</h1>

					{/* Inputfield for algolia */}
					<CustomSearchBox />

					{/* Checkbox for searching through all agents if required */}
					<Checkbox
						label="Search all agents"
						checked={searchAll}
						onClick={() => setSearchAll(!searchAll)}
					/>

					{/* New enquiry button */}
					<Button
						label="New enquiry"
						small={true}
						icon={<PlusIcon />}
						onClick={() => setAddingEnquiry(true)}
					/>
				</Title>

				<Navigation
					links={user?.show_for?.can_assign ? fullNavigation : restrictedNavigation}
					active={activeTab}
					onClick={(tabID) => setActiveTab(tabID)}
				/>

				{/* Enquries that require attention (just been submitted from the website) */}
				{user?.show_for?.can_assign && activeTab === "REQUIRE_ATTENTION" && (
					<RequireAttention
						maskDetails={user?.mask_details}
						setBulk={(bulkEnquiries) => setBulkAssignments(bulkEnquiries)}
						setAssignEnquiry={(enquiryID) => setAssignEnquiry(enquiryID)}
						updateRequiredCount={(count) => setRequireAttentionCount(count)}
						showEnquiryWindow={(enquiryID) => setViewEnquiry(enquiryID)}
					/>
				)}

				{/* For all other enqiury tabs that are not "REQUIRE_ATTENTION" */}
				{activeTab !== "REQUIRE_ATTENTION" && (
					<Table
						className="enquiries-table"
						headings={["Site", "Name", "Email", "Telephone", "Follow up", "Location/Event", "Date from", "Group size", "Quoted"]}>
						<Results />
					</Table>
				)}

				{/* Do we have a enquiryID in the local state to load into a window? */}
				{viewEnquiry && (
					<ViewEnquiry
						enquiryID={viewEnquiry}
						close={() => setViewEnquiry("")}
					/>
				)}

				{/* Are we assigning an enquiry to an agent */}
				{assignEnquiry && (
					<Assign
						bulkAssign={bulkAssignments}
						enquiryID={assignEnquiry}
						close={() => setAssignEnquiry("")}
					/>
				)}
			</InstantSearch>

			{/* Are we adding a new enquiry? */}
			{addingEnquiry && <Add close={() => setAddingEnquiry(false)} />}
		</Tile>
	)
}

export default Enquiries
