import { useContext, useState, useEffect } from "react"
import { BookingContext } from "../../../utils/providers/booking"
import { AlertsContext } from "../../../utils/providers/alerts"
import { db } from "../../../utils/firebase"
import firebase from "firebase"
import "./event-info.scss"

// UI components
import Select from "../../../components/ui/select/select"
import Button from "../../../components/ui/button/button"
import Input from "../../../components/ui/inputs/input"
import Checkbox from "../../../components/ui/checkbox/checkbox"
import GroupName from "./_components/group-name/group-name"

// Define options for the select component in the format it expects
const eventOptions = {
	"": "No event",
	the_masters_2025: "The Masters 2025",
}

// Functional component to return the Event Information tab on the booking
export default function EventInfo() {
	// Deconstruct data from the booking context
	const { bookingID, eventTag: defaultEventTag, golfers, nonGolfers } = useContext(BookingContext)
	const { pushAlert } = useContext(AlertsContext)

	const [saving, setSaving] = useState(false)
	const [eventTag, setEventTag] = useState(defaultEventTag || null)
	const [groupNames, setGroupNames] = useState([])
	const [loadingNames, setLoadingNames] = useState(true)

	// Golf days and Masters viewing days
	const [golfDaysAll, setGolfDaysAll] = useState({
		friday_columbia: false,
		friday_lexington: false,
		saturday_windermere: false,
	})

	const [mastersDaysAll, setMastersDaysAll] = useState({
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false,
		friday: false,
		saturday: false,
		sunday: false,
	})

	// Add transfer days state
	const [transferDaysAll, setTransferDaysAll] = useState({
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false,
		friday: false,
		saturday: false,
		sunday: false,
	})

	// Club hire state for "apply to all"
	const [clubHireAll, setClubHireAll] = useState(false)

	// Add new state for transfer booking status
	const [transferBooked, setTransferBooked] = useState(false)
	const [transferReference, setTransferReference] = useState("")

	// Calculate total group size
	const totalGroupSize = parseInt(golfers || 0) + parseInt(nonGolfers || 0)

	// On component load
	useEffect(() => {
		const loadBookingData = async () => {
			// If we havn't mounted with an ID yet, return early
			if (!bookingID) return

			try {
				// Otherwise get the booking data
				await db
					.doc(`bookings/${bookingID}`)
					.get()
					.then((doc) => {
						// Return early if the booking doesn't exist
						if (!doc.exists) return

						// Otherwise pull the booking data
						const { event_tag, event_transfer_booked, event_transfer_reference } = doc.data()
						setEventTag(event_tag || "")
						setTransferBooked(event_transfer_booked || false)
						setTransferReference(event_transfer_reference || "")
					})
			} catch (err) {
				console.log(err)
			}
		}
		loadBookingData()
	}, [bookingID])

	// Setup a listener on the group names collection when component mounts
	useEffect(() => {
		const unsubscribe = db
			.collection(`bookings/${bookingID}/group_names`)
			.orderBy("last_name", "asc")
			.onSnapshot((snapshot) => {
				// Get an array of the IDs
				const groupNameIDs = snapshot.docs.map((doc) => ({ personID: doc.id, ...doc.data() }))

				// Set them into the state
				setGroupNames(groupNameIDs)
				setLoadingNames(false)

				// Check to see if all the golf days are set
				setGolfDaysAll({
					friday_columbia: groupNameIDs.every((person) => person.golf_days.friday_columbia),
					friday_lexington: groupNameIDs.every((person) => person.golf_days.friday_lexington),
					saturday_windermere: groupNameIDs.every((person) => person.golf_days.saturday_windermere),
				})

				// Check to see if all the masters days are set
				setMastersDaysAll({
					monday: groupNameIDs.every((person) => person.masters_days.monday),
					tuesday: groupNameIDs.every((person) => person.masters_days.tuesday),
					wednesday: groupNameIDs.every((person) => person.masters_days.wednesday),
					thursday: groupNameIDs.every((person) => person.masters_days.thursday),
					friday: groupNameIDs.every((person) => person.masters_days.friday),
					saturday: groupNameIDs.every((person) => person.masters_days.saturday),
					sunday: groupNameIDs.every((person) => person.masters_days.sunday),
				})

				// Check to see if all the transfer days are set
				setTransferDaysAll({
					monday: groupNameIDs.every((person) => person.transfer_days.monday),
					tuesday: groupNameIDs.every((person) => person.transfer_days.tuesday),
					wednesday: groupNameIDs.every((person) => person.transfer_days.wednesday),
					thursday: groupNameIDs.every((person) => person.transfer_days.thursday),
					friday: groupNameIDs.every((person) => person.transfer_days.friday),
					saturday: groupNameIDs.every((person) => person.transfer_days.saturday),
					sunday: groupNameIDs.every((person) => person.transfer_days.sunday),
				})

				// Check to see if all the club hire is set
				setClubHireAll(groupNameIDs.every((person) => person.club_hire))
			})

		return () => unsubscribe()
	}, [bookingID])

	// Save the event tag to the booking
	const saveBookingEventInfo = async () => {
		setSaving(true)

		try {
			await db.doc(`bookings/${bookingID}`).set(
				{
					event_tag: eventTag ? eventTag : firebase.firestore.FieldValue.delete(),
					event_transfer_booked: transferBooked,
					event_transfer_reference: transferReference,
				},
				{ merge: true }
			)

			pushAlert({
				type: "SUCCESS",
				title: "Booking Saved",
				body: "Event information saved successfully",
			})
		} catch (error) {
			console.log(error)
			pushAlert({
				type: "ERROR",
				title: "Booking Not Saved",
				body: "Error saving event information",
			})
		} finally {
			setSaving(false)
		}
	}

	// Add a new group name entry
	const addGroupName = async () => {
		await db.collection(`bookings/${bookingID}/group_names`).add({
			created: firebase.firestore.FieldValue.serverTimestamp(),
			updated: firebase.firestore.FieldValue.serverTimestamp(),
			first_name: "",
			last_name: "",
			email: "",
			phone: "",
			handicap: "",
			golf_days: {
				friday_columbia: false,
				friday_lexington: false,
				saturday_windermere: false,
			},
			masters_days: {
				monday: false,
				tuesday: false,
				wednesday: false,
				thursday: false,
				friday: false,
			},
			transfer_days: {
				monday: false,
				tuesday: false,
				wednesday: false,
				thursday: false,
				friday: false,
			},
			club_hire: false,
			club_hire_hand: "right",
		})
	}

	// Update golf days for all people
	const updateAllGolfDays = (day, value) => {
		try {
			// Update all the group names to have the new value in the database
			groupNames.forEach((person) => {
				db.doc(`bookings/${bookingID}/group_names/${person.personID}`)
					.update({
						["golf_days." + day]: value,
						updated: firebase.firestore.FieldValue.serverTimestamp(),
					})
					.catch((err) => {
						pushAlert({
							type: "ERROR",
							title: "Golf Days Not Updated",
							body: err.message,
						})
					})
			})

			// Update the "all" state
			setGolfDaysAll({
				...golfDaysAll,
				[day]: value,
			})
		} catch (err) {
			console.log(err)
			pushAlert({
				type: "ERROR",
				title: "Golf Days Not Updated",
				body: "Error updating golf days",
			})
		} finally {
			pushAlert({
				type: "SUCCESS",
				title: "Golf Days Updated",
				body: "All golf days updated successfully",
			})
		}
	}

	// Update masters days for all people
	const updateAllMastersDays = (day, value) => {
		try {
			// Update all the group names to have the new value in the database
			groupNames.forEach((person) => {
				db.doc(`bookings/${bookingID}/group_names/${person.personID}`)
					.update({
						["masters_days." + day]: value,
						updated: firebase.firestore.FieldValue.serverTimestamp(),
					})
					.catch((err) => {
						pushAlert({
							type: "ERROR",
							title: "Masters Days Not Updated",
							body: err.message,
						})
					})
			})

			// Update the "all" state
			setMastersDaysAll({
				...mastersDaysAll,
				[day]: value,
			})
		} catch (err) {
			console.log(err)
			pushAlert({
				type: "ERROR",
				title: "Masters Days Not Updated",
				body: "Error updating masters days",
			})
		} finally {
			pushAlert({
				type: "SUCCESS",
				title: "Masters Days Updated",
				body: "All masters days updated successfully",
			})
		}
	}

	// Update transfer days for all people
	const updateAllTransferDays = (day, value) => {
		try {
			// Update all the group names to have the new value in the database
			groupNames.forEach((person) => {
				db.doc(`bookings/${bookingID}/group_names/${person.personID}`)
					.update({
						["transfer_days." + day]: value,
						updated: firebase.firestore.FieldValue.serverTimestamp(),
					})
					.catch((err) => {
						pushAlert({
							type: "ERROR",
							title: "Transfer Days Not Updated",
							body: err.message,
						})
					})
			})

			// Update the "all" state
			setTransferDaysAll({
				...transferDaysAll,
				[day]: value,
			})
		} catch (err) {
			console.log(err)
			pushAlert({
				type: "ERROR",
				title: "Transfer Days Not Updated",
				body: "Error updating transfer days",
			})
		} finally {
			pushAlert({
				type: "SUCCESS",
				title: "Transfer Days Updated",
				body: "All transfer days updated successfully",
			})
		}
	}

	// Update club hire for all people
	const updateAllClubHire = (value) => {
		try {
			// Update all the group names to have the new value in the database
			groupNames.forEach((person) => {
				db.doc(`bookings/${bookingID}/group_names/${person.personID}`)
					.update({
						club_hire: value,
						updated: firebase.firestore.FieldValue.serverTimestamp(),
					})
					.catch((err) => {
						pushAlert({
							type: "ERROR",
							title: "Club Hire Not Updated",
							body: err.message,
						})
					})
			})

			// Update the "all" state
			setClubHireAll(value)
		} catch (err) {
			console.log(err)
			pushAlert({
				type: "ERROR",
				title: "Club Hire Not Updated",
				body: "Error updating club hire",
			})
		} finally {
			pushAlert({
				type: "SUCCESS",
				title: "Club Hire Updated",
				body: "All club hire updated successfully",
			})
		}
	}

	// Calculate status of group names to show as a string
	const groupNamesStatus = () => {
		if (totalGroupSize === 0) return "No group size specified (set golfers and non-golfers)"
		if (loadingNames) return "Loading group names..."

		const validEntries = groupNames.filter((person) => person.first_name || person.last_name).length

		if (validEntries === 0) return `No names added yet (${totalGroupSize} needed)`
		if (validEntries < totalGroupSize) return `${validEntries} of ${totalGroupSize} names added`
		if (validEntries === totalGroupSize) return `Complete: All ${totalGroupSize} names added`
		if (validEntries > totalGroupSize) return `Warning: ${validEntries} names added (only ${totalGroupSize} expected)`

		return "Unknown status"
	}

	// Get status color for the group names section
	const getStatusColor = () => {
		if (totalGroupSize === 0 || loadingNames) return "gray"

		const validEntries = groupNames.filter((person) => person.first_name || person.last_name).length

		if (validEntries === 0) return "red"
		if (validEntries < totalGroupSize) return "orange"
		if (validEntries === totalGroupSize) return "green"
		if (validEntries > totalGroupSize) return "orange"

		return "gray"
	}

	return (
		<>
			<table className="booking-table">
				<tbody>
					<tr>
						<td>Event Tag</td>
						<td>
							<Select
								placeholder="Select an event"
								value={eventOptions[eventTag] || ""}
								onSelect={(option) => setEventTag(option.option)}
								options={eventOptions}
								activeOnHover={true}
							/>
						</td>
					</tr>
					<tr>
						<td>Transfer Status</td>
						<td>
							<Select
								placeholder="Select an event"
								value={transferBooked ? "Yes" : "No"}
								onSelect={(option) => setTransferBooked(option.option === "Yes")}
								options={{ Yes: "Yes", No: "No" }}
								activeOnHover={true}
							/>
						</td>
					</tr>
					{transferBooked && (
						<tr>
							<td>Transfer Reference</td>
							<td>
								<Input
									value={transferReference || ""}
									onChange={(value) => setTransferReference(value)}
									placeholder="Enter booking reference"
								/>
							</td>
						</tr>
					)}
					<tr>
						<td></td>
						<td>
							<Button
								label="Save event information"
								loading={saving}
								loadingText="Saving..."
								onClick={saveBookingEventInfo}
							/>
						</td>
					</tr>
				</tbody>
			</table>

			{/* Group Names Section */}
			<div className="event-group-names-section">
				<h3>Group Names</h3>

				<div
					className="event-status-indicator"
					style={{ borderLeftColor: getStatusColor(), color: getStatusColor() === "gray" ? "#666" : getStatusColor() === "green" ? "#2e7d32" : getStatusColor() === "orange" ? "#ed6c02" : "#d32f2f" }}>
					{groupNamesStatus()}
				</div>

				{loadingNames ? (
					<div className="event-loading">Loading group names...</div>
				) : (
					<>
						<div className="event-golf-days-section">
							<h4>Golf Days</h4>

							<div className="event-golf-days-all">
								<div className="event-golf-days-all-header">
									<strong>Apply to All:</strong>
								</div>
								<div className="event-golf-days-all-checkboxes">
									<Checkbox
										label="Friday - Columbia"
										checked={golfDaysAll.friday_columbia}
										onClick={() => updateAllGolfDays("friday_columbia", !golfDaysAll.friday_columbia)}
									/>
									<Checkbox
										label="Friday - Lexington"
										checked={golfDaysAll.friday_lexington}
										onClick={() => updateAllGolfDays("friday_lexington", !golfDaysAll.friday_lexington)}
									/>
									<Checkbox
										label="Saturday - Windermere"
										checked={golfDaysAll.saturday_windermere}
										onClick={() => updateAllGolfDays("saturday_windermere", !golfDaysAll.saturday_windermere)}
									/>
								</div>
							</div>
						</div>

						<div className="event-masters-days-section">
							<h4>Masters Viewing Days</h4>

							<div className="event-masters-days-all">
								<div className="event-masters-days-all-header">
									<strong>Apply to All:</strong>
								</div>
								<div className="event-masters-days-all-checkboxes">
									<Checkbox
										label="Monday"
										checked={mastersDaysAll.monday}
										onClick={() => updateAllMastersDays("monday", !mastersDaysAll.monday)}
									/>
									<Checkbox
										label="Tuesday"
										checked={mastersDaysAll.tuesday}
										onClick={() => updateAllMastersDays("tuesday", !mastersDaysAll.tuesday)}
									/>
									<Checkbox
										label="Wednesday"
										checked={mastersDaysAll.wednesday}
										onClick={() => updateAllMastersDays("wednesday", !mastersDaysAll.wednesday)}
									/>
									<Checkbox
										label="Thursday"
										checked={mastersDaysAll.thursday}
										onClick={() => updateAllMastersDays("thursday", !mastersDaysAll.thursday)}
									/>
									<Checkbox
										label="Friday"
										checked={mastersDaysAll.friday}
										onClick={() => updateAllMastersDays("friday", !mastersDaysAll.friday)}
									/>
									<Checkbox
										label="Saturday"
										checked={mastersDaysAll.saturday}
										onClick={() => updateAllMastersDays("saturday", !mastersDaysAll.saturday)}
									/>
									<Checkbox
										label="Sunday"
										checked={mastersDaysAll.sunday}
										onClick={() => updateAllMastersDays("sunday", !mastersDaysAll.sunday)}
									/>
								</div>
							</div>
						</div>

						<div className="event-transfer-days-section">
							<h4>Transfer Days</h4>

							<div className="event-transfer-days-all">
								<div className="event-transfer-days-all-header">
									<strong>Apply to All:</strong>
								</div>
								<div className="event-transfer-days-all-checkboxes">
									<Checkbox
										label="Monday"
										checked={transferDaysAll.monday}
										onClick={() => updateAllTransferDays("monday", !transferDaysAll.monday)}
									/>
									<Checkbox
										label="Tuesday"
										checked={transferDaysAll.tuesday}
										onClick={() => updateAllTransferDays("tuesday", !transferDaysAll.tuesday)}
									/>
									<Checkbox
										label="Wednesday"
										checked={transferDaysAll.wednesday}
										onClick={() => updateAllTransferDays("wednesday", !transferDaysAll.wednesday)}
									/>
									<Checkbox
										label="Thursday"
										checked={transferDaysAll.thursday}
										onClick={() => updateAllTransferDays("thursday", !transferDaysAll.thursday)}
									/>
									<Checkbox
										label="Friday"
										checked={transferDaysAll.friday}
										onClick={() => updateAllTransferDays("friday", !transferDaysAll.friday)}
									/>
									<Checkbox
										label="Saturday"
										checked={transferDaysAll.saturday}
										onClick={() => updateAllTransferDays("saturday", !transferDaysAll.saturday)}
									/>
									<Checkbox
										label="Sunday"
										checked={transferDaysAll.sunday}
										onClick={() => updateAllTransferDays("sunday", !transferDaysAll.sunday)}
									/>
								</div>
							</div>
						</div>

						<div className="event-club-hire-section">
							<h4>Club Hire</h4>

							<div className="event-club-hire-all">
								<div className="event-club-hire-all-header">
									<strong>Apply to All:</strong>
								</div>
								<div className="event-club-hire-all-checkboxes">
									<Checkbox
										label="Requires Club Hire"
										checked={clubHireAll}
										onClick={() => updateAllClubHire(!clubHireAll)}
									/>
								</div>
							</div>
						</div>

						<div className="event-group-names-table-container">
							<table className="event-group-names-table">
								<thead>
									<tr>
										<th>First Name</th>
										<th>Last Name</th>
										<th>Email</th>
										<th>Phone</th>
										<th>Handicap</th>
										<th>Golf Days</th>
										<th>Masters Days</th>
										<th>Transfer Days</th>
										<th>Club Hire</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{groupNames.map(({ personID }) => (
										<GroupName
											key={personID}
											bookingID={bookingID}
											groupNameID={personID}
										/>
									))}

									{groupNames.length === 0 && (
										<tr>
											<td
												colSpan={eventTag === "the_masters_2025" ? "10" : "6"}
												className="event-no-data">
												No group names added yet
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
						<div className="event-button-group">
							<Button
								label="Add Person"
								onClick={addGroupName}
							/>
						</div>
					</>
				)}
			</div>
		</>
	)
}
