import React, { useState, useContext } from "react"
import { db, auth } from "../../../utils/firebase"
import { BookingContext } from "../../../utils/providers/booking"
import { AlertsContext } from "../../../utils/providers/alerts"
import { AuthContext } from "../../../utils/providers/auth"
import firebase from "firebase"
import moment from "moment"

/**
 * UI components
 */
import Input from "../../../components/ui/inputs/input"
import Select from "../../../components/ui/select/select"
import Button from "../../../components/ui/button/button"
import { MinusIcon } from "../../../utils/svgs-v2"
import Badge from "../../../components/ui/badge/badge"

/**
 * Functional component to return the booking margins panel
 */
function Margins(props) {
	const [assigning, setAssigning] = useState(false)

	const { pushAlert } = useContext(AlertsContext)
	const { user } = useContext(AuthContext)

	/**
	 * Deconstruct the client ID from the props
	 */
	const { clientID } = props

	/**
	 * Deconstruct data from the booking context
	 */
	const {
		bookingID,
		saveBookingDetails,
		totalCost,
		stablefordPoints,
		stablefordPointsSpent,
		stablefordAssigned,
		hotelCost,
		roundsCost,
		buggiesCost,
		transfersCost,
		setTransfersCost,
		flightsCost,
		setFlightsCost,
		supplierInvoices,
		miscellaneousCosts,
		setMiscellaneousCosts,
		exchangeRate,
		setExchangeRate,
		resortPaid,
		setResortPaid,
		profit,
		agentsMargin,
		currencies,
		checkInDate,
		marginsLocked,
		commissionLogs,
	} = useContext(BookingContext)

	// Get the current date
	const marginChange = moment("01/03/2024", "DD/MM/YYYY")
	const checkIn = moment(checkInDate?.seconds, "X")

	/**
	 * Calculate the profit percentage
	 */
	const profitPercent = ((profit / totalCost) * 100).toFixed(2)

	/**
	 * Assign the stableford points to the clients account
	 */
	const assignStablefordPoints = async () => {
		/**
		 * Update the state
		 */
		setAssigning(true)
		/**
		 * Push the new stableford points to the clients account
		 */
		await db.doc(`clients/${clientID}`).set(
			{
				stableford_points: firebase.firestore.FieldValue.increment(stablefordPoints),
			},
			{ merge: true }
		)
		/**
		 * Then toggle the booking to reflect they've been added
		 */
		await db.doc(`bookings/${bookingID}`).set(
			{
				stableford: {
					assigned: true,
				},
			},
			{ merge: true }
		)
		/**
		 * Reset the state
		 */
		setAssigning(false)
	}

	const assignPointsBalance = async () => {
		setAssigning(true)

		// Get a timestamp for now
		const nowTimestamp = moment().startOf("day").valueOf()
		const nowTimestampMillis = firebase.firestore.Timestamp.fromMillis(nowTimestamp)

		// We then want to find all bookings for this client, where the check_in date is in the past
		const pointsEarned = await db
			.collection("bookings")
			.where("client", "==", clientID)
			.where("check_in", "<=", nowTimestampMillis)
			.get()
			.then((previousBookings) => {
				let pointsEarned = 0

				// For each booking
				previousBookings.forEach((booking) => {
					// Pull some fields to check it's valid
					const { confirmed, balance_outstanding, stableford } = booking.data()

					// Was it confirmed, and did the client pay the full balance?
					if (balance_outstanding <= 0) {
						// Add the points into the running total
						pointsEarned = pointsEarned + Math.floor(stableford?.amount) || 0
					}
				})

				return pointsEarned
			})

		// Then search against the ledger on the clients account
		const availablePoints = await db
			.collection(`clients/${clientID}/points_ledger`)
			.get()
			.then((ledgerDocs) => {
				// If there are some docs returned
				if (ledgerDocs.size > 0) {
					let pointsUsed = 0

					// For each of the ledger documents
					ledgerDocs.forEach((ledgerDoc) => {
						// Pull the points from the document
						const { points, type } = ledgerDoc.data()

						// If the ledger type is SPENT
						if (type === "SPENT") {
							// Add the used points onto the total to remove
							pointsUsed = pointsUsed + points
						}
					})
					// Set the resulting points into the state
					return pointsEarned - pointsUsed
				} else {
					return pointsEarned
				}
			})

		// If there are some points available
		if (availablePoints) {
			// Set the stableford points amount on the booking to 0
			await db.doc(`bookings/${bookingID}`).set(
				{
					stableford: {
						amount: 0,
						spent: availablePoints,
					},
				},
				{ merge: true }
			)

			// Then add the spend into the clients ledger
			await db.collection(`clients/${clientID}/points_ledger`).add({
				type: "SPENT",
				points: availablePoints,
				booking: bookingID,
				created: firebase.firestore.FieldValue.serverTimestamp(),
			})

			// And finally add a new 'payment' on the booking using the points
			await db.collection(`bookings/${bookingID}/payments`).add({
				amount: availablePoints / 10,
				payee: "SYSTEM",
				reference: "Stableford Discount",
				paid_date: firebase.firestore.FieldValue.serverTimestamp(),
				created: firebase.firestore.FieldValue.serverTimestamp(),
				saved_by: auth.currentUser.uid,
			})
		} else {
			// If there are no points available, show an alert
			pushAlert({
				type: "ALERT",
				title: "No Points",
				body: "This user does not have any stableford points from previous bookings available to spend.",
			})
		}

		// Reset the state
		setAssigning(false)
	}

	// Work out the total commission paid from the logs
	const totalCommissionPaid = commissionLogs.reduce((acc, log) => {
		return acc + log.amount
	}, 0)

	return (
		<>
			<table className="booking-table">
				<tbody>
					<tr>
						<td>Customer price</td>
						<td>
							<Input
								type="text"
								value={totalCost.toLocaleString()}
								symbol="£"
								readOnly={true}
								placeholder="0"
							/>
						</td>
					</tr>
					<tr>
						<td>Stableford points</td>
						<td>
							{stablefordPointsSpent > 0 && (
								<Input
									type="text"
									value={`Spent ${stablefordPointsSpent} - (£${stablefordPointsSpent / 10} discount)`}
									placeholder="0"
									readOnly={true}
								/>
							)}

							{stablefordPointsSpent === 0 && (
								<Input
									type="text"
									value={`${stablefordPoints} ${stablefordAssigned ? "(assigned)" : ""}`}
									placeholder="0"
									readOnly={true}
								/>
							)}
						</td>
					</tr>
					{(!stablefordPointsSpent || stablefordPointsSpent === 0) && (
						<tr>
							<td></td>
							<td>
								<Button
									xsSmall={true}
									icon={<MinusIcon />}
									label="Use Stableford Balance"
									loading={assigning}
									loadingText="Assigning..."
									onClick={() => assignPointsBalance()}
								/>
							</td>
						</tr>
					)}
					<tr>
						<td>Hotels cost</td>
						<td>
							<Input
								type="text"
								value={hotelCost.toLocaleString()}
								symbol={currencies?.hotels === "USD" ? "$" : currencies?.hotels === "EUR" ? "€" : "£"}
								readOnly={true}
								placeholder="0"
							/>
						</td>
					</tr>
					<tr>
						<td>Rounds cost</td>
						<td>
							<Input
								type="text"
								value={roundsCost.toLocaleString()}
								symbol={currencies?.rounds === "USD" ? "$" : currencies?.rounds === "EUR" ? "€" : "£"}
								readOnly={true}
								placeholder="0"
							/>
						</td>
					</tr>
					<tr>
						<td>Buggies cost</td>
						<td>
							<Input
								type="text"
								value={buggiesCost.toLocaleString()}
								symbol={currencies?.rounds === "USD" ? "$" : currencies?.rounds === "EUR" ? "€" : "£"}
								readOnly={true}
								placeholder="0"
							/>
						</td>
					</tr>
					<tr>
						<td>Transfers cost</td>
						<td>
							<Input
								type="text"
								value={transfersCost.toLocaleString()}
								onChange={setTransfersCost}
								symbol="€"
								activeOnHover={true}
								placeholder="0"
								onBlur={() => saveBookingDetails()}
								readOnly={marginsLocked}
							/>
						</td>
					</tr>
					<tr>
						<td>Flights cost</td>
						<td>
							<Input
								type="text"
								value={flightsCost}
								onChange={setFlightsCost}
								symbol="£"
								activeOnHover={true}
								placeholder="0"
								onBlur={() => saveBookingDetails()}
								readOnly={marginsLocked}
							/>
						</td>
					</tr>
					<tr>
						<td>Miscellaneous costs</td>
						<td>
							<Input
								type="text"
								value={miscellaneousCosts.toLocaleString()}
								onChange={setMiscellaneousCosts}
								symbol="£"
								activeOnHover={true}
								placeholder="0"
								onBlur={() => saveBookingDetails()}
								readOnly={marginsLocked}
							/>
						</td>
					</tr>
					<tr>
						<td>Supplier invoices</td>
						<td>
							<Input
								type="text"
								value={supplierInvoices.toLocaleString()}
								symbol="£"
								readOnly={true}
								placeholder="0"
							/>
						</td>
					</tr>
					<tr>
						<td>Exchange rate</td>
						<td>
							<Input
								type="text"
								value={exchangeRate}
								onChange={setExchangeRate}
								activeOnHover={true}
								onBlur={() => saveBookingDetails()}
								readOnly={marginsLocked}
							/>
						</td>
					</tr>
					<tr>
						<td>Resort paid?</td>
						<td>
							<Select
								placeholder="Choose status:"
								value={resortPaid ? "Yes" : "No"}
								activeOnHover={true}
								onSelect={(option) => setResortPaid(option.option === "true" ? true : false)}
								options={{
									true: "Yes",
									false: "No",
								}}
								disabled={marginsLocked}
							/>
						</td>
					</tr>
					<tr>
						<td>Profit</td>
						<td>
							<Input
								type="text"
								value={`${profit.toLocaleString()} (${profitPercent}%)`}
								symbol="£"
								readOnly={true}
								placeholder="0"
							/>
						</td>
					</tr>
					<tr>
						<td>Agents margin</td>
						<td>
							<Input
								type="text"
								value={`${agentsMargin.toLocaleString()} (${checkIn.isBefore(marginChange) ? "10" : "5"}%)`}
								symbol="£"
								readOnly={true}
								placeholder="0"
							/>
						</td>
					</tr>
					<tr>
						<td>Commission paid</td>
						<td
							class="vertical-center"
							style={{ display: "flex", gap: 10, alignItems: "center" }}>
							{totalCommissionPaid === agentsMargin && (
								<Badge
									type="POSITIVE"
									label="Yes"
								/>
							)}

							{totalCommissionPaid !== agentsMargin && (
								<Badge
									type="AMBER"
									label="No"
								/>
							)}

							{user?.is_admin && (
								<Button
									small={true}
									disabled={totalCommissionPaid === agentsMargin}
									label="Add Payment"
									onClick={async () => {
										await db.collection(`bookings/${bookingID}/commission_logs`).add({
											amount: agentsMargin - totalCommissionPaid,
											created: firebase.firestore.FieldValue.serverTimestamp(),
											paid_by: auth.currentUser.uid,
										})
									}}
								/>
							)}
						</td>
					</tr>
					{commissionLogs?.length !== 0 && (
						<tr>
							<td></td>
							<td class="vertical-center">
								<p style={{ fontSize: 14, opacity: 0.65, marginBottom: 6 }}>Commission logs:</p>
								{commissionLogs.map((log, index) => (
									<div key={index}>
										<p style={{ fontSize: 15, marginBottom: 6 }}>
											{moment(log?.created?.seconds, "X").format("DD/MM/YYYY")}: {Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(log.amount)}
										</p>
									</div>
								))}
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</>
	)
}

export default Margins
