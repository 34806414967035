import React, { useState, useEffect } from "react"
import { db, fun, auth, storage } from "../../../utils/firebase"
import firebase from "firebase"
import "./staff.scss"

// UI components
import WindowCard from "../../ui/window-card/window-card"
import Window from "../../structure/window/window"
import Checkbox from "../../ui/checkbox/checkbox"
import Textarea from "../../ui/inputs/textarea"
import Button from "../../ui/button/button"
import Input from "../../ui/inputs/input"
import File from "../../ui/inputs/file"
import Select from "../../ui/select/select"

// Required to generate the ID for the new content document
const random = require("randomstring")

const managers = {
	FbeBRh8bIGb9L6aRLLJSttvutQ33: "Chris",
	"9ChsmNaiYkdQGjm3JbEbqY6mIbR2": "Conor",
	L334Rwj0YiRWSuCZLlixoBbsxwl2: "Oliver",
	"8lIXQuliWhPJXrgi0rM7dJ3e9kM2": "James",
	p4VG2EgFFQS8fsaBc0d9peNWaD52: "Matt",
}

// Functional component to return staff table row with targets
export default function Staff(props) {
	const [saving, setSaving] = useState(false)

	const [savingBio, setSavingBio] = useState(false)
	const [savingEmail, setSavingEmail] = useState(false)
	const [settingBio, setSettingBio] = useState(false)
	const [newEmail, setNewEmail] = useState("")
	const [bio, setBio] = useState("")
	const [manager, setManager] = useState({})
	const [clearingManager, setClearingManager] = useState(false)

	const [savingPhone, setSavingPhone] = useState(false)
	const [settingPhone, setSettingPhone] = useState(false)
	const [changeEmail, setChangeEmail] = useState(false)
	const [phone, setPhone] = useState("")

	const [savingPicture, setSavingPicture] = useState(false)
	const [settingPicture, setSettingPicture] = useState(false)
	const [picture, setPicture] = useState("")
	const [uploading, setUploading] = useState(false)
	const [uploadProgress, setUploadProgress] = useState(0)
	const [uploadFiles, setUploadFiles] = useState([])
	const [filePlaceholder, setFilePlaceholder] = useState("")
	const [fileErrors, setFileErrors] = useState(0)
	const [error, setError] = useState("")

	const [resetting, setResetting] = useState(false)
	const [resettingPassword, setResettingPassword] = useState(false)
	const [newPassword, setNewPassword] = useState("")

	// Deconstruct the user details from the props
	const { id, details } = props

	// On component load
	useEffect(() => {
		// Update the users bio into the state
		setBio(details.bio)
		setPhone(details.phone)
		setPicture(details.picture)

		setManager({
			value: managers[details.manager],
			option: details.manager,
		})
	}, [])

	/**
	 * When the resettingPassword state is updated
	 */
	useEffect(() => {
		/**
		 * Clear the new password if were loading into the window
		 */
		resettingPassword && setNewPassword("")
	}, [resettingPassword])

	/**
	 * Save the toggles into the database for the given user
	 */
	const toggleEnquiries = async () => {
		/**
		 * Show a loading spinner for the save button
		 */
		setSaving(true)
		/**
		 * Save the toggles into the database
		 */
		await db.doc(`users/${id}`).set(
			{
				show_for: {
					assignable: !details.show_for.assignable,
				},
				updated: firebase.firestore.FieldValue.serverTimestamp(),
			},
			{ merge: true }
		)
		/**
		 * Reset the state
		 */
		setSaving(false)
	}

	/**
	 * Save the toggles into the database for the given user
	 */
	const toggleLeaderboard = async () => {
		/**
		 * Show a loading spinner for the save button
		 */
		setSaving(true)
		/**
		 * Save the toggles into the database
		 */
		await db.doc(`users/${id}`).set(
			{
				show_for: {
					targets: !details.show_for.targets,
				},
				updated: firebase.firestore.FieldValue.serverTimestamp(),
			},
			{ merge: true }
		)
		/**
		 * Reset the state
		 */
		setSaving(false)
	}

	/**
	 * Save the toggles into the database for the given user
	 */
	const toggleCanAssign = async () => {
		/**
		 * Show a loading spinner for the save button
		 */
		setSaving(true)
		/**
		 * Save the toggles into the database
		 */
		await db.doc(`users/${id}`).set(
			{
				show_for: {
					can_assign: !details.show_for.can_assign,
				},
				updated: firebase.firestore.FieldValue.serverTimestamp(),
			},
			{ merge: true }
		)
		/**
		 * Reset the state
		 */
		setSaving(false)
	}

	/**
	 * Reset the users password with the new one from the state
	 */
	const processPasswordReset = async () => {
		/**
		 * Set the state to show it's loading
		 */
		setResetting(true)
		/**
		 * Call a cloud function to update the users password
		 */
		const resetUserPassword = fun.httpsCallable("resetUserPassword")
		await resetUserPassword({
			userID: id,
			password: newPassword,
		})
		/**
		 * Reset the state
		 */
		setResetting(false)
		setResettingPassword(false)
	}

	// Save the new bio for the user
	const saveUsersBio = async () => {
		// Set the loading state
		setSavingBio(true)

		// Update the database
		await db.doc(`users/${id}`).set(
			{
				bio: bio.trim(),
			},
			{ merge: true }
		)

		// Reset the state
		setSavingBio(false)
		setSettingBio(false)
	}

	// Save the phone number for the user
	const saveUsersPhone = async () => {
		// Set the loading state
		setSavingPhone(true)

		// Update the database
		await db.doc(`users/${id}`).set(
			{
				phone: phone.trim(),
			},
			{ merge: true }
		)

		// Reset the state
		setSavingPhone(false)
		setSettingPhone(false)
	}

	// Upload and save the new profile picture for the sales agent
	const saveUsersPicture = async () => {
		// Set the loading state
		setSavingPicture(true)

		// Process the file upload
		await handleFileUpload()

		// Reset the state
		setSavingPicture(false)
		setSettingPicture(false)
	}

	// Check the images chosen by the user for some requirements
	const checkChosenFiles = (files) => {
		// Set the state
		setError("")

		// Check for an empty array
		if (files.length > 0) {
			// Loop over the files
			for (const [index, file] of Object.entries(files)) {
				// Don't push this index because it'll only be holding the file count
				if (index !== "length") {
					// Generate a new ID for using on the document references for this file
					const uploadID = random.generate(20)

					// Strip everything from the name to just keep letters
					let cleanName = file.name.match(/[a-zA-Z]+/g)
					let fileID = `${cleanName}${file.size}`
					let fileUploadTime = file.lastModified
					let size = file.size

					// Add an ID and an errors object to the file
					let newFile = {
						id: fileID,
						title: file.name,
						uploadID,
						error: {},
						file,
						upload: {},
					}

					// Check if this file already exists
					uploadFiles.forEach((file) => {
						if (file.id === fileID) {
							newFile.id = `${fileID}${fileUploadTime}`
							newFile.error = {
								message: "You've already added this file to the upload.",
							}
						}
					})

					// Check for a valid file format
					const allowedFormats = ["image/jpeg", "image/jpg", "image/png"]
					if (!allowedFormats.includes(file.type)) {
						newFile.error = {
							message: "This file format is not supported.",
						}
					}

					// Check the size of the file to make sure it's below an acceptable limit of 5mb
					const sizeInMB = size / 1024 / 1024
					if (sizeInMB > 5) {
						newFile.error = {
							message: "Please ensure your file is below 5MB.",
						}
					}

					// Make sure there were no error appended onto this file
					if (!newFile.error?.message) {
						// Push the files object to the array for sending to state
						setUploadFiles((uploadFiles) => [...uploadFiles, newFile])
					} else {
						// Push the errors into the state
						setError(newFile.error?.message)
					}
				}
			}
		}
	}

	// Handles the uploading of the file into the storage bucket
	const handleFileUpload = () => {
		// Get the file object from the state
		const file = uploadFiles[0].file

		// Generate a new ID for using on the document references for this file
		const uploadID = random.generate(20)

		// Build out some metadata for the image
		const meta = {
			customMetadata: {
				fileID: uploadID,
				uploadedBy: auth.currentUser.uid,
			},
		}

		// Move the file into firebase storage
		const upload = storage.ref(`users/${id}/${uploadID}`).put(file, meta)

		// Return a promise listening to the upload progress
		return new Promise((resolve, reject) => {
			// Setup the monitor for the file
			monitorUploadProgress(upload, file.id).then(() => {
				// When it's complete, get the downloadURL from the callback
				upload.snapshot.ref.getDownloadURL().then(async (url) => {
					// Update the users document with the new image URL
					await db.doc(`users/${id}`).set(
						{
							picture: url,
						},
						{ merge: true }
					)

					// Set the image into the state
					setPicture(url)

					// And reoslve the promise
					resolve(url)
				})
			})
		})
	}

	// Attach a listener onto the upload job to stream the progress into state
	const monitorUploadProgress = (upload, fileID) => {
		return new Promise((resolve, reject) => {
			// Setup a listener on the upload process
			upload.on(
				"state_changed",
				(snapshot) => {
					// Work out a progress percentage
					const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)

					// Update the state with the progress of this file
					setUploadProgress(progress)
				},
				(error) => console.log(error),
				() => {
					resolve()
				}
			)
		})
	}

	const saveUsersNewEmail = async () => {
		setSavingEmail(true)

		try {
			const changeEmailFunction = fun.httpsCallable("updateAuthEmail")
			await changeEmailFunction({
				userID: id,
				email: newEmail,
			})
		} catch (err) {
			console.log(err)
			alert("Could not update the users email.")
		} finally {
			setNewEmail("")
			setSavingEmail(false)
			setChangeEmail(false)
			alert("Email updated successfully")
		}
	}

	const updateUsersManager = async (managerOpt) => {
		console.log(managerOpt)
		setSaving(true)

		try {
			await db.doc(`users/${id}`).set(
				{
					manager: managerOpt.option,
				},
				{ merge: true }
			)
			setManager({
				value: managerOpt.value,
				option: managerOpt.option,
			})
		} catch (err) {
			console.log(err)
			alert("Could not update the users manager.")
		} finally {
			setSaving(false)
			alert("Manager updated successfully")
		}
	}

	const clearManager = async () => {
		setClearingManager(true)
		await db.doc(`users/${id}`).set(
			{
				manager: null,
			},
			{ merge: true }
		)
		setManager({})
		setClearingManager(false)
	}

	return (
		<>
			<tr data-system-user={props.id}>
				<td>
					{details.first_name} {details.last_name} <br />
					<small style={{ display: "block", marginTop: "-18px" }}>{props.id}</small>
					<small style={{ display: "block", marginTop: "-18px" }}>On version: {props.details?.on_version || "Unknown"}</small>
				</td>
				<td>
					<Checkbox
						checked={details?.show_for.can_assign}
						onClick={() => toggleCanAssign()}
						label="Can asssign enquiries"
					/>
					<Checkbox
						checked={details?.show_for.assignable}
						onClick={() => toggleEnquiries()}
						label="Can be assigned enquiries"
					/>
					<Checkbox
						checked={details?.show_for.targets}
						onClick={() => toggleLeaderboard()}
						label="Is shown on the leaderboard"
					/>
				</td>
				<td style={{ width: "250px" }}>
					<div style={{ paddingRight: "30px" }}>
						<div style={{ paddingTop: "10px" }} />
						<Select
							placeholder="Choose manager:"
							value={manager?.value}
							selected={manager?.option}
							activeOnHover={true}
							onSelect={(option) => updateUsersManager(option)}
							options={{
								FbeBRh8bIGb9L6aRLLJSttvutQ33: "Chris",
								"9ChsmNaiYkdQGjm3JbEbqY6mIbR2": "Conor",
								L334Rwj0YiRWSuCZLlixoBbsxwl2: "Oliver",
								"8lIXQuliWhPJXrgi0rM7dJ3e9kM2": "James",
								p4VG2EgFFQS8fsaBc0d9peNWaD52: "Matt",
							}}
						/>
					</div>
				</td>
				<td className="user-action-buttons">
					{/* Lewis Newson */}
					{auth.currentUser.uid === "CGOHCdUwFAOe13OSTY2D9qdO2LX2" && props.id === "CGOHCdUwFAOe13OSTY2D9qdO2LX2" && (
						<>
							<Button
								xsSmall={true}
								label="Set bio"
								onClick={() => setSettingBio(true)}
							/>

							<Button
								xsSmall={true}
								label="Set phone"
								onClick={() => setSettingPhone(true)}
							/>

							<Button
								xsSmall={true}
								label="Set picture"
								onClick={() => setSettingPicture(true)}
							/>

							<Button
								xsSmall={true}
								label="Reset password"
								onClick={() => setResettingPassword(true)}
							/>
							<Button
								xsSmall={true}
								label="Change email"
								onClick={() => setChangeEmail(true)}
							/>
						</>
					)}

					{/* Lewis Remington */}
					{auth.currentUser.uid === "iUlTXjfx1OZEPBhPosh6rJt3CKe2" && props.id === "iUlTXjfx1OZEPBhPosh6rJt3CKe2" && (
						<>
							<Button
								xsSmall={true}
								label="Set bio"
								onClick={() => setSettingBio(true)}
							/>

							<Button
								xsSmall={true}
								label="Set phone"
								onClick={() => setSettingPhone(true)}
							/>

							<Button
								xsSmall={true}
								label="Set picture"
								onClick={() => setSettingPicture(true)}
							/>

							<Button
								xsSmall={true}
								label="Reset password"
								onClick={() => setResettingPassword(true)}
							/>
							<Button
								xsSmall={true}
								label="Change email"
								onClick={() => setChangeEmail(true)}
							/>
						</>
					)}

					{/* If neither */}
					{props.id !== "CGOHCdUwFAOe13OSTY2D9qdO2LX2" && props.id !== "iUlTXjfx1OZEPBhPosh6rJt3CKe2" && (
						<>
							<Button
								xsSmall={true}
								label="Set bio"
								onClick={() => setSettingBio(true)}
							/>

							<Button
								xsSmall={true}
								label="Set phone"
								onClick={() => setSettingPhone(true)}
							/>

							<Button
								xsSmall={true}
								label="Set picture"
								onClick={() => setSettingPicture(true)}
							/>

							<Button
								xsSmall={true}
								label="Reset password"
								onClick={() => setResettingPassword(true)}
							/>

							<Button
								xsSmall={true}
								label="Change email"
								onClick={() => setChangeEmail(true)}
							/>

							<Button
								xsSmall={true}
								label="Clear manager"
								loading={clearingManager}
								loadingText="Clearing..."
								onClick={() => clearManager()}
							/>
						</>
					)}

					<div style={{ paddingBottom: "10px" }} />
				</td>
			</tr>

			{settingBio && (
				<Window
					className="slim"
					title={`Setting ${details.first_name}'s bio`}
					close={() => setSettingBio(false)}>
					<WindowCard>
						<div className="input-grid cols-1">
							<Textarea
								placeholder="e.g. I’ve been working within the golf travel industry for x years..."
								value={bio}
								onChange={setBio}
							/>

							<Button
								label="Save"
								loading={savingBio}
								loadingText="Saving..."
								fullWidth={true}
								onClick={() => saveUsersBio()}
							/>
						</div>
					</WindowCard>
				</Window>
			)}

			{settingPhone && (
				<Window
					className="slim"
					title={`Setting ${details.first_name}'s phone number`}
					close={() => setSettingPhone(false)}>
					<WindowCard>
						<div className="input-grid cols-1">
							<Input
								placeholder="e.g. 01234 567890"
								value={phone}
								onChange={setPhone}
							/>

							<Button
								label="Save"
								loading={savingBio}
								loadingText="Saving..."
								fullWidth={true}
								onClick={() => saveUsersPhone()}
							/>
						</div>
					</WindowCard>
				</Window>
			)}

			{changeEmail && (
				<Window
					className="slim"
					title={`Setting ${details.first_name}'s email address`}
					close={() => setChangeEmail(false)}>
					<WindowCard>
						<div className="input-grid cols-1">
							<Input
								placeholder="New email address:"
								value={newEmail}
								onChange={setNewEmail}
							/>

							<Button
								label="Update"
								loading={savingEmail}
								loadingText="Saving..."
								fullWidth={true}
								onClick={() => saveUsersNewEmail()}
							/>
						</div>
					</WindowCard>
				</Window>
			)}

			{settingPicture && (
				<Window
					className="slim"
					title={`Update ${details.first_name}'s picture`}
					close={() => setSettingPicture(false)}>
					<WindowCard>
						<div className="input-grid cols-1">
							<div className="user-picture-flex">
								{picture && (
									<a
										href={picture}
										target="_blank"
										className="user-profile-picture">
										<img
											src={picture}
											alt={details.first_name}
										/>
									</a>
								)}

								<File
									placeholder="Choose file"
									showFileNames={true}
									accept="image/png, image/jpg, image/jpeg"
									onChange={(files) => checkChosenFiles(files)}
									error={error}
								/>
							</div>

							<Button
								label="Upload & Save"
								loading={savingPicture}
								loadingText="Saving..."
								fullWidth={true}
								onClick={() => saveUsersPicture()}
							/>
						</div>
					</WindowCard>
				</Window>
			)}

			{resettingPassword && (
				<Window
					className="slim"
					title={`Resetting ${details.first_name}'s password`}
					close={() => setResettingPassword(false)}>
					<WindowCard>
						<div className="input-grid cols-1">
							<Input
								type="password"
								placeholder="New password:"
								value={newPassword}
								onChange={setNewPassword}
							/>

							<Button
								label="Reset"
								loading={resetting}
								loadingText="Resetting..."
								fullWidth={true}
								disabled={newPassword.length < 8}
								onClick={() => processPasswordReset()}
							/>
						</div>
					</WindowCard>
				</Window>
			)}
		</>
	)
}
