import React, { useState, useEffect, useContext } from "react"
import { AuthContext } from "../../utils/providers/auth"
import { db } from "../../utils/firebase"
import firebase from "firebase"
import moment from "moment"
import "./manager-analytics.scss"

// ChartJS imports
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title as ChartJSTitle } from "chart.js"
import { Pie, Bar } from "react-chartjs-2"
import annotationPlugin from "chartjs-plugin-annotation"

// UI components
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Select from "../../components/ui/select/select"
import Datepicker from "../../components/ui/datepicker/datepicker"
import Table from "../../components/structure/table/table"
import { ChartArea } from "lucide-react"
import { CheckIcon, XIcon } from "lucide-react"

// Register ChartJS components
ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, ChartJSTitle, Tooltip, Legend, annotationPlugin)

// Functional component to return the manager analytics dashboard
export default function ManagerAnalytics() {
	const [loading, setLoading] = useState(true)
	const [managers, setManagers] = useState({})
	const [selectedManager, setSelectedManager] = useState("")
	const [timeframe, setTimeframe] = useState("year_month")
	const [customRange, setCustomRange] = useState(false)
	const [yearMonthMode, setYearMonthMode] = useState(true)
	const [selectedYear, setSelectedYear] = useState(moment().year())
	const [selectedMonth, setSelectedMonth] = useState(moment().month())
	const [startDate, setStartDate] = useState(moment().startOf("month").toDate())
	const [endDate, setEndDate] = useState(moment().endOf("month").toDate())
	const [stats, setStats] = useState({
		totalEnquiries: 0,
		convertedEnquiries: 0,
		deletedEnquiries: 0,
		conversionRate: 0,
		enquiriesByAgent: {},
		bookingsByAgent: {},
		deletedByAgent: {},
		originalEnquiriesByAgent: {},
		marginsByAgent: {},
		targetsByAgent: {},
		agentNames: {},
		noAgents: false,
		teamTotalMargin: 0,
		teamTotalBookings: 0,
	})

	// Get the current user auth from the context
	const { user } = useContext(AuthContext)

	// On component load
	useEffect(() => {
		// Only admins and managers can access this page
		if (!user.is_admin && !user.is_manager) {
			window.location.href = "/"
			return
		}

		// Fetch all managers
		fetchManagers()
	}, [user])

	// When the selected manager changes
	useEffect(() => {
		if (selectedManager) {
			fetchAnalytics()
		}
	}, [selectedManager, timeframe, customRange, startDate, endDate])

	// Fetch all managers from the database
	const fetchManagers = async () => {
		try {
			const managersData = {}
			const sortedManagers = {}

			// Get all users who are managers
			const managerDocs = await db.collection("users").where("is_manager", "==", true).get()

			// Collect all managers with their names
			const managersArray = []
			managerDocs.forEach((doc) => {
				const { first_name, last_name } = doc.data()
				managersArray.push({
					id: doc.id,
					name: `${first_name} ${last_name}`,
				})
			})

			// If current user is admin, also include them if they're not already in the list
			if (user.is_admin) {
				const isAlreadyIncluded = managersArray.some((manager) => manager.id === user.uid)
				if (!isAlreadyIncluded) {
					managersArray.push({
						id: user.uid,
						name: `${user.first_name} ${user.last_name} (You)`,
					})
				}
			}

			// Sort managers by name
			managersArray.sort((a, b) => a.name.localeCompare(b.name))

			// Convert back to object format
			managersArray.forEach((manager) => {
				sortedManagers[manager.id] = manager.name
			})

			setManagers(sortedManagers)
			setLoading(false)
		} catch (error) {
			console.error("Error fetching managers:", error)
			setLoading(false)
		}
	}

	// Handle timeframe change
	const handleTimeframeChange = (option) => {
		const selectedOption = option.option
		setTimeframe(selectedOption)
		setCustomRange(selectedOption === "custom")
		setYearMonthMode(selectedOption === "year_month")

		// Update date range based on selected timeframe
		const now = moment()
		if (selectedOption === "week") {
			setStartDate(now.clone().subtract(1, "week").startOf("day").toDate())
			setEndDate(now.endOf("day").toDate())
		} else if (selectedOption === "month") {
			setStartDate(moment().subtract(1, "month").startOf("month").toDate())
			setEndDate(moment().subtract(1, "month").endOf("month").toDate())
		} else if (selectedOption === "year") {
			setStartDate(moment().subtract(1, "year").startOf("year").toDate())
			setEndDate(moment().subtract(1, "year").endOf("year").toDate())
		} else if (selectedOption === "year_month") {
			// Set to current year/month by default
			setSelectedYear(now.year())
			setSelectedMonth(now.month())
			setStartDate(moment().year(now.year()).month(now.month()).startOf("month").toDate())
			setEndDate(moment().year(now.year()).month(now.month()).endOf("month").toDate())
		}
	}

	// Handle year change for year/month mode
	const handleYearChange = (option) => {
		const year = parseInt(option.option)
		setSelectedYear(year)
		setStartDate(moment().year(year).month(selectedMonth).startOf("month").toDate())
		setEndDate(moment().year(year).month(selectedMonth).endOf("month").toDate())
	}

	// Handle month change for year/month mode
	const handleMonthChange = (option) => {
		const month = parseInt(option.option)
		setSelectedMonth(month)
		setStartDate(moment().year(selectedYear).month(month).startOf("month").toDate())
		setEndDate(moment().year(selectedYear).month(month).endOf("month").toDate())
	}

	// Fetch analytics data for the selected manager
	const fetchAnalytics = async () => {
		setLoading(true)

		try {
			// Calculate date range based on timeframe or custom dates
			let calculatedStartDate, calculatedEndDate
			let isCustomDateRange = customRange
			let shouldCalculateTarget = false
			let targetMonth = ""

			if (customRange) {
				calculatedStartDate = moment(startDate).startOf("day")
				calculatedEndDate = moment(endDate).endOf("day")
			} else if (yearMonthMode) {
				calculatedStartDate = moment().year(selectedYear).month(selectedMonth).startOf("month")
				calculatedEndDate = moment().year(selectedYear).month(selectedMonth).endOf("month")
				shouldCalculateTarget = true
				targetMonth = moment().year(selectedYear).month(selectedMonth).format("YYYY_MM")
			} else {
				const now = moment()
				if (timeframe === "week") {
					calculatedStartDate = now.clone().subtract(1, "week").startOf("day")
					calculatedEndDate = now.endOf("day")
					shouldCalculateTarget = true
					targetMonth = now.format("YYYY_MM")
				} else if (timeframe === "month") {
					calculatedStartDate = now.clone().subtract(1, "month").startOf("month")
					calculatedEndDate = now.clone().subtract(1, "month").endOf("month")
					shouldCalculateTarget = true
					targetMonth = now.subtract(1, "month").format("YYYY_MM")
				} else if (timeframe === "year") {
					calculatedStartDate = now.clone().subtract(1, "year").startOf("year")
					calculatedEndDate = now.clone().subtract(1, "year").endOf("year")
				}
			}

			// Convert to Firebase timestamps
			const startTimestamp = firebase.firestore.Timestamp.fromMillis(calculatedStartDate.valueOf())
			const endTimestamp = firebase.firestore.Timestamp.fromMillis(calculatedEndDate.valueOf())

			// Get all agents managed by this manager
			const agentDocs = await db.collection("users").where("manager", "==", selectedManager).get()

			const agentIds = []
			const agentNames = {}

			agentDocs.forEach((doc) => {
				agentIds.push(doc.id)
				agentNames[doc.id] = `${doc.data().first_name} ${doc.data().last_name}`
			})

			// If no agents found, show a message
			if (agentIds.length === 0) {
				setStats({
					totalEnquiries: 0,
					convertedEnquiries: 0,
					deletedEnquiries: 0,
					conversionRate: 0,
					enquiriesByAgent: {},
					bookingsByAgent: {},
					deletedByAgent: {},
					originalEnquiriesByAgent: {},
					marginsByAgent: {},
					targetsByAgent: {},
					agentNames: {},
					noAgents: true,
					teamTotalMargin: 0,
					teamTotalBookings: 0,
				})
				setLoading(false)
				return
			}

			// Initialize stats for each agent
			const enquiriesByAgent = {}
			const bookingsByAgent = {}
			const deletedByAgent = {}
			const originalEnquiriesByAgent = {}
			const marginsByAgent = {}
			const targetsByAgent = {}

			agentIds.forEach((id) => {
				enquiriesByAgent[id] = 0
				bookingsByAgent[id] = 0
				deletedByAgent[id] = 0
				originalEnquiriesByAgent[id] = 0
				marginsByAgent[id] = {
					totalMargin: 0,
					count: 0,
					average: 0,
				}
				targetsByAgent[id] = {
					monthly: 0,
					achieved: false,
					percentage: 0,
					totalProfit: 0,
				}
			})

			// Get current month for target lookup
			const currentMonth = moment().format("YYYY_MM")

			// Determine which month to use for target lookup based on timeframe
			let targetMonthToUse = currentMonth
			if (!isCustomDateRange && !yearMonthMode) {
				if (timeframe === "month") {
					// For "Last Month" timeframe, use the previous month for target
					targetMonthToUse = moment().subtract(1, "month").format("YYYY_MM")
				} else if (timeframe === "week" || timeframe === "year") {
					// For other timeframes, use the current month's target
					targetMonthToUse = currentMonth
				}
			} else if (yearMonthMode) {
				// For year/month mode, use the selected month
				targetMonthToUse = targetMonth
			}

			// Fetch targets for all agents in parallel
			const targetPromises = agentIds.map(async (agentId) => {
				// Only fetch targets if we should calculate them
				if (shouldCalculateTarget) {
					// Try to get the archived target for the target month
					const targetDoc = await db.doc(`targets/${agentId}/archives/${targetMonthToUse}`).get()

					if (targetDoc.exists) {
						targetsByAgent[agentId].monthly = targetDoc.data().monthly || 0
					} else {
						// If no archived target, try to get the current target
						const currentTargetDoc = await db.doc(`targets/${agentId}`).get()
						if (currentTargetDoc.exists) {
							targetsByAgent[agentId].monthly = currentTargetDoc.data().monthly || 0
						}
					}
				}
			})

			// Wait for all target fetches to complete
			await Promise.all(targetPromises)

			// Get all enquiries for these agents
			let totalEnquiries = 0
			let totalOriginalEnquiries = 0
			let totalConverted = 0
			let totalDeleted = 0
			let teamTotalMargin = 0
			let teamTotalBookings = 0

			for (const agentId of agentIds) {
				// Fetch active, removed, and booked enquiries concurrently
				const [activeEnquiries, removedEnquiries, bookedEnquiries] = await Promise.all([
					db.collection("enquiries").where("agent", "==", agentId).where("created", ">=", startTimestamp).where("created", "<=", endTimestamp).get(),

					db.collection("enquiries_removed").where("agent", "==", agentId).where("enquired", ">=", startTimestamp).where("enquired", "<=", endTimestamp).get(),

					db.collection("bookings").where("agent", "==", agentId).where("enquiry.created", ">=", startTimestamp).where("enquiry.created", "<=", endTimestamp).get(),
				])

				// Store original enquiry counts (before de-duplication)
				originalEnquiriesByAgent[agentId] = activeEnquiries.size + removedEnquiries.size
				totalOriginalEnquiries += originalEnquiriesByAgent[agentId]

				// Remove all duplicate enquiries using the client ID
				const activeEnquiriesMap = new Map()
				activeEnquiries.forEach((doc) => {
					const enquiry = doc.data()
					activeEnquiriesMap.set(enquiry.client, enquiry)
				})

				const removedEnquiriesMap = new Map()
				removedEnquiries.forEach((doc) => {
					const enquiry = doc.data()
					removedEnquiriesMap.set(enquiry.enquiry?.client, enquiry)
				})

				// Calculate counts after de-duplication
				const activeCount = activeEnquiriesMap.size
				const removedCount = removedEnquiriesMap.size
				const bookedCount = bookedEnquiries.size

				// Update agent stats
				enquiriesByAgent[agentId] = activeCount + removedCount
				bookingsByAgent[agentId] = bookedCount
				deletedByAgent[agentId] = removedCount

				// Calculate margin percentages for each booking and track total profit
				let totalProfit = 0
				for (const doc of bookedEnquiries.docs) {
					const bookingData = doc.data()
					if (bookingData.margins && bookingData.margins.profit != null && bookingData.margins.customer_price != null && bookingData.margins.customer_price !== 0) {
						const marginPercentage = (bookingData.margins.profit / bookingData.margins.customer_price) * 100
						marginsByAgent[agentId].totalMargin += marginPercentage
						marginsByAgent[agentId].count++

						// Add to team totals
						teamTotalMargin += bookingData.margins.profit
						teamTotalBookings++
					}
				}

				// Calculate average margin percentage
				if (marginsByAgent[agentId].count > 0) {
					marginsByAgent[agentId].average = marginsByAgent[agentId].totalMargin / marginsByAgent[agentId].count
				}

				// Update totals
				totalEnquiries += enquiriesByAgent[agentId]
				totalConverted += bookedCount
				totalDeleted += removedCount
			}

			// If we should calculate targets, fetch target-related data
			if (shouldCalculateTarget) {
				// For each agent, fetch bookings for the target month to calculate target achievement
				for (const agentId of agentIds) {
					if (targetsByAgent[agentId].monthly > 0) {
						// Determine the date range for target calculation
						let targetStartDate, targetEndDate

						if (yearMonthMode) {
							// For year/month mode, use the selected month
							targetStartDate = moment().year(selectedYear).month(selectedMonth).startOf("month")
							targetEndDate = moment().year(selectedYear).month(selectedMonth).endOf("month")
						} else if (timeframe === "month") {
							// For "Last Month" timeframe, use the previous month
							targetStartDate = moment().subtract(1, "month").startOf("month")
							targetEndDate = moment().subtract(1, "month").endOf("month")
						} else if (timeframe === "week") {
							// For "Last Week" timeframe, use the current month
							targetStartDate = moment().startOf("month")
							targetEndDate = moment().endOf("month")
						}

						const targetStartTimestamp = firebase.firestore.Timestamp.fromMillis(targetStartDate.valueOf())
						const targetEndTimestamp = firebase.firestore.Timestamp.fromMillis(targetEndDate.valueOf())

						// Get bookings created in the target month
						const targetBookings = await db.collection("bookings").where("agent", "==", agentId).where("created", ">=", targetStartTimestamp).where("created", "<=", targetEndTimestamp).get()

						// Calculate total profit for target comparison
						let targetTotalProfit = 0
						targetBookings.forEach((doc) => {
							const bookingData = doc.data()
							if (bookingData.margins && bookingData.margins.profit != null) {
								targetTotalProfit += bookingData.margins.profit
							}
						})

						// Update target achievement data
						targetsByAgent[agentId].totalProfit = targetTotalProfit
						const percentage = (targetTotalProfit / targetsByAgent[agentId].monthly) * 100
						targetsByAgent[agentId].percentage = percentage.toFixed(1)
						targetsByAgent[agentId].achieved = targetTotalProfit >= targetsByAgent[agentId].monthly
					}
				}
			}

			// Calculate conversion rate
			const conversionRate = totalEnquiries > 0 ? (totalConverted / totalEnquiries) * 100 : 0

			// Update stats state
			setStats({
				totalEnquiries,
				totalOriginalEnquiries,
				convertedEnquiries: totalConverted,
				deletedEnquiries: totalDeleted,
				conversionRate: conversionRate.toFixed(2),
				enquiriesByAgent,
				originalEnquiriesByAgent,
				bookingsByAgent,
				deletedByAgent,
				marginsByAgent,
				targetsByAgent,
				agentNames,
				teamTotalMargin,
				teamTotalBookings,
			})

			setLoading(false)
		} catch (error) {
			console.error("Error fetching analytics:", error)
			setLoading(false)
		}
	}

	// Prepare data for the conversion rate pie chart
	const conversionPieData = {
		labels: ["Converted", "Deleted", "Open/In Progress"],
		datasets: [
			{
				data: [stats.convertedEnquiries, stats.deletedEnquiries, stats.totalEnquiries - stats.convertedEnquiries - stats.deletedEnquiries],
				backgroundColor: ["rgba(75, 192, 192, 0.6)", "rgba(255, 99, 132, 0.6)", "rgba(54, 162, 235, 0.6)"],
				borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
				borderWidth: 1,
			},
		],
	}

	// Prepare data for the agent performance bar chart
	const agentPerformanceData = {
		labels: stats.agentNames ? Object.keys(stats.enquiriesByAgent).map((id) => stats.agentNames[id]) : [],
		datasets: [
			{
				label: "Total Enquiries",
				data: Object.values(stats.enquiriesByAgent),
				backgroundColor: "rgba(54, 162, 235, 0.6)",
				borderColor: "rgba(54, 162, 235, 1)",
				borderWidth: 1,
			},
			{
				label: "Converted to Bookings",
				data: Object.values(stats.bookingsByAgent),
				backgroundColor: "rgba(75, 192, 192, 0.6)",
				borderColor: "rgba(75, 192, 192, 1)",
				borderWidth: 1,
			},
			{
				label: "Deleted",
				data: Object.values(stats.deletedByAgent),
				backgroundColor: "rgba(255, 99, 132, 0.6)",
				borderColor: "rgba(255, 99, 132, 1)",
				borderWidth: 1,
			},
		],
	}

	// Options for the bar chart
	const barOptions = {
		responsive: true,
		scales: {
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: "Number of Enquiries",
				},
			},
			x: {
				title: {
					display: true,
					text: "Agents",
				},
			},
		},
		plugins: {
			legend: {
				position: "top",
			},
			title: {
				display: true,
				text: "Agent Performance",
			},
		},
	}

	// Get timeframe display text
	const getTimeframeText = () => {
		if (customRange) {
			return `${moment(startDate).format("MMM D, YYYY")} - ${moment(endDate).format("MMM D, YYYY")}`
		}

		if (yearMonthMode) {
			return `${moment().month(selectedMonth).format("MMMM")} ${selectedYear}`
		}

		const timeframeOptions = {
			week: "Last Week",
			month: "Last Month",
			year: "Last Year",
			custom: "Custom Range",
			year_month: "Select Month",
		}

		return timeframeOptions[timeframe] || "Select timeframe"
	}

	// Handle date changes
	const handleStartDateChange = (milliseconds) => {
		setStartDate(moment(milliseconds).toDate())
	}

	const handleEndDateChange = (milliseconds) => {
		setEndDate(moment(milliseconds).toDate())
	}

	return (
		<Tile fullPage={true}>
			<Title className="flex">
				<h1>HoS Team Analytics</h1>
			</Title>

			<div className="analytics-controls">
				<div className="select-wrapper">
					<label>Select Manager:</label>
					<Select
						placeholder="Choose a manager"
						value={selectedManager ? managers[selectedManager] : ""}
						onSelect={(option) => setSelectedManager(option.option)}
						options={managers}
					/>
				</div>

				<div className="select-wrapper">
					<label>Timeframe:</label>
					<Select
						placeholder="Select timeframe"
						value={getTimeframeText()}
						onSelect={handleTimeframeChange}
						options={{
							year_month: "Select Month",
							week: "Last Week",
							month: "Last Month",
							year: "Last Year",
							custom: "Custom Range",
						}}
					/>
				</div>
			</div>

			{customRange && (
				<div className="date-range-controls">
					<div className="date-picker-wrapper">
						<label>Start Date:</label>
						<Datepicker
							placeholder="Start Date"
							value={moment(startDate).valueOf()}
							onSelect={handleStartDateChange}
						/>
					</div>
					<div className="date-picker-wrapper">
						<label>End Date:</label>
						<Datepicker
							placeholder="End Date"
							value={moment(endDate).valueOf()}
							onSelect={handleEndDateChange}
						/>
					</div>
				</div>
			)}

			{yearMonthMode && (
				<div className="date-range-controls">
					<div className="date-picker-wrapper">
						<label>Year:</label>
						<Select
							placeholder="Select Year"
							value={selectedYear.toString()}
							onSelect={handleYearChange}
							options={Object.fromEntries(
								Array.from({ length: 5 }, (_, i) => {
									const year = moment().year() - i
									return [year.toString(), year.toString()]
								})
							)}
						/>
					</div>
					<div className="date-picker-wrapper">
						<label>Month:</label>
						<Select
							placeholder="Select Month"
							value={moment().month(selectedMonth).format("MMMM")}
							onSelect={handleMonthChange}
							options={Object.fromEntries(Array.from({ length: 12 }, (_, i) => [i.toString(), moment().month(i).format("MMMM")]))}
						/>
					</div>
				</div>
			)}

			{loading ? (
				<div className="loading">Loading analytics data...</div>
			) : selectedManager ? (
				<div className="analytics-content">
					{stats.noAgents ? (
						<div className="no-agents-message">
							<p>This manager currently has no team members assigned.</p>
						</div>
					) : (
						<>
							<div className="stats-summary">
								<div className="stat-card">
									<h3>Total Enquiries</h3>
									<div className="stat-value">{stats.totalOriginalEnquiries}</div>
									<div className="stat-subvalue">({stats.totalEnquiries} de-duped)</div>
								</div>
								<div className="stat-card">
									<h3>Converted to Bookings</h3>
									<div className="stat-value">{stats.convertedEnquiries}</div>
								</div>
								<div className="stat-card">
									<h3>Deleted Enquiries</h3>
									<div className="stat-value">{stats.deletedEnquiries}</div>
								</div>
								<div className="stat-card">
									<h3>Conversion Rate</h3>
									<div className="stat-value">{stats.conversionRate}%</div>
								</div>
								<div className="stat-card">
									<h3>Team Total Margin</h3>
									<div className="stat-value">£{stats.teamTotalMargin.toLocaleString("en-GB", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
									<div className="stat-subvalue">({stats.teamTotalBookings} bookings)</div>
								</div>
							</div>

							{/* <div className="charts-container">
								<div className="chart-wrapper pie-chart">
									<h3>Enquiry Status Distribution</h3>
									<Pie data={conversionPieData} />
								</div>

								<div className="chart-wrapper bar-chart">
									<h3>Agent Performance</h3>
									<Bar
										data={agentPerformanceData}
										options={barOptions}
									/>
								</div>
							</div> */}

							<div className="agent-table-container">
								<h3>Agent Performance Details</h3>
								<Table
									className="enquiries-table"
									headings={["Agent", "Total Enquiries", "Enquiries (De-duped)", "Converted", "Deleted", "Conversion Rate", "Avg. Margin %", "Target"]}>
									{Object.keys(stats.enquiriesByAgent)
										.sort((a, b) => stats.agentNames[a].localeCompare(stats.agentNames[b]))
										.map((agentId) => {
											const totalEnquiries = stats.enquiriesByAgent[agentId]
											const originalEnquiries = stats.originalEnquiriesByAgent[agentId]
											const converted = stats.bookingsByAgent[agentId]
											const deleted = stats.deletedByAgent[agentId]
											const conversionRate = totalEnquiries > 0 ? ((converted / totalEnquiries) * 100).toFixed(2) : "0.00"
											const avgMargin = stats.marginsByAgent && stats.marginsByAgent[agentId] ? stats.marginsByAgent[agentId].average.toFixed(2) : "0.00"
											const targetData = stats.targetsByAgent[agentId]

											return (
												<tr key={agentId}>
													<td>{stats.agentNames[agentId]}</td>
													<td>{originalEnquiries}</td>
													<td>{totalEnquiries}</td>
													<td>{converted}</td>
													<td>{deleted}</td>
													<td>{conversionRate}%</td>
													<td>{avgMargin}%</td>
													<td>
														{!timeframe.match(/^(week|month)$/) && !yearMonthMode ? (
															<small>-</small>
														) : targetData.monthly > 0 ? (
															<div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
																{targetData.achieved ? (
																	<CheckIcon
																		size={19}
																		color="#22b229"
																	/>
																) : (
																	<XIcon
																		size={19}
																		color="#d3423d"
																	/>
																)}
																<div>
																	<span className={`ui-badge ${targetData.percentage >= 100 ? "POSITIVE" : "NEGATIVE"}`}>{targetData.percentage}%</span>
																</div>
															</div>
														) : (
															<small>No target</small>
														)}
													</td>
												</tr>
											)
										})}
								</Table>
							</div>
						</>
					)}
				</div>
			) : (
				<div className="no-selection">
					<p>Please select a manager to view their team's analytics.</p>
				</div>
			)}
		</Tile>
	)
}
