import React, { useState, useEffect } from "react"
import { db } from "../../../utils/firebase"
import moment from "moment"
import ReactDiffViewer from "react-diff-viewer-continued"
import Badge from "../../ui/badge/badge"
import "./diff-log.scss"

// Flatten nested objects with dot notation
const flattenObject = (obj, prefix = "") => {
	// Return empty object if obj is null or undefined
	if (!obj) return {}

	return Object.keys(obj).reduce((acc, k) => {
		const pre = prefix.length ? prefix + "." : ""
		if (
			typeof obj[k] === "object" &&
			obj[k] !== null &&
			!Array.isArray(obj[k]) &&
			!(obj[k] instanceof Date) &&
			// Ignore Firebase Timestamp objects
			!(obj[k]?.seconds !== undefined && obj[k]?.nanoseconds !== undefined)
		) {
			Object.assign(acc, flattenObject(obj[k], pre + k))
		} else {
			// Handle arrays by converting to string
			const value = Array.isArray(obj[k]) ? JSON.stringify(obj[k].sort()) : obj[k]
			acc[pre + k] = value
		}
		return acc
	}, {})
}

// Get only changed values between two objects
const getChangedValues = (oldObj, newObj) => {
	// Handle null/undefined inputs
	const safeOldObj = oldObj || {}
	const safeNewObj = newObj || {}

	const flatOld = flattenObject(safeOldObj)
	const flatNew = flattenObject(safeNewObj)

	const changes = {}

	// Get all unique keys
	const allKeys = [...new Set([...Object.keys(flatOld), ...Object.keys(flatNew)])].sort()

	// Only include keys that have different values
	allKeys.forEach((key) => {
		if (flatOld[key] !== flatNew[key]) {
			changes[key] = {
				old: flatOld[key],
				new: flatNew[key],
			}
		}
	})

	return changes
}

// Format the changes into a readable diff
const formatDiff = (changes) => {
	const oldValues = {}
	const newValues = {}

	Object.entries(changes).forEach(([key, value]) => {
		oldValues[key] = value.old
		newValues[key] = value.new
	})

	return {
		old: JSON.stringify(oldValues, null, 2),
		new: JSON.stringify(newValues, null, 2),
	}
}

export default function DiffLog({ logDetails }) {
	const [userName, setUserName] = useState("")
	const [badgeLabel, setBadgeLabel] = useState("")

	/**
	 * Create a readable date from the seconds timestamp for the log document
	 */
	const log_date = moment(logDetails?.created?.seconds, "X").format("DD/MM/YYYY [at] HH:mm:ss")

	/**
	 * When the log type is updated
	 */
	useEffect(() => {
		/**
		 * First remove the underscores
		 */
		const underscores = logDetails.type.replace(/_/g, " ").toLowerCase()
		/**
		 * Then convert the words to capitals on the first
		 */
		const words = underscores.split(" ")
		/**
		 * Loop through the words
		 */
		for (let i = 0; i < words.length; i++) {
			/**
			 * Covnert the first letter to a capital
			 */
			words[i] = words[i][0].toUpperCase() + words[i].substr(1)
		}
		/**
		 * Then join the array back into a string
		 */
		const joined = words.join(" ")
		/**
		 * And set it into the state
		 */
		setBadgeLabel(joined)
	}, [logDetails.type])

	useEffect(() => {
		if (logDetails.user) {
			db.doc(`users/${logDetails.user}`)
				.get()
				.then((userDoc) => {
					if (userDoc.exists) {
						const { first_name, last_name } = userDoc.data()
						setUserName(`${first_name} ${last_name}`)
					}
				})
		}
	}, [logDetails.user])

	// Get only the changed values if we have diff data
	const changes = logDetails.diff ? getChangedValues(logDetails.diff.before, logDetails.diff.after) : null
	const formattedDiff = changes ? formatDiff(changes) : null

	return (
		<tr>
			<td>
				<Badge
					label={badgeLabel}
					type={"AMBER"}
				/>
			</td>

			<td>{log_date}</td>

			<td>
				{userName && <>{userName}</>}

				{!userName && <small>No user attached</small>}
			</td>

			<td>
				{logDetails.diff && (
					<div>
						<div className="booking__log-item__diff-changes">{logDetails.diff.changes}</div>
						<div className="booking__log-item__diff-changes">
							<small style={{ display: "block", marginTop: "-12px" }}>{logDetails.diff.collection_path}</small>
						</div>
						<div className="booking__log-item__diff-container">
							<ReactDiffViewer
								oldValue={formattedDiff ? formattedDiff.old : JSON.stringify(logDetails.diff.before, null, 2)}
								newValue={formattedDiff ? formattedDiff.new : JSON.stringify(logDetails.diff.after, null, 2)}
								splitView={true}
								hideLineNumbers={true}
								showDiffOnly={true}
								disableWordDiff={true}
								extraLinesSurroundingDiff={0}
								linesOffset={0}
								compareMethod="diffLines"
								styles={{
									variables: {
										light: {
											diffViewerBackground: "transparent",
											diffViewerColor: "inherit",
										},
									},
								}}
							/>
						</div>
					</div>
				)}
			</td>
		</tr>
	)
}
