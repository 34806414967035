import React, { useState, useEffect } from "react"
import { db } from "../../utils/firebase"
import firebase from "firebase"
import moment from "moment"
import "./conversions-breakdown.scss"

// UI imports
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Table from "../../components/structure/table/table"
import Button from "../../components/ui/button/button"
import { LoadingIcon } from "../../utils/svgs"

// Utils
import exportToExcel from "./_utils/export"

// Returns a dashboard for viewing the conversions breakdown by resort
export default function ConversionsBreakdown() {
	const [loading, setLoading] = useState(true)
	const [agents, setAgents] = useState([])
	const [agentResortStats, setAgentResortStats] = useState({})

	// On component load, fetch the agents that are on the leaderboard
	useEffect(() => {
		const fetchAgents = async () => {
			try {
				const agentsSnapshot = await db.collection("users").where("show_for.targets", "==", true).orderBy("first_name", "asc").get()

				const agentsData = []
				agentsSnapshot.forEach((doc) => {
					agentsData.push({
						id: doc.id,
						...doc.data(),
					})
				})

				setAgents(agentsData)
			} catch (error) {
				console.error("Error fetching agents:", error)
			}
		}

		fetchAgents()
	}, [])

	// When agents are loaded, fetch their bookings for the last 12 months
	useEffect(() => {
		if (agents.length === 0) return

		const fetchBookingsData = async () => {
			setLoading(true)

			try {
				// Calculate date range for last 12 months
				const endDate = moment().endOf("day")
				const startDate = moment().subtract(12, "months").startOf("day")

				const startTimestamp = firebase.firestore.Timestamp.fromMillis(startDate.valueOf())
				const endTimestamp = firebase.firestore.Timestamp.fromMillis(endDate.valueOf())

				// Create stats object for each agent
				const agentStats = {}

				// For each agent, fetch their bookings
				for (const agent of agents) {
					// Initialize agent stats
					agentStats[agent.id] = {
						name: `${agent.first_name} ${agent.last_name}`,
						totalBookings: 0,
						resorts: {},
					}

					// Fetch bookings for this agent
					const bookingsSnapshot = await db.collection("bookings").where("agent", "==", agent.id).where("created", ">=", startTimestamp).where("created", "<=", endTimestamp).get()

					// Process each booking
					const bookingPromises = []

					bookingsSnapshot.forEach((bookingDoc) => {
						agentStats[agent.id].totalBookings++

						// Fetch hotel details from the booking's subcollection
						const hotelPromise = db
							.collection(`bookings/${bookingDoc.id}/hotels`)
							.get()
							.then(async (hotelsSnapshot) => {
								// Process each hotel in the booking
								for (const hotelDoc of hotelsSnapshot.docs) {
									const hotelData = hotelDoc.data()

									// Look up the hotel name from the hotels collection
									const hotelRef = await db.collection("hotels").doc(hotelData?.resortID).get()
									if (hotelRef.exists) {
										const hotelRefData = hotelRef.data()

										if (hotelRefData.name) {
											const resortName = hotelRefData.name

											if (!agentStats[agent.id].resorts[resortName]) {
												agentStats[agent.id].resorts[resortName] = {
													bookings: 0,
												}
											}

											agentStats[agent.id].resorts[resortName].bookings++
											continue
										}
									}
								}
							})

						bookingPromises.push(hotelPromise)
					})

					// Wait for all hotel fetches to complete
					await Promise.all(bookingPromises)
				}

				setAgentResortStats(agentStats)
			} catch (error) {
				console.error("Error fetching bookings data:", error)
			} finally {
				setLoading(false)
			}
		}

		fetchBookingsData()
	}, [agents])

	// Prepare data for display and export
	const prepareDataForDisplay = () => {
		const tableData = []

		// Convert the agent stats object to an array for sorting
		Object.entries(agentResortStats).forEach(([agentId, agentData]) => {
			// For each resort the agent has worked with
			Object.entries(agentData.resorts).forEach(([resort, resortData]) => {
				tableData.push({
					agentId,
					agentName: agentData.name,
					resort,
					bookings: resortData.bookings,
				})
			})
		})

		return tableData
	}

	// Handle export to Excel
	const handleExport = () => {
		const data = prepareDataForDisplay().map((item) => ({
			"Agent Name": item.agentName,
			Resort: item.resort,
			Bookings: item.bookings,
		}))

		exportToExcel(data, "Resort_Bookings_Breakdown_Report")
	}

	// Get sorted data
	const sortedData = prepareDataForDisplay()

	return (
		<Tile fullPage={true}>
			<Title className="flex">
				<h1>Resort Bookings Breakdown</h1>
				<div className="title-actions">
					<Button
						label="Export to XLSX"
						onClick={handleExport}
						disabled={loading || Object.keys(agentResortStats).length === 0}
					/>
				</div>
			</Title>

			<Table
				className="enquiries-table"
				headings={["Agent", "Resort", "Bookings"]}
				noResults={sortedData.length === 0}
				noResultsMessage={"No results found"}
				loading={loading}>
				{sortedData.map((item, index) => (
					<tr key={`${item.agentId}-${item.resort}-${index}`}>
						<td>{item.agentName}</td>
						<td>{item.resort}</td>
						<td>{item.bookings}</td>
					</tr>
				))}
			</Table>

			<br />
			{sortedData.length === 0 && (
				<div className="table-loading-splash">
					<div className="loading-wrapper">
						<p>Loading...</p>
						<div className="svg-loading-wrap">
							<LoadingIcon />
						</div>
					</div>
				</div>
			)}
		</Tile>
	)
}
