import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { db } from "../../utils/firebase"
import moment from "moment"

// UI components
import Badge from "../ui/badge/badge"

// Functional component to return the booking
export default function Booking(props) {
	const [client, setClient] = useState({})
	const [agent, setAgent] = useState({})
	const [commissionPaid, setCommissionPaid] = useState(0)

	const [discepencyAmount, setDiscepencyAmount] = useState(0)
	const [hasCommsLogs, setHasCommsLogs] = useState(false)
	const [isOwed, setIsOwed] = useState(false)
	const [doesOwe, setDoesOwe] = useState(false)

	// Deconstruct the details from the props
	const { id, details } = props

	// Get the margin values from the sales margins
	const { agents_margin } = details?.margins || {}

	// Generate a string to represent the check_in date from the timestamps
	const checkInDate = moment(details.check_in?.seconds, "X").format("MMM D[,] YYYY")
	const bookedDate = moment(details.booked?.seconds, "X").format("MMM D[,] YYYY")

	// On component load
	useEffect(() => {
		db.doc(`clients/${details.client}`)
			.get()
			.then((clientDoc) => {
				setClient(clientDoc.data())
			})

		db.doc(`users/${details.agent}`)
			.get()
			.then((agentDoc) => {
				setAgent(agentDoc.data())
			})

		db.collection(`bookings/${id}/commission_logs`)
			.get()
			.then((commissionDocs) => {
				let totalCommissionPaid = 0
				commissionDocs.forEach((commissionDoc) => {
					if (commissionDoc.data().amount) {
						totalCommissionPaid += commissionDoc.data().amount
					}
				})
				setCommissionPaid(totalCommissionPaid)

				const hasLogs = commissionDocs.size > 0
				setHasCommsLogs(hasLogs)

				setIsOwed(hasLogs && totalCommissionPaid < agents_margin)
				setDoesOwe(hasLogs && totalCommissionPaid > agents_margin)
				setDiscepencyAmount((agents_margin - totalCommissionPaid).toFixed(2))
			})
	}, [])

	return (
		<tr>
			<td>
				{details.missing_values && <div className="no-values-tag"></div>}

				{bookedDate}
			</td>
			<td>{details?.reference}</td>
			<td>
				{agent?.first_name} {agent?.last_name}
			</td>
			<td>
				{client.first_name && `${client.first_name} ${client.last_name}`}
				{!client.first_name && <small className="no-value">No name set</small>}
			</td>
			<td>{checkInDate}</td>
			<td>£{details?.paid_by_client?.toLocaleString() || 0}</td>
			<td>£{agents_margin?.toLocaleString()}</td>
			<td>£{commissionPaid?.toLocaleString()}</td>
			<td>£{hasCommsLogs ? discepencyAmount : agents_margin?.toLocaleString()}</td>
			<td>
				{agents_margin > 0 && commissionPaid === 0 && (
					<Badge
						label="Missing payment"
						type="AMBER"
					/>
				)}

				{discepencyAmount !== "0.00" && (doesOwe || isOwed) && (
					<Badge
						label="Discrepancy"
						type="NEGATIVE"
					/>
				)}
			</td>
			<td className="is-button">
				<NavLink to={`booking/${id}`}>View booking</NavLink>
				<a
					target="_blank"
					rel="noreferrer"
					href={`booking/${id}`}>
					View in new tab
				</a>
			</td>
		</tr>
	)
}
