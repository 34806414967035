import React, { useState, useEffect, useContext } from "react"
import { sendHotelAndRoundsReservation } from "../../../utils/reservations"
import { BookingContext } from "../../../utils/providers/booking"
import { ModalContext } from "../../../utils/providers/modal"
import { AlertsContext } from "../../../utils/providers/alerts"
import { db, auth, arrayRemove } from "../../../utils/firebase"
import { ROOM_TYPES } from "../../../utils/exports/room-types"
import { BOARD_TYPES } from "../../../utils/exports/board-types"
import firebase from "firebase"
import moment from "moment"

/**
 * UI components
 */
import Input from "../../ui/inputs/input"
import SearchSelect from "../../ui/select/search-select"
import Datepicker from "../../ui/datepicker/datepicker"
import Textarea from "../../ui/inputs/textarea"
import Badge from "../../ui/badge/badge"
import Button from "../../ui/button/button"
import Select from "../../ui/select/select"
import Room from "./room"
import Window from "../../structure/window/window"
import WindowCard from "../../ui/window-card/window-card"
import { SearchIcon } from "../../../utils/svgs"
import { updateBookingMargins } from "../../../utils/methods"

/**
 * Functional component to return the round component
 */
function Hotel(props) {
	const [saving, setSaving] = useState(false)
	const [removing, setRemoving] = useState(false)
	const [sending, setSending] = useState(false)
	const [addingRoom, setAddingRoom] = useState(false)

	const [addingRooms, setAddingRooms] = useState(false)
	const [addingMultipleRooms, setAddingMultipleRooms] = useState(false)
	const [multipleRoomType, setMultipleRoomType] = useState({})
	const [multipleRoomsCount, setMultipleRoomsCount] = useState(0)

	const [resort, setResort] = useState({})
	const [resortID, setResortID] = useState("")
	const [rooms, setRooms] = useState([])
	const [nights, setNights] = useState(0)
	const [totalCost, setTotalCost] = useState(0)
	const [checkInDate, setCheckInDate] = useState(0)
	const [boardType, setBoardType] = useState("")
	const [commentsForResort, setCommentsForResort] = useState("")
	const [hotelBookingStatus, setHotelBookingStatus] = useState("")
	const [hotelBookingStatusComments, setHotelBookingStatusComments] = useState("")

	const [readyToSave, setReadyToSave] = useState(false)

	/**
	 * Deconstruct the booking & round IDs from the props
	 */
	const { bookingID, hotelID, index } = props

	/**
	 * Pull the show modal function from the modal context
	 */
	const { showModal } = useContext(ModalContext)

	/**
	 * Use the alert context to show an alert when price line has been saved
	 */
	const { pushAlert } = useContext(AlertsContext)

	/**
	 * Deconstruct data from the booking context
	 */
	const { hotels, marginsLocked } = useContext(BookingContext)

	/**
	 * On component load
	 */
	useEffect(() => {
		/**
		 * Get the hotel document from the database
		 */
		db.doc(`bookings/${bookingID}/hotels/${hotelID}`)
			.get()
			.then((hotelDoc) => {
				/**
				 * Deconstruct the data from the document
				 */
				const { resortID, check_in, nights, board_type, total_cost, rooms, comments, booking_status, booking_status_comments } = hotelDoc.data()
				/**
				 * Generate a date from the
				 */
				const checkInMillis = moment(check_in?.seconds, "X").valueOf()
				/**
				 * Update the state with these details
				 */
				setCommentsForResort(comments || "")
				setResortID(resortID || "")
				setCheckInDate(checkInMillis || 0)
				setRooms(rooms || [])
				setNights(nights || 0)
				setBoardType({ option: board_type || "" })
				setTotalCost(total_cost || 0)
				setHotelBookingStatus(booking_status || "")
				setHotelBookingStatusComments(booking_status_comments || "")
				/**
				 * Wait a second and then allow the script to start tracking changes
				 */
				setTimeout(() => {
					setReadyToSave(true)
				}, 1000)
			})
	}, [])

	/**
	 * When the hotel ID is updated in the state
	 */
	useEffect(() => {
		/**
		 * Fetch the hotel from the database
		 */
		resortID &&
			db
				.doc(`hotels/${resortID}`)
				.get()
				.then((hotelDoc) => {
					/**
					 * Decosntruct the hotel data
					 */
					const { name, email } = hotelDoc.data()
					/**
					 * Set the data into the state
					 */
					setResort({ id: hotelDoc.id, name, email })
				})
	}, [resortID])

	/**
	 * When either the checkInDate or resort ID is updated
	 */
	useEffect(() => {
		/**
		 * Save the hotel details
		 */
		readyToSave && saveHotelDetails()
	}, [resortID, checkInDate, boardType, rooms])

	/**
	 * Save the round details into the database
	 */
	const saveHotelDetails = async () => {
		/**
		 * Show the state as saving
		 */
		setSaving(true)
		/**
		 * Make sure there is a resort ID and check in date are present
		 */
		if (resortID) {
			if (marginsLocked) {
				await db.doc(`bookings/${bookingID}/hotels/${hotelID}`).set(
					{
						resortID,
						rooms,
						nights: Number(nights),
						comments: commentsForResort,
						check_in: firebase.firestore.Timestamp.fromMillis(checkInDate),
						board_type: boardType?.option,
						last_modified_by: auth.currentUser.uid,
					},
					{ merge: true }
				)

				pushAlert({
					type: "SUCCESS",
					title: "Hotel Updated",
					body: "Hotel details updated, minus any changes to the cost",
				})
			} else {
				await db.doc(`bookings/${bookingID}/hotels/${hotelID}`).set(
					{
						resortID,
						rooms,
						nights: Number(nights),
						total_cost: Number.parseFloat(totalCost),
						comments: commentsForResort,
						check_in: firebase.firestore.Timestamp.fromMillis(checkInDate),
						board_type: boardType?.option,
						last_modified_by: auth.currentUser.uid,
					},
					{ merge: true }
				)

				await db.doc(`bookings/${bookingID}`).set(
					{
						hotels_costs: {
							[hotelID]: Number.parseFloat(totalCost),
						},
					},
					{ merge: true }
				)

				await updateBookingPricing()
			}
		}
		/**
		 * Reset the state
		 */
		setSaving(false)
	}

	/**
	 * Update the pricing for the booking as per the hotel prices
	 */
	const updateBookingPricing = async () => {
		/**
		 * Firstly pull the booking details
		 */
		const bookingDetails = await db
			.doc(`bookings/${bookingID}`)
			.get()
			.then((bookingDoc) => {
				/**
				 * Return the booking in full
				 */
				return { ...bookingDoc.data() }
			})
		/**
		 * Pull the hotel costs from the booking document
		 */
		const { hotels_costs } = bookingDetails
		/**
		 * Create a running total of all hotel prices
		 */
		let runningTotal = 0
		/**
		 * Loop through each of the hotel prices on the booking
		 */
		Object.values(hotels_costs).forEach((hotelCost) => {
			/**
			 * Add it to the running total
			 */
			runningTotal += hotelCost
		})
		/**
		 * Get the hotel costs to the nearest 2 decimal places
		 */
		const totalHotelsCost = Number.parseFloat(runningTotal.toFixed(2))
		/**
		 * Push the new global status onto the booking document
		 */
		await db.doc(`bookings/${bookingID}`).set(
			{
				margins: {
					hotels_cost: totalHotelsCost,
				},
			},
			{ merge: true }
		)
		/**
		 * Show an alert to say it's been saved
		 */
		pushAlert({
			type: "SUCCESS",
			title: "Hotel Updated",
			body: "Hotel updated on the booking and associated pricing has been matched up",
		})
		/**
		 * Then update the booking margins
		 */
		return await updateBookingMargins(bookingID)
	}

	/**
	 * Check before removing the hotel from the enquiry
	 */
	const checkBeforeRemoval = () => {
		/**
		 * Display the modal to check before a hotel removal
		 */
		showModal({
			type: "ALERT",
			title: "Are you sure?",
			body: "Are you sure you'd like to remove this hotel from the booking?",
			cancel: {
				label: "Cancel",
				action: () => {
					return null
				},
			},
			next: {
				label: "Yes, i'm sure",
				action: () => removeHotelFromBooking(),
			},
		})
	}

	/**
	 * Remove the hotel from the booking
	 */
	const removeHotelFromBooking = async () => {
		/**
		 * Show the state as removing
		 */
		setRemoving(true)
		/**
		 * Remove the hotel from the order list for the booking
		 */
		await db.doc(`bookings/${bookingID}`).set(
			{
				hotels_order: arrayRemove(hotelID),
				hotels_costs: {
					[hotelID]: firebase.firestore.FieldValue.delete(),
				},
				suppliers_hotels: arrayRemove(resortID),
			},
			{ merge: true }
		)
		/**
		 * Then remove the hotel document itself
		 */
		await db.doc(`bookings/${bookingID}/hotels/${hotelID}`).delete()
		/**
		 * Then update the booking margins
		 */
		await updateBookingPricing()
		/**
		 * Show an alert to say it's been saved
		 */
		pushAlert({
			type: "SUCCESS",
			title: "Hotel Removed",
			body: "Hotel has been removed and the booking pricing details have been updated",
		})
		/**
		 * Reset the state
		 */
		setRemoving(false)
	}

	/**
	 * Add a room to the hotel booking
	 */
	const addRoomToHotel = async () => {
		/**
		 * show a spinner for adding the room
		 */
		setAddingRoom(true)
		/**
		 * Push a new room into the hotel booking
		 */
		setRooms((rooms) => [
			...rooms,
			{
				room_type: "",
			},
		])
		/**
		 * Reset the state
		 */
		setAddingRoom(false)
	}

	/**
	 * Update the room details for the hotel in the booking
	 */
	const updateRoomOnHotel = async (index, details) => {
		/**
		 * Create a copy of the rooms array
		 */
		let roomsCopy = [...rooms]
		/**
		 * Update the index for the rooms witht he new details
		 */
		roomsCopy[index] = details
		/**
		 * Update the rooms array in the state
		 */
		setRooms(roomsCopy)
	}

	/**
	 * Remove the room from the hotel booking via the index passed up from the room component
	 */
	const removeRoomFromHotel = async (index) => {
		/**
		 * Create a copy of the rooms array
		 */
		let roomsCopy = [...rooms]
		/**
		 * Remove the room from the array
		 */
		roomsCopy.splice(index, 1)
		/**
		 * Update the rooms array in the state
		 */
		setRooms(roomsCopy)
	}

	/**
	 * Send the hotel booking off to the resort
	 */
	const sendHotelBooking = async () => {
		/**
		 * Set the state to show the reservation is sending
		 */
		setSending(true)
		/**
		 * Build the email for the hotel by passing in a booking ID and hotel ID.
		 *
		 * The function will check through the rounds associated with this booking and detemrine if any
		 * of them have the same reservation address. If they do, the round is also added into the same
		 * reservation email.
		 */
		await sendHotelAndRoundsReservation(bookingID, hotelID)
		/**
		 * Reset the state
		 */
		setSending(false)
	}

	/**
	 * Add multiple rooms to the hotel booking
	 */
	const addMultipleRooms = async () => {
		/**
		 * Show a loading spinner
		 */
		setAddingRooms(false)
		/**
		 * Get the quantity to add from the state
		 */
		const quantity = Number(multipleRoomsCount)
		/**
		 * Setup a new object to store the
		 */
		let newRooms = []
		/**
		 * Loop through until we match the quanitity required
		 */
		for (let i = 0; i < quantity; i++) {
			newRooms = [
				...newRooms,
				{
					room_type: multipleRoomType?.option,
				},
			]
		}
		/**
		 * Set the rooms into the state
		 */
		setRooms((rooms) => [...rooms, ...newRooms])
		/**
		 * Reset the state
		 */
		setAddingRooms(false)
		setAddingMultipleRooms(false)
	}

	return (
		<>
			<table className="booking-table">
				<tbody>
					<tr>
						<td colSpan="2">
							<div className="table-room-lower-border" />
						</td>
					</tr>
					<tr className="table-sub-heading">
						<td>Hotel #{index + 1}</td>
						<td>
							<Button
								badge={true}
								loading={sending}
								loadingText="Sending..."
								label="Send hotel booking"
								onClick={() => sendHotelBooking()}
							/>
							<Button
								badge={true}
								label="Remove"
								className="OUTLINE"
								loading={removing}
								loadingText="Removing..."
								onClick={() => checkBeforeRemoval()}
							/>
						</td>
					</tr>
					<tr>
						<td>Hotel</td>
						<td>
							<SearchSelect
								placeholder="Change hotel:"
								icon={<SearchIcon />}
								activeOnHover={true}
								onSelect={(option) => setResortID(option.option)}
								options={hotels}
							/>
						</td>
					</tr>
					<tr>
						<td>Hotel name</td>
						<td>
							<Input
								type="text"
								value={resort?.name}
								placeholder="No hotel chosen yet"
								readOnly={true}
							/>
						</td>
					</tr>
					<tr>
						<td>Hotel email</td>
						<td>
							<Input
								type="text"
								value={resort?.email}
								placeholder="No hotel chosen yet"
								readOnly={true}
							/>
						</td>
					</tr>
					<tr>
						<td>Check in date</td>
						<td>
							<Datepicker
								placeholder="Choose date:"
								value={checkInDate}
								onSelect={(date) => setCheckInDate(date)}
								activeOnHover={true}
							/>
						</td>
					</tr>
					<tr>
						<td>Board type</td>
						<td>
							<Select
								placeholder="Choose board type:"
								value={boardType?.value}
								selected={boardType?.option}
								activeOnHover={true}
								onSelect={(option) => setBoardType(option)}
								options={BOARD_TYPES}
							/>
						</td>
					</tr>
					<tr>
						<td>Number of nights</td>
						<td>
							<Input
								type="text"
								value={nights}
								onChange={setNights}
								placeholder="Number of nights:"
								activeOnHover={true}
								onBlur={() => saveHotelDetails()}
							/>
						</td>
					</tr>
					<tr>
						<td>Hotel cost</td>
						<td>
							<Input
								type="text"
								value={totalCost}
								onChange={setTotalCost}
								placeholder="Total cost:"
								symbol="£"
								activeOnHover={true}
								onBlur={() => saveHotelDetails()}
								readOnly={marginsLocked}
							/>
						</td>
					</tr>
					<tr className="table-sub-heading smaller">
						<td>Rooms</td>
						<td>
							<Button
								badge={true}
								label="Add multiple rooms"
								onClick={() => setAddingMultipleRooms(true)}
							/>

							<Button
								badge={true}
								label="Add room"
								loading={addingRoom}
								loadingText="Adding..."
								onClick={() => addRoomToHotel()}
							/>
						</td>
					</tr>

					{/* Loop through all the rooms requested */}
					{rooms.map((room, index) => (
						<Room
							key={index}
							index={index}
							room={room}
							update={(index, details) => updateRoomOnHotel(index, details)}
							remove={(index) => removeRoomFromHotel(index)}
						/>
					))}

					<tr>
						<td>Comments for hotel</td>
						<td>
							<Textarea
								type="text"
								value={commentsForResort}
								onChange={setCommentsForResort}
								placeholder="Comments to send to hotel:"
								activeOnHover={true}
								onBlur={() => saveHotelDetails()}
							/>
						</td>
					</tr>
					<tr>
						<td>Hotel booking status</td>
						<td className="vertical-center">
							{/* Booking has not yet been sent to the partner */}
							{!hotelBookingStatus && <Badge label="Booking not sent yet" />}

							{/* Booking has been sent but no response yet */}
							{hotelBookingStatus === "sent" && (
								<Badge
									type="INFO"
									label="Booking sent"
								/>
							)}

							{/* Booking has been approved */}
							{hotelBookingStatus === "approved" && (
								<Badge
									type="POSITIVE"
									label="Booking accepted"
								/>
							)}

							{/* Booking has been rejected */}
							{hotelBookingStatus === "rejected" && (
								<Badge
									type="NEGATIVE"
									label="Booking rejected"
								/>
							)}
						</td>
					</tr>

					{/* Are there any comments on the reservation? */}
					{hotelBookingStatusComments && (
						<tr>
							<td>Partner comments</td>
							<td className="text-block">
								<p>{hotelBookingStatusComments}</p>
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{addingMultipleRooms && (
				<Window
					title="Add multiple rooms"
					className="slim"
					close={() => setAddingMultipleRooms(false)}>
					<WindowCard>
						<div className="input-grid cols-1">
							<Select
								placeholder="Choose room type:"
								value={multipleRoomType?.value}
								selected={multipleRoomType?.option}
								activeOnHover={true}
								onSelect={(option) => setMultipleRoomType(option)}
								options={ROOM_TYPES}
							/>

							<Input
								type="number"
								value={multipleRoomsCount}
								onChange={setMultipleRoomsCount}
								placeholder="Quantity:"
								activeOnHover={true}
							/>

							<Button
								label="Add rooms"
								loading={addingRooms}
								loadingText="Adding..."
								disabled={!multipleRoomType?.option || multipleRoomsCount <= 0}
								onClick={() => addMultipleRooms()}
							/>
						</div>
					</WindowCard>
				</Window>
			)}
		</>
	)
}

export default Hotel
