import { CALENDAR_MONTHS } from "../../utils/exports/months"
import { db, auth, storage } from "../../utils/firebase"
import { useState, useContext } from "react"
import { AlertsContext } from "../../utils/providers/alerts"
import firebase from "firebase"
import moment from "moment"
import "./commission-settlement.scss"

// UI components
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Select from "../../components/ui/select/select"
import Button from "../../components/ui/button/button"
import Table from "../../components/structure/table/table"
import Booking from "../../components/commission-settlement/booking"
import exportToExcel from "./_utils/export"

// Functional component to return the dashboard for settling commission
export default function CommissionSettlement() {
	const [loading, setLoading] = useState(false)
	const [processing, setProcessing] = useState(false)
	const [settling, setSettling] = useState(false)
	const [uploadProgress, setUploadProgress] = useState(0)
	const [bookings, setBookings] = useState([])
	const [staffCommission, setStaffCommission] = useState({})

	const [exportArr, setExportArr] = useState([])
	const [exportTitle, setExportTitle] = useState("")

	const [month, setMonth] = useState("")
	const [year, setYear] = useState("")
	const [type, setType] = useState("")

	const { pushAlert } = useContext(AlertsContext)

	// Fetch the bookings in the chosen period
	const fetchCommissionBookings = async () => {
		try {
			setLoading(true)
			setExportArr([])
			setExportTitle("")

			if (!month || !year || !type) {
				return
			}

			// Get the milliseconds timestamps from the chosen filters
			const fetchStart = moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf()
			const fetchEnd = moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf()

			// Build some firestore timestamps from the milliseconds
			const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart)
			const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd)

			// Setup a variable to store the field we are going to be searching the database with
			let searchBy = type === "travel_date" ? "check_in" : "booked"

			// Setup some storage for all the booking info we'll get from firestore
			const bookingsArr = []
			const commissionByStaff = {}

			// Start a firestore query against the bookings collection
			const bookingDocs = await db.collection("bookings").where(searchBy, ">=", startTimestamp).where(searchBy, "<=", endTimestamp).get()

			// Loop through all the booking docs
			bookingDocs.forEach((bookingDoc) => {
				// Deconstruct some balances from the booking document
				const { margins, golfers, non_golfers, paid_by_client } = bookingDoc.data()

				// Pull the margins from the booking
				const { customer_price, supplier_invoices, agents_margin } = margins || {}

				// Calculate the required deposit amount
				const groupSize = (golfers || 0) + (non_golfers || 0)
				const requiredDeposit = groupSize * 50

				// Check to make sure there is a customer price, supplier invoice figures, agent margin, and full deposit paid
				if (margins && customer_price > 0 && supplier_invoices > 0 && agents_margin > 0 && paid_by_client >= requiredDeposit) {
					// Push the booking data into the array
					bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data() })

					// Add the booking to the commission by staff object
					if (!commissionByStaff[bookingDoc.data().agent]) {
						commissionByStaff[bookingDoc.data().agent] = {
							total: 0,
							bookings: [],
						}
					}

					// Add the booking to the commission by staff object
					commissionByStaff[bookingDoc.data().agent].total += agents_margin
					commissionByStaff[bookingDoc.data().agent].bookings.push(bookingDoc.id)
				}
			})

			// Reset states with new data
			setBookings(bookingsArr)
			setStaffCommission(commissionByStaff)

			// Prepare export data
			prepareExportData(commissionByStaff, `${month} ${year}`)
		} catch (error) {
			console.error("Error fetching commission bookings:", error)
			setBookings([])
			setStaffCommission({})
			setExportArr([])
			setExportTitle("")
		} finally {
			setLoading(false)
		}
	}

	// Prepare data for export
	const prepareExportData = async (commissionData, period) => {
		const staffIds = Object.keys(commissionData)
		const exportData = []

		try {
			// Get all staff user documents in one batch
			const staffDocs = await db.collection("users").where("is_staff", "==", true).get()
			const staffMap = {}
			staffDocs.forEach((doc) => {
				staffMap[doc.id] = {
					first_name: doc.data().first_name || "",
					last_name: doc.data().last_name || "",
					email: doc.data().email,
				}
			})

			// Collect all booking IDs across all staff
			const allBookingIds = staffIds.reduce((acc, staffId) => {
				return [...acc, ...commissionData[staffId].bookings]
			}, [])

			// Fetch all bookings in one batch
			const bookingRefs = allBookingIds.map((id) => db.doc(`bookings/${id}`))
			const bookingDocs = await Promise.all(bookingRefs.map((ref) => ref.get()))

			// Create a map of booking data
			const bookingMap = {}
			bookingDocs.forEach((doc) => {
				if (doc.exists) {
					bookingMap[doc.id] = doc.data()
				}
			})

			// Fetch all commission logs in parallel
			const commissionLogsPromises = allBookingIds.map((bookingId) => db.collection(`bookings/${bookingId}/commission_logs`).get())
			const commissionLogResults = await Promise.all(commissionLogsPromises)

			// Create a map of commission paid amounts
			const commissionPaidMap = {}
			commissionLogResults.forEach((logs, index) => {
				const bookingId = allBookingIds[index]
				commissionPaidMap[bookingId] = logs.docs.reduce((sum, log) => sum + (log.data().amount || 0), 0)
			})

			// Process each staff member's data
			for (const staffId of staffIds) {
				const staffData = staffMap[staffId] || {}
				const staffName = staffData.first_name && staffData.last_name ? `${staffData.first_name} ${staffData.last_name}` : "Unknown"

				const staffBookings = commissionData[staffId].bookings
					.map((bookingId) => {
						const bookingData = bookingMap[bookingId]
						if (!bookingData) return null

						return {
							reference: bookingData.reference,
							check_in: bookingData.check_in,
							commission_amount: bookingData.margins?.agents_margin || 0,
							commission_paid: commissionPaidMap[bookingId] || 0,
						}
					})
					.filter(Boolean)

				const totalCommissionPaid = staffBookings.reduce((sum, b) => sum + b.commission_paid, 0)

				exportData.push({
					"Staff Name": staffName,
					"Staff Email": staffData.email || "Unknown",
					"Commission Amount": `£${commissionData[staffId].total.toFixed(2)}`,
					"Commission Paid": `£${totalCommissionPaid.toFixed(2)}`,
					"Number of Bookings": commissionData[staffId].bookings.length,
					bookings: staffBookings,
				})
			}

			// Set the export data and title
			setExportArr(exportData)
			const formattedMonth = moment(`${month}-${year}`, "MMMM-YYYY").format("MMM")
			const formattedDateTime = moment().format("YYYY_MM_DD_HH_mm")
			setExportTitle(`${year}_${formattedMonth}_Commission_${formattedDateTime}`)
		} catch (error) {
			console.error("Error preparing export data:", error)
			setExportArr([])
			setExportTitle("")
		}
	}

	// Handle settling of commission (split payment)
	const settleCommission = async (isFullPayment = false) => {
		// If there are no bookings, return
		if (bookings.length === 0) return

		// Set the processing state
		setSettling(true)

		try {
			// First export the spreadsheet of commission owed
			exportToExcel(exportArr, exportTitle)

			// Then add commission payment to each booking
			for (const booking of bookings) {
				const { id, margins } = booking
				const { agents_margin } = margins || {}

				// Get existing commission payments
				const commissionLogs = await db.collection(`bookings/${id}/commission_logs`).get()
				const totalPaid = commissionLogs.docs.reduce((sum, log) => sum + (log.data().amount || 0), 0)

				// Calculate payment amount based on existing payments and current commission
				let paymentAmount = 0
				let paymentType = ""

				if (isFullPayment) {
					// For full payment, pay the entire remaining amount
					paymentAmount = agents_margin - totalPaid
					paymentType = "full_payment"
				} else {
					if (totalPaid === 0) {
						// No payment yet - pay half of current commission
						paymentAmount = agents_margin / 2
						paymentType = "booking_month"
					} else {
						// Calculate remaining amount based on current commission
						const remainingAmount = agents_margin - totalPaid
						if (remainingAmount > 0) {
							paymentAmount = remainingAmount
							paymentType = "travel_month"
						}
					}
				}

				// Only proceed if we have a payment to make
				if (paymentAmount > 0) {
					// Add the commission payment record
					await db.collection(`bookings/${id}/commission_logs`).add({
						amount: paymentAmount,
						created: firebase.firestore.FieldValue.serverTimestamp(),
						paid_by: auth.currentUser.uid,
						payment_type: paymentType,
						original_commission: agents_margin,
						total_paid_to_date: totalPaid + paymentAmount,
					})
				}
			}

			// Refresh the data
			await fetchCommissionBookings()

			pushAlert({
				type: "SUCCESS",
				title: "Commission Settled",
				body: `Commission payments have been processed successfully (${isFullPayment ? "Full Payment" : "Split Payment"})`,
			})
		} catch (error) {
			console.error("Error settling commission:", error)
			pushAlert({
				type: "ERROR",
				title: "Settlement Failed",
				body: "There was an error processing the commission payments",
			})
		} finally {
			setSettling(false)
		}
	}

	// Handle the upload of the XLSX file to Firebase Storage
	const handleXLSXUpload = async (xlsxBlob) => {
		const formattedMonth = moment(`${year}-${month}-01`).format("MMM")
		const formattedDateTime = moment().format("YYYY_MM_DD_HH_mm")
		const fileName = `${year}_${formattedMonth}_Commission_${formattedDateTime}`

		const meta = {
			contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			customMetadata: {
				uploadedBy: auth.currentUser.uid,
				month,
				year,
				type,
			},
		}

		// Upload the file to Firebase Storage
		const upload = storage.ref(`exports/commission_reports/${year}/${month}/${fileName}.xlsx`).put(xlsxBlob, meta)

		// Return a promise that resolves when the upload is complete
		return new Promise((resolve, reject) => {
			upload.on(
				"state_changed",
				(snapshot) => {
					const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
					setUploadProgress(progress)
				},
				(error) => {
					console.error("Upload error:", error)
					reject(error)
				},
				async () => {
					const url = await upload.snapshot.ref.getDownloadURL()
					await db.collection("commission_reports").add({
						created: firebase.firestore.FieldValue.serverTimestamp(),
						month,
						year,
						type,
						file_url: url,
						uploaded_by: auth.currentUser.uid,
						filename: `${fileName}.xlsx`,
					})
					resolve(url)
				}
			)
		})
	}

	return (
		<Tile fullPage={true}>
			<Title className="flex has-select-field">
				<h1>Commission Settlement</h1>
			</Title>

			<div
				className="page-filters extra-stats"
				style={{ gridTemplateColumns: "225px 145px 180px auto auto" }}>
				<Select
					label="Month:"
					placeholder="Month:"
					value={month}
					activeOnHover={true}
					onSelect={(option) => setMonth(option.option)}
					options={CALENDAR_MONTHS}
				/>

				<Select
					label="Year:"
					placeholder="Year:"
					value={year}
					activeOnHover={true}
					onSelect={(option) => setYear(option.option)}
					options={{
						2019: "2019",
						2020: "2020",
						2021: "2021",
						2022: "2022",
						2023: "2023",
						2024: "2024",
						2025: "2025",
						2026: "2026",
						2027: "2027",
						2028: "2028",
					}}
				/>

				<Select
					label="Search by:"
					placeholder="Search by:"
					value={type}
					selected={type}
					activeOnHover={true}
					onSelect={(option) => setType(option.option)}
					options={{
						travel_date: "Travel date",
						booked_date: "Booked date",
					}}
				/>

				<div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
					<Button
						filters={true}
						disabled={((!month || !year) && (!month || !year) && !year) || !type}
						label="Fetch stats"
						loadingText="Fetching stats..."
						loading={loading}
						onClick={() => fetchCommissionBookings()}
					/>

					<div style={{ position: "relative" }}>
						<Button
							filters={true}
							disabled={exportArr.length === 0}
							label="Export to XLSX"
							loadingText={uploadProgress ? `Uploading ${uploadProgress}%...` : "Exporting..."}
							loading={processing}
							onClick={() => {
								setProcessing(true)
								exportToExcel(exportArr, exportTitle)
									.then((blob) => handleXLSXUpload(blob))
									.then(() => {
										pushAlert({
											type: "SUCCESS",
											title: "Commission Report Exported",
											body: "The report has been exported and saved to storage",
										})
									})
									.catch((error) => {
										console.error("Export error:", error)
										pushAlert({
											type: "ERROR",
											title: "Export Failed",
											body: "There was an error exporting the commission report",
										})
									})
									.finally(() => {
										setProcessing(false)
										setUploadProgress(0)
									})
							}}
						/>
					</div>

					<div style={{ display: "flex", gap: "10px" }}>
						<Button
							filters={true}
							disabled={bookings.length === 0}
							label="Settle 2.5% (Split)"
							loadingText="Processing..."
							loading={settling}
							onClick={() => settleCommission(false)}
						/>
						<Button
							filters={true}
							disabled={bookings.length === 0}
							label="Settle 5% (Full)"
							loadingText="Processing..."
							loading={settling}
							onClick={() => settleCommission(true)}
						/>
					</div>
				</div>
			</div>

			{/* Bookings Section */}
			{bookings.length > 0 && (
				<Table
					className="bookings-table"
					headings={["Booked", "Booking ref", "Agent", "Client", "Check-in", "Paid by client", "Agent's margin", "Commission paid", "Commission owed", ""]}>
					{bookings.map((booking) => (
						<Booking
							key={booking.id}
							id={booking.id}
							details={booking}
						/>
					))}
				</Table>
			)}
		</Tile>
	)
}
