import React, { useContext, useEffect, useState } from "react"
import { BookingContext } from "../../../utils/providers/booking"
import { AuthContext } from "../../../utils/providers/auth"
import { db } from "../../../utils/firebase"

/**
 * UI components
 */
import Table from "../../../components/structure/table/table"
import Log from "../../../components/booking/logs/log"
import DiffLog from "../../../components/booking/logs/diff-log"
import Loading from "../../../components/app/loading/loading"

/**
 * Functional component to return the booking logs panel
 */
export default function Logs() {
	const [logs, setLogs] = useState([])
	const [loading, setLoading] = useState(true)
	const { bookingID } = useContext(BookingContext)
	const { user } = useContext(AuthContext)

	/**
	 * On component load
	 */
	useEffect(() => {
		/**
		 * Setup a listener on the database to stream the logs down for this booking
		 */
		const unsubscribe = db
			.collection(`bookings/${bookingID}/logs`)
			.orderBy("created", "desc")
			.onSnapshot((logDocs) => {
				/**
				 * Loop over the logs
				 */
				logDocs.docChanges().forEach((change) => {
					/**
					 * Log added
					 */
					if (change.type === "added") {
						setLogs((logs) => [
							...logs,
							{
								id: change.doc.id,
								...change.doc.data(),
							},
						])
					}
					/**
					 * Log updated
					 */
					if (change.type === "modified") {
						setLogs((logs) => {
							let updatedLogs = [...logs]
							for (let i in logs) {
								if (logs[i].id === change.doc.id) {
									updatedLogs[i] = {
										id: change.doc.id,
										...change.doc.data(),
									}
									break
								}
							}
							return updatedLogs
						})
					}
					/**
					 * Log removed
					 */
					if (change.type === "removed") {
						setLogs((logs) => logs.filter((log) => log.id !== change.doc.id))
					}
				})
				setLoading(false)
			})
		/**
		 * Remove the listener when the component unloads
		 */
		return () => unsubscribe()
	}, [bookingID])

	if (loading) {
		return (
			<div className="booking__logs-container">
				<Loading />
			</div>
		)
	}

	return (
		<div className="booking__logs-container">
			<Table
				className="logs-table"
				headings={["Event", "When", "User", "Message"]}>
				{logs.map((log) => {
					if (log.diff && user?.is_admin) {
						return (
							<DiffLog
								key={log.id}
								logDetails={log}
							/>
						)
					} else {
						return (
							<Log
								key={log.id}
								details={log}
								maskDetails={user?.mask_details}
							/>
						)
					}
				})}
			</Table>
		</div>
	)
}
