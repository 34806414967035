import { useState, useEffect, useContext } from "react"
import { AlertsContext } from "../../../../../utils/providers/alerts"
import { db } from "../../../../../utils/firebase"
import "./group-name.scss"

// UI components
import Checkbox from "../../../../../components/ui/checkbox/checkbox"
import Button from "../../../../../components/ui/button/button"
import Input from "../../../../../components/ui/inputs/input"

// Returns a row for the group name table
export default function GroupName({ bookingID, groupNameID }) {
	const [saving, setSaving] = useState(false)
	const [deleting, setDeleting] = useState(false)
	const [mounted, setMounted] = useState(false)
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [email, setEmail] = useState("")
	const [phone, setPhone] = useState("")
	const [handicap, setHandicap] = useState("")
	const [golfDays, setGolfDays] = useState({
		friday_columbia: false,
		friday_lexington: false,
		saturday_windermere: false,
	})
	const [mastersDays, setMastersDays] = useState({
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false,
		friday: false,
	})
	const [transferDays, setTransferDays] = useState({
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false,
		friday: false,
	})
	const [clubHire, setClubHire] = useState(false)
	const [clubHireHand, setClubHireHand] = useState("right")

	// Pull the alerts context in
	const { pushAlert } = useContext(AlertsContext)

	// On component load
	useEffect(() => {
		const unsubscribe = db.doc(`bookings/${bookingID}/group_names/${groupNameID}`).onSnapshot((nameDoc) => {
			// Return early if the name doesn't exist
			if (!nameDoc.exists) return

			// Otherwise pull the name data
			const { first_name, last_name, email, phone, handicap, golf_days, masters_days, transfer_days, club_hire, club_hire_hand } = nameDoc.data()

			// Set the name details
			setFirstName(first_name)
			setLastName(last_name)
			setEmail(email)
			setPhone(phone)
			setHandicap(handicap)

			// Set the golf days, transfer days and club hire
			setGolfDays(golf_days)
			setMastersDays(masters_days)
			setTransferDays(transfer_days)
			setClubHire(club_hire)
			setClubHireHand(club_hire_hand)

			// Finally, set the mounted state to true
			setMounted(true)
		})

		return () => unsubscribe()
	}, [])

	// Save the new details into the database
	const saveGroupName = async () => {
		setSaving(true)

		try {
			// Update the person row in the database
			await db
				.doc(`bookings/${bookingID}/group_names/${groupNameID}`)
				.set(
					{
						first_name: firstName,
						last_name: lastName,
						email: email,
						phone: phone,
						handicap: handicap,
						golf_days: golfDays,
						masters_days: mastersDays,
						transfer_days: transferDays,
						club_hire: clubHire,
						club_hire_hand: clubHireHand,
					},
					{ merge: true }
				)
				.then(() => {
					pushAlert({
						type: "SUCCESS",
						title: "Group name saved",
					})
				})
				.catch((err) => {
					console.log(err)
					pushAlert({
						type: "ERROR",
						title: "Error saving this name",
						body: err.message,
					})
				})
		} catch (err) {
			console.log(err)
			pushAlert({
				type: "ERROR",
				title: "Error saving this name",
				body: err.message,
			})
		} finally {
			setSaving(false)
		}
	}

	// Remove the group name from the booking
	const deleteGroupName = async () => {
		setDeleting(true)

		try {
			// Remove this person from the booking
			await db
				.doc(`bookings/${bookingID}/group_names/${groupNameID}`)
				.delete()
				.catch((err) => {
					console.log(err)
					pushAlert({
						type: "ERROR",
						message: "Error deleting this name",
					})
				})
		} catch (err) {
			console.log(err)
			pushAlert({
				type: "ERROR",
				message: "Error deleting this name",
			})
		} finally {
			setDeleting(false)
		}
	}

	// Miss the row off if we've been unable to load the data
	if (!mounted) return null

	return (
		<tr>
			<td>
				<Input
					value={firstName}
					onChange={(value) => setFirstName(value)}
					placeholder="First name"
				/>
			</td>
			<td>
				<Input
					value={lastName}
					onChange={(value) => setLastName(value)}
					placeholder="Last name"
				/>
			</td>
			<td>
				<Input
					value={email}
					onChange={(value) => setEmail(value)}
					placeholder="Email"
				/>
			</td>
			<td>
				<Input
					value={phone}
					onChange={(value) => setPhone(value)}
					placeholder="Phone"
				/>
			</td>
			<td>
				<Input
					value={handicap}
					onChange={(value) => setHandicap(value)}
					placeholder="Handicap"
				/>
			</td>

			<td>
				<div className="checkbox-stack">
					<Checkbox
						label="Columbia"
						checked={golfDays.friday_columbia}
						onClick={() => setGolfDays({ ...golfDays, friday_columbia: !golfDays.friday_columbia })}
					/>
					<Checkbox
						label="Lexington"
						checked={golfDays.friday_lexington}
						onClick={() => setGolfDays({ ...golfDays, friday_lexington: !golfDays.friday_lexington })}
					/>
					<Checkbox
						label="Windermere"
						checked={golfDays.saturday_windermere}
						onClick={() => setGolfDays({ ...golfDays, saturday_windermere: !golfDays.saturday_windermere })}
					/>
				</div>
			</td>

			<td>
				<div className="checkbox-stack">
					<Checkbox
						label="Mon"
						checked={mastersDays.monday}
						onClick={() => setMastersDays({ ...mastersDays, monday: !mastersDays.monday })}
					/>
					<Checkbox
						label="Tue"
						checked={mastersDays.tuesday}
						onClick={() => setMastersDays({ ...mastersDays, tuesday: !mastersDays.tuesday })}
					/>
					<Checkbox
						label="Wed"
						checked={mastersDays.wednesday}
						onClick={() => setMastersDays({ ...mastersDays, wednesday: !mastersDays.wednesday })}
					/>
					<Checkbox
						label="Thu"
						checked={mastersDays.thursday}
						onClick={() => setMastersDays({ ...mastersDays, thursday: !mastersDays.thursday })}
					/>
					<Checkbox
						label="Fri"
						checked={mastersDays.friday}
						onClick={() => setMastersDays({ ...mastersDays, friday: !mastersDays.friday })}
					/>
					<Checkbox
						label="Sat"
						checked={mastersDays.saturday}
						onClick={() => setMastersDays({ ...mastersDays, saturday: !mastersDays.saturday })}
					/>
					<Checkbox
						label="Sun"
						checked={mastersDays.sunday}
						onClick={() => setMastersDays({ ...mastersDays, sunday: !mastersDays.sunday })}
					/>
				</div>
			</td>

			<td>
				<div className="checkbox-stack">
					<Checkbox
						label="Mon"
						checked={transferDays.monday}
						onClick={() => setTransferDays({ ...transferDays, monday: !transferDays.monday })}
					/>
					<Checkbox
						label="Tue"
						checked={transferDays.tuesday}
						onClick={() => setTransferDays({ ...transferDays, tuesday: !transferDays.tuesday })}
					/>
					<Checkbox
						label="Wed"
						checked={transferDays.wednesday}
						onClick={() => setTransferDays({ ...transferDays, wednesday: !transferDays.wednesday })}
					/>
					<Checkbox
						label="Thu"
						checked={transferDays.thursday}
						onClick={() => setTransferDays({ ...transferDays, thursday: !transferDays.thursday })}
					/>
					<Checkbox
						label="Fri"
						checked={transferDays.friday}
						onClick={() => setTransferDays({ ...transferDays, friday: !transferDays.friday })}
					/>
					<Checkbox
						label="Sat"
						checked={transferDays.saturday}
						onClick={() => setTransferDays({ ...transferDays, saturday: !transferDays.saturday })}
					/>
					<Checkbox
						label="Sun"
						checked={transferDays.sunday}
						onClick={() => setTransferDays({ ...transferDays, sunday: !transferDays.sunday })}
					/>
				</div>
			</td>

			<td>
				<div className="club-hire-options">
					<Checkbox
						label="Required"
						checked={clubHire}
						onClick={() => setClubHire(!clubHire)}
					/>

					{clubHire && (
						<select
							value={clubHireHand}
							onChange={(e) => setClubHireHand(e.target.value)}>
							<option
								disabled
								selected>
								Choose hand
							</option>
							<option value="right">Right</option>
							<option value="left">Left</option>
						</select>
					)}
				</div>
			</td>

			<td>
				<div className="buttons-row">
					<Button
						label="Save"
						small={true}
						loading={saving}
						onClick={() => saveGroupName()}
					/>

					<Button
						label="Delete"
						small={true}
						loading={deleting}
						onClick={() => deleteGroupName()}
					/>
				</div>
			</td>
		</tr>
	)
}
